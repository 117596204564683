import { mainLinearGrad } from "../../../uikit/color-variables";
import uikit from "../../../uikit/styles";

const stylesheets = (width, height, variable = {}) => ({
    userModalHeader: {
        background: variable.greyColor3,
        height: '40px',
        'border-radius': '14px',
        'text-align': 'center',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'font-weight': 500,
        'margin-bottom': '16px',
        color: variable.blackColor
    },
    userPosition: {
        background: variable.purpleGradient,
        'border-radius': '16px',
        'text-align': 'center',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        color: variable.whiteColor,
        ...uikit.small,
        'line-height': '1.14',
        padding: '4px 7px',
        position: 'absolute',
        'font-weight': 'bold',
        bottom: '0'
    },
    userModalUserRangBlockWrapper: {
        padding: '24px',
        background: 'url(../../../../src/img/office/modalProgressBg.png) no-repeat center',
        'border-radius': '32px'
    },
    userModalRangBlock: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        background: variable.whiteColor,
        'border-radius': '14px',
        padding: '12px 6px',
        'margin-bottom': '6px'
    },
    startProgressText: {
        color: variable.whiteColor,
        'font-weight': 'bold',
        'font-size': '0.625rem'
    },
    endProgressText: {
        color: variable.whiteColor,
        'font-weight': 'bold',
        'font-size': '0.625rem'
    },
    infoSpeedProgressValueNotDone: { color: variable.whiteColor },
    speedProgressComponent: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        marginBottom: '20px',
        color: variable.whiteColor
    },
    grayTitle: {
        ...uikit.subtitle,
        'line-height': '1',
        'margin-bottom': '4px'
    },
    quantitySpeedProgressText: {},
    quantitySpeedProgress: {
        color: variable.whiteColor,
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center'
    },
    infoSpeedProgressPercent: {
        'border-radius': '12px',
        'background-color': variable.yellowColor,
        padding: '6px 8px',
        ...uikit.tiny,
        'font-weight': 500,
        color: variable.blackColor
    },
    quantitySpeedProgress: {
        'margin-top': '20px',
        color: variable.whiteColor,
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center'
    },
    infoSpeedProgressValueDone: { color: variable.yellowColor },
    speedProgressDone: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        color: variable.yellowColor
    },
    speedProgressDoneSvg: {
        'margin-left': '6px',
        width: '20px',
        height: '20px',
        fill: variable.yellowColor,
        checkFill: variable.blackColor
    },
    userModalInfoIcon: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        width: '48px',
        height: '48px',
        'margin-right': '12px',
        'border-radius': '50%',
        background: mainLinearGrad
    },
    userModalVolumeValue: {
        'font-weight': 600,
        ...uikit.subtitle,
        'margin-bottom': '3px'
    },
});
export default stylesheets;