// @generated: @expo/next-adapter@2.1.5
import React, {
    createContext, useEffect, useContext, useState, useMemo
} from 'react';
import UserContext from '../utils/getContext';
import { getLangLocale } from '../utils/memo';
import PropTypes from 'prop-types';

export const LangContext = createContext();

const Language = ({
    children, page, translates, group
}) => {
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    const { lang } = useContext(UserContext);
    const [contextTrans, setContextTrans] = useState(translates);

    const result = useMemo(() => contextTrans ? { ...contextTrans, ...translation } : {}, [translation, contextTrans]);

    useEffect(() => {
        if (translates){
            setContextTrans(translates)
        } else {
            (async () => {
                const trans = await getLangLocale(lang, page, group);
                setContextTrans(trans.data || {})
            })();
        }
    }, [lang, page, group, translates]);

    return (
        <LangContext.Provider value={{ loading: !contextTrans, translation: result }}>
            {children}
        </LangContext.Provider>
    );
};

Language.propTypes = {
    translates: PropTypes.object,
    page: PropTypes.string,
    group: PropTypes.string
};

export const Lang = (OriginalComponent, page, group) => class WrapperComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Language page={page} group={group}>
                <OriginalComponent
                    {...this.props}
                />
            </Language>
        );
    }
};

export default Language;