import React, {
    useMemo, useContext, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
    Div, P, svgSprite
} from '../wrappers';
import UserContext, { UserProfileContext } from '../../common/utils/getContext';
import { t } from '../../common/components/T';
import uikit from '../../project/uikit/styles';
import { getWidgetTranslates } from '../../common/utils/utils';
import { LangContext } from '../../common/decorators/Language';
import { progressSpeedColors } from '../../project/uikit/color-variables';

const SpeedProgress = ({
    formatted_accumulated,
    formatted_left_to_accumulate,
    target,
    hasPV,
    title,
    styles,
    showAim = true,
    key
}) => {
    const { lang } = useContext(UserContext);
    const { officePage } = useContext(UserProfileContext);

    const [trans, setTranslates] = useState(null);
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'speedProgress', translation, setTranslates);
    }, [lang, translation])

    let pers = hasPV / target * 100; // вычисляем процент выполения
    if (pers > 100) {
        pers = 100
    }
    const dasharray = 304; // длина svg
    const endPath = 0; // точка окончания прорисовки svg
    const dashoffset = (dasharray - endPath) / 100 * (100 - pers) + endPath;
    const radius = 54; // радиус окружности
    const coordinatesProgress = useMemo(() => {
        // set>max ? set = max : '';
        const lengthPath = 128.3 / 100 * pers;// где 128.5-максимальная длина пути , 100 - 100%
        const rotateMark = 255 / 100 * pers; // 255 - максимальный градус оборота маркера-стрелочки
        const s = 2 * Math.PI / 180 * lengthPath; // Вычислим угол
        return {
            top: `${44 + radius * Math.sin(s)}px`, // 60 - радиус окружности минус висота врапера маркера-стрелочки
            left: `${45 + radius * Math.cos(s)}px`, // 60 - радиус окружности минус ширина врапера маркера-стрелочки
            transform: `rotateZ(${rotateMark - 4}deg)`

        };
    }, [pers]);
    return (
        <Div styles={styles.speedProgressComponent}>
            <Div styles={styles.officeWrapSpeedProgress}>
                {
                    100 / target * hasPV < 100 || officePage
                        ?
                        <Div styles={{ ...styles.officeSpeedProgressSvg, top: '9px' }}>
                            {
                                svgSprite('speed-progress-colored', {
                                    width: '142px',
                                    height: '115px',
                                    color1: officePage ? progressSpeedColors[0] : styles.variable.redDarkColor,
                                    color2: officePage ? progressSpeedColors[1] : styles.variable.orangeColor,
                                    color3: officePage ? progressSpeedColors[2] : styles.variable.greenColor
                                })
                            }
                        </Div>

                        :
                        <Div styles={styles.officeSpeedProgressSvg}>
                            {
                                svgSprite('speed-progress-done', {
                                    width: '175px',
                                    height: '143px',
                                    color1: styles.variable.greenColor,
                                    color2: styles.variable.greenGradientLightColor
                                }, key)
                            }
                        </Div>

                }
                <Div styles={{ position: 'absolute', top: '23px' }}>
                    {svgSprite('speed-progress-path', {
                        width: '115',
                        height: '91',
                        fill: officePage ? styles.variable.blackColor : styles.variable.darkColor,
                        style: { ...styles.officeSpeedProgressPathSvgDone },
                        opacity: officePage ? 0.4 : 0.08
                    })}
                </Div>
                <Div styles={{ position: 'absolute', top: '23px' }}>
                    {svgSprite('speed-progress-value', {
                        width: '115',
                        height: '91',
                        stroke: officePage ? styles.variable.yellowColor : styles.variable.greyDeepExtraDarkColor,
                        style: styles.officeSpeedProgressPathSvgDone
                    }, key, [dasharray, dashoffset])}
                </Div>

                <Div styles={styles.officeSpeedProgressCircle}>
                    <Div styles={{ ...styles.markerSpeedProgress, ...coordinatesProgress }}>
                        <Div styles={styles.markerSpeedProgressArrowWrap}>
                            {svgSprite('speed-progress-arrow', {
                                width: '24px',
                                height: '3px',
                                fill: officePage ? styles.variable.yellowColor : styles.variable.greyDeepExtraDarkColor,
                                style: styles.arrowSpeedProgress
                            }, key)}
                        </Div>
                    </Div>
                </Div>
                <Div styles={styles.startProgress}><P styles={styles.startProgressText}>0</P></Div>
                <Div styles={styles.endProgress}><P styles={styles.endProgressText}>100%</P></Div>
                <Div styles={styles.infoSpeedProgress}>
                    <Div styles={{ ...uikit.title, ...styles.infoSpeedProgressTitleWrap }}>
                        <Div styles={styles.infoSpeedProgressTitle}>
                            <P styles={styles.grayTitle}>
                                {title}
                            </P>
                        </Div>
                    </Div>
                    <Div styles={uikit.mb2}>
                        <P styles={{ ...styles.infoSpeedProgressValue, ...100 / target * hasPV < 100 ? styles.infoSpeedProgressValueNotDone || { color: styles.variable.darkColor } : styles.infoSpeedProgressValueDone }}>
                            {formatted_accumulated}
                        </P>
                    </Div>
                    {
                        100 / target * hasPV < 100
                        &&
                            <Div styles={styles.infoSpeedProgressPercentWrap}>
                                <P styles={styles.infoSpeedProgressPercent}>
                                    {Math.round(100 / target * hasPV)}
                                    %
                                </P>
                            </Div>

                    }

                </Div>
            </Div>
            {showAim ? <Div styles={{ ...styles.quantitySpeedProgress, ...uikit.small }}>
                <P styles={styles.quantitySpeedProgressText}>
                    {t(
                        'quantitySpeedProgressTextTarget', 'Цель', 'widget', trans, 'speedProgress'
                    )} {formatted_accumulated}
                </P>
                {
                    100 / target * hasPV < 100
                        ?

                        <P styles={styles.quantitySpeedProgressText}>
                            {t(
                                'quantitySpeedProgressTextMore', 'Еще', 'widget', trans, 'speedProgress'
                            )} {formatted_left_to_accumulate}
                        </P>

                        :
                        <P styles={styles.speedProgressDone}>
                            {t(
                                'quantitySpeedProgressTextDone', 'Выполнен', 'widget', trans, 'speedProgress'
                            )}
                            {
                                svgSprite('check-circle-fill', { style: styles.speedProgressDoneSvg })
                            }
                        </P>

                }
            </Div> : <></>}
        </Div>

    );
};

SpeedProgress.propTypes = {
    formatted_accumulated: PropTypes.node,
    formatted_left_to_accumulate: PropTypes.node,
    target: PropTypes.number,
    hasPV: PropTypes.number,
    title: PropTypes.node,
    styles: PropTypes.object,
    showAim: PropTypes.bool,
    key: PropTypes.string
};

export default SpeedProgress;