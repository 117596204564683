import React, { useContext } from 'react'
import { Div } from '../../../hybrid/wrappers'
import UserContext from '../../../common/utils/getContext'
import moment from 'moment'

const TimePass = ({ val }) => {
    const { time, lang } = useContext(UserContext)

    return (
        <Div>{moment.duration(-((time().unix() - val) * 1000), 'milliseconds').locale(lang)
            .humanize(true)}</Div>
    )
}
export default TimePass