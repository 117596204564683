import uikit from '../../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    socialBlock: {
        position: 'relative',
        'z-index': 1,
        background: variable.greyColor3,
        'border-radius': '14px',
        padding: '8px 24px',
        margin: '16px 0 0'
    },
});
export default stylesheets;