import React from 'react';
import { getDimensions, eventDimension } from '../../hybrid/wrappers';
import { UserProfileContext } from '../utils/getContext';
import {
    main, office, admin
} from '../../project/uikit/color-variables';

const StylesComponent = (OriginalComponent, getStyles = () => {}) => class WrapperComponent extends React.Component {
    constructor(props) {
        super(props);
        this.styles = this.styles.bind(this);
        this.changeSize = this.changeSize.bind(this);
        this.getDimensions = this.getDimensions.bind(this);
        this.state = { screenSize: {} };
    }
    static contextType = UserProfileContext;

    getDimensions() {
        const { width, height } = getDimensions();
        return {
            width,
            height
        };
    }

    changeSize() {
        const newSize = this.getDimensions();
        this.setState({ screenSize: newSize });
        document.body.classList.remove('is_loading')
    }

    componentDidMount() {
        this.changeSize();
        eventDimension(this.changeSize);
    }

    componentWillUnmount() {
        eventDimension(this.changeSize, true);
    }

    styles() {
        if (this.context?.adminPage){
            return {
                variable: admin,
                ...getStyles(this.state.screenSize.width, this.state.screenSize.height, admin)
            }
        }
        if (this.context?.officePage){
            const result = getStyles(this.state.screenSize.width, this.state.screenSize.height, office)
            if (result['officeStyles']){
                return {
                    variable: office,
                    ...result,
                    ...result['officeStyles'](this.state.screenSize.width, this.state.screenSize.height, office)
                }
            } else {
                return {
                    variable: office,
                    ...result
                }
            }
        }

        const result = getStyles(this.state.screenSize.width, this.state.screenSize.height, main)
        if (result['mainStyles']){
            return {
                variable: main,
                ...result,
                ...result['mainStyles'](this.state.screenSize.width, this.state.screenSize.height, main)
            }
        } else {
            return {
                variable: main,
                ...result
            }
        }
    }

    render() {
        return (
            <OriginalComponent
                {...this.props}
                styles={!this.state.screenSize.width && !this.state.screenSize.height ? { variable: { calcColor: () => {} } } : this.styles()}
            />
        )
    }
};
export default StylesComponent;