const {
    Schema, models, model
} = require('mongoose');

const Code = new Schema({
    position: { type: Number },
    // name: {
    //     type: String,
    //     required: [true, 'Необходимо заполнить поле'],
    //     unique: true
    // },
    name: { },
    status: { type: Boolean },
    text: { }
});
module.exports = models?.codes || model('codes', Code);