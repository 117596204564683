// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, Div } from '../../../hybrid/wrappers';
import { t } from '../../../common/components/T';

const Package = ({
    getHandlers, gridStyles, val, styles, name, translation
}) => {
    return (
        <Div
            {...getHandlers(false)}
            styles={gridStyles[name]?.wrapper?.styles || styles.flexRow}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            <P
                styles={
                    gridStyles[name]?.small?.styles || {
                        ...styles.orderStatus, ...styles.orderStatusWeb, ...!!val?.sort ? styles[`package_${val?.sort}`] : styles.package_null
                    }
                }
                effects={gridStyles[name]?.small?.effects || {}}
            >
                {
                    !!val
                        ? typeof val === 'string' ? val : val?.name || t(
                            'EmptyPackages', 'Отсутсвует', 'widget', translation, 'grid'
                        )
                        : t(
                            'EmptyPackages', 'Отсутсвует', 'widget', translation, 'grid'
                        )
                }
            </P>
        </Div>
    );
}

Package.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    styles: PropTypes.object,
    name: PropTypes.string
};

export default Package;