// @generated: @expo/next-adapter@2.1.5
import React, { useCallback, useContext } from 'react';
import FormComponent from '../../FormComponent';
import {
    Portal, Div, P, Img
} from '../../../../hybrid/wrappers';

import { dataSender } from '../../../../common/utils/router';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import closeImg from '../../../../public/src/img/close.png';
import UserContext from '../../../../common/utils/getContext';
import { useWindowDimensions } from '../../../../common/utils/windowDimensions';
import T from '../../../../common/components/T';

const FormModal = ({
    setOpen,
    attribute,
    options,
    modalStyles,
    modalSendUrl,
    message,
    userToken,
    resetUrl,
    styles,
    titlePopup,
    cancelFuncs,
    buttonText
}) => {
    const widthDimensions = useWindowDimensions();
    const mobile = widthDimensions <= 768;
    const { setAlertData, lang } = useContext(UserContext);

    const modalAfterSend = useCallback((data) => {
        const resMessage = data?.data?.message;

        setOpen(false);
        setAlertData({
            type: 'success', cont: resMessage || message?.success || (message?.success === false ? message.success : true), default: 'success_save_data'
        });
    }, [message?.success, setAlertData, setOpen]);

    const sender = useCallback((formData, setAllowedSend, setFieldsErr) => {
        const sendData = resetUrl
            ? {
                ...formData,
                attribute,
                url: resetUrl
            }
            : {
                ...formData,
                attribute
            };

        dataSender(
            modalSendUrl,
            'POST',
            sendData,
            (response) => modalAfterSend(response),
            (data) => {
                if (message?.error === false) { setAlertData(false); } else if (data.message) { setAlertData({ type: 'error', cont: data.message }); }

                if (data.errors && Object.keys(data.errors)) { setFieldsErr(data.errors); }

                if (data.message || data.errors && Object.keys(data.errors)) {
                    setAllowedSend(true);
                    setAlertData({ type: 'error', cont: data.message })
                };
            },
            userToken,
            lang
        );
    }, [resetUrl, attribute, modalSendUrl, userToken, lang, modalAfterSend, message?.error, setAlertData]);

    const modalSendData = {
        url: modalSendUrl,
        sender
    };

    const { infoTextBlock } = options[0];

    return (
        <Portal customClass={mobile ? 'modalBottom' : 'modal'} close={() => setOpen(false)}>
            <Div styles={styles.modalContentWrapper}>

                <Div
                    data-uitest='581_uitest' onClick={() => setOpen(false)}
                    styles={styles.modalCloseBtn}
                >
                    {
                        !mobile ?
                            <Img
                                src='/src/img/close.svg'
                                source={(closeImg)}
                            />
                            : <Div styles={styles.modalCloseBtnDecor}/>
                    }

                </Div>


                {
                    !!titlePopup
                        &&
                            <P styles={styles.formModalSubtitle}>
                                {titlePopup}
                            </P>

                }
                <Div conttype='scroll'>
                    {
                        !!infoTextBlock
                            &&
                                <Div styles={styles.formModalInfoBlockModal}>
                                    <Div styles={styles.formModalPrevValueTextBlock}>
                                        <P styles={styles.formModalPrevValueText}>{options[0].prevValueText}</P>
                                        <P styles={styles.formModalPrevValue}>{options[0].prevValue}</P>
                                    </Div>
                                    <P styles={styles.formModalInfoTextBlock}>{options[0].infoTextBlock}</P>
                                </Div>

                    }

                    <FormComponent
                        data={options}
                        formName={`emailWidget_${attribute}`}
                        formStyles={modalStyles || {}}
                        sendData={modalSendData}
                        message={message}
                        customData={{
                            customFormButton: {
                                isFullWidth: true
                            }
                        }}
                        funcs={{}}
                        buttons={[
                            ...cancelFuncs
                                ? [{
                                    type: 'cancel',
                                    clickFunc: cancelFuncs.web.onClick,
                                    buttonText: <T textName='close' defaultStr='Закрыть' page='all' />
                                }]
                                : []
                            ,
                            {
                                buttonText: buttonText || <T textName='send' defaultStr='Отправить' page='all' />,
                                type: 'ok'
                            }
                        ]}
                    />
                </Div>
            </Div>
        </Portal>
    );
};

export default Styles(FormModal, getStyles);