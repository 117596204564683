import uikit from '../../../../project/uikit/styles'

export const getBreakpoint = (width) => {
    const breakpoints = [320, 479, 575, 767, 991, 1199, 1449, 1919];
    let key = breakpoints.reduce((result, current) => {
        if (width > +current && +current > +result){
            return +current;
        }
        return result;
    }, 320);

    return key
}
export const mediaSkeleton = (key, settings, empty) => {
    if (settings[key]) return settings[key];
    let newKey = Object.keys(settings).reduce((result, current) => {
        if (key > +current && +current > +result){
            return +current;
        }
        return result;
    }, 0);
    if (settings[newKey]) return settings[newKey];
    return empty || {}
};

export const userSidebarInfoSkeleton = () => [
    {
        row: [40, 60],
        items: [
            {
                type: 'circle',
                align: 'center',
                diameter: 8,
                gap: [20]
            },
            {
                content: [
                    {
                        type: 'text',
                        width: 50,
                        align: 'left',
                        gap: [20, 10]
                    },
                    {
                        type: 'text',
                        width: 50,
                        align: 'left',
                        gap: [20, 10]
                    },
                ]
            }
        ]
    },
    'empty',
    {
        type: 'text',
        width: 100,
        align: 'center'
    },
    'empty',
    'line',
    'empty',
    {
        row: [50, 50, 50, 50, 100],
        items: [
            {
                type: 'blockLG',
                width: 90,
                align: 'center'
            },
            {
                type: 'blockLG',
                width: 90,
                align: 'center'
            }, {
                type: 'blockLG',
                width: 90,
                align: 'center'
            },
            {
                type: 'blockLG',
                width: 90,
                align: 'center'
            },
            {
                type: 'blockLG',
                width: 95,
                align: 'center'
            },
        ],
    },
    'empty',
    'line',
    'empty',
    {
        row: [100, 100, 100, 100, 100, 100],
        indent: 3,
        items: [{
            row: [15, 85],
            items: ['icon', {
                content: [
                    {
                        type: 'text', mode: 'dark', width: 30
                    },
                    { type: 'small', width: 20 }]
            }]
        }]
    },
    'empty',
    'line',
    'empty',
    {
        row: [100, 100, 100],
        items: [
            'blockSM',
            {
                row: [33, 66],
                items: [{
                    type: 'circle',
                    diameter: 7
                },
                {
                    content: [
                        {
                            type: 'title',
                            width: 90
                        },
                        {
                            type: 'list',
                            width: 60,
                            size: { rows: 1 }
                        },
                        {
                            type: 'small',
                            width: 60
                        }
                    ]
                }]
            },
            'blockSM'
        ],
        indent: 3
    }

]
export const userSidebarSkeleton = () => [
    {
        row: [5, 30, 30, 30, 5],
        items: [
            'empty',
            {
                type: 'icon',
                align: 'center',
                gap: [3, 0, 2]
            },
            {
                type: 'circle',
                align: 'center',
                diameter: 8,
                gap: [3, 0, 2]
            },
            {
                type: 'icon',
                align: 'center',
                gap: [3, 0, 2]
            }
        ]
    },
    'empty',
    {
        type: 'text',
        width: 30,
        align: 'center'
    },
    {
        type: 'text',
        width: 25,
        align: 'center'
    },
    {
        type: 'blockLG',
        width: 90,
        align: 'center'
    },
    {
        type: 'blockLG',
        width: 90,
        align: 'center'
    },
    'empty',
    'line',
    'empty',
    {
        row: [100, 100, 100, 100, 100, 100],
        indent: 3,
        items: [{
            row: [15, 85],
            items: ['icon', {
                content: [
                    {
                        type: 'text', mode: 'dark', width: 30
                    },
                    { type: 'small', width: 20 }]
            }]
        }]
    },
    'empty',
    'line',
    'empty',
    {
        row: [100, 100, 100],
        items: [
            'blockSM',
            {
                row: [33, 66],
                items: [{
                    type: 'circle',
                    diameter: 7
                },
                {
                    content: [
                        {
                            type: 'title',
                            width: 90
                        },
                        {
                            type: 'list',
                            width: 60,
                            size: { rows: 1 }
                        },
                        {
                            type: 'small',
                            width: 60
                        }
                    ]
                }]
            },
            'blockSM'
        ],
        indent: 3
    }

]

export const breadcrumbsSkeleton = () => [{
    row: [20, 20],
    items: [
        'title',
        {
            row: [50, 50],
            gap: 1,
            items: [{
                type: 'text',
                mode: 'dark'
            }, 'text']
        }
    ]
}]
export const adminFormTransparentSkeleton = (styles, breakpoint) => [{
    content: [
        {
            row: mediaSkeleton(breakpoint, { 767: [34, 32, 34] }, [1, 98, 1]),
            items: [
                'empty',
                {
                    content: [
                        'blockMD',
                        'empty',
                        'blockMD',
                        'empty',
                        'blockMD'
                    ]
                }
            ]
        },
        'empty',
        'line',
        'empty',
        {
            row: mediaSkeleton(breakpoint, { 767: [34, 32, 34] }, [1, 98, 1]),
            items: [
                'empty',
                {
                    content: [
                        'blockMD',
                        'empty',
                        'blockMD',
                        'empty',
                        'blockMD'
                    ]
                }
            ]
        },
        'empty',
        'line',
        'empty',
        {
            row: mediaSkeleton(breakpoint, { 767: [34, 32, 34] }, [1, 98, 1]),
            items: [
                'empty',
                {
                    content: [
                        'blockMD',
                        'empty',
                        'blockMD',
                        'empty',
                        'blockMD'
                    ]
                }
            ]
        }
    ]
}]

export const adminFormSkeleton = (variable) => [
    {
        row: [100],
        itemsStyles: {
            padding: '1.5rem 0',
            'border-radius': '20px',
            background: variable.whiteColor,
            overflow: 'hidden',
            ...uikit.mb2
        },
        items: adminFormTransparentSkeleton()
    }
]
export const adminGridFilterSkeleton = (variable, key, { counter } = {}) => {
    let smHeader = [42, 29, 29];
    let xsHeader = [50, 50];
    const getItem = (margin) => ({
        row: ['auto', counter ? 77 : 90],
        align: 'left',
        gap: 2,
        items: [
            ...mediaSkeleton(key, { 1919: ['icon'] }, counter ? [{
                type: 'icon',
                ...margin ? { gap: [2, 0, 2, 0] } : {}
            }] : []),
            {
                content: [
                    ...margin ? ['empty'] : [],
                    {
                        type: 'text',
                        width: mediaSkeleton(key, { 767: 50 }, counter ? 80 : 50),
                        gap: [0, 0, 1, 0]
                    },
                    {
                        type: 'title',
                        width: mediaSkeleton(key, { 767: 70 }, counter ? 90 : 70),
                        mode: 'light',
                        gap: 0
                    }
                ]
            }]
    })
    const items = [getItem()]
    let xsItems = [...items]
    if (counter){
        smHeader = [35]
        xsHeader = []
        xsItems = []
        for (let i = 0;i < counter;i++){
            smHeader.push(65 / counter);
            xsHeader.push(50);
            xsItems.push(getItem(i>=2))
        }
    }
    return [
        {
            row: [100],
            itemsStyles: {
                padding: '1.5rem',
                'border-radius': '20px',
                background: variable.whiteColor,
                overflow: 'hidden',
                ...uikit.mb2
            },
            items: [{
                row: mediaSkeleton(key, { 767: smHeader }, xsHeader),
                items: mediaSkeleton(key, { 767: items }, xsItems),
                ...counter && counter > 1 ? { align: mediaSkeleton(key, { 767: 'left' }, 'center') } : {}
            }]
        },
        {
            row: [100],
            itemsStyles: {
                padding: '1.5rem',
                'border-radius': '20px',
                background: variable.whiteColor,
                overflow: 'hidden',
                ...uikit.mb2
            },
            items: [{
                type: 'table',
                size: {
                    rows: 5,
                    columns: 8
                }
            }
            ]
        }
    ]
}
export const adminGridFilterSummarySkeleton = (variable) => [
    {
        row: [100],
        itemsStyles: {
            padding: '1.5rem 0',
            'border-radius': '20px',
            background: variable.whiteColor,
            overflow: 'hidden',
            ...uikit.mb2
        },
        items: [{
            content: [
                {
                    type: 'blockSM',
                    width: 30,
                    gap: [0, 3, 1, 3]
                },
                'line',
                {
                    type: 'text',
                    width: 29,
                    gap: [0, 3, 2, 3]
                },
                {
                    type: 'text',
                    width: 29,
                    gap: [0, 3, 2, 3]
                },
                {
                    type: 'text',
                    width: 29,
                    gap: [0, 3, 0, 3]
                }
            ]
        }]
    },
    {
        row: [100],
        itemsStyles: {
            padding: '1.5rem',
            'border-radius': '20px',
            background: variable.whiteColor,
            overflow: 'hidden',
            ...uikit.mb2
        },
        items: [{
            type: 'table',
            size: {
                rows: 5,
                columns: 8
            }
        }
        ]
    }
]
export const adminGridFilterSummaryLgSkeleton = (variable, key) => [
    {
        row: [100],
        itemsStyles: {
            padding: '1.5rem 0',
            'border-radius': '20px',
            background: variable.whiteColor,
            overflow: 'hidden',
            ...uikit.mb2
        },
        items: [{
            content: [
                ...mediaSkeleton(key, {
                    767: [{
                        type: 'blockSM',
                        width: 30,
                        gap: [0, 3, 1, 3]
                    },
                    'line']
                }, []),
                {
                    row: mediaSkeleton(key, {
                        1449: [25, 25, 25, 25],
                        1199: [33, 33, 33, 33],
                        767: [50, 50, 50, 50]
                    }, [100]),
                    align: mediaSkeleton(key, { 1449: 'left' }, 'center'),
                    items: [
                        {
                            content: [{
                                type: 'text',
                                width: 80,
                                gap: [0, 3, 2, 3]
                            },
                            {
                                type: 'text',
                                width: 80,
                                gap: [0, 3, 2, 3]
                            },
                            {
                                type: 'text',
                                width: 80,
                                gap: [0, 3, 0, 3]
                            }, ...mediaSkeleton(key, { 1449: [] }, ['line'])]
                        }
                    ]
                }
            ]
        }]
    },
    {
        row: [100],
        itemsStyles: {
            padding: '1.5rem',
            'border-radius': '20px',
            background: variable.whiteColor,
            overflow: 'hidden',
            ...uikit.mb2
        },
        items: [{
            type: 'table',
            size: {
                rows: 5,
                columns: 8
            }
        }
        ]
    }
]
export const tabsFormSkeleton = (variable, breakpoint) => [
    {
        row: mediaSkeleton(breakpoint, { 767: [25, 40] }, [100]),
        gap: 2,
        align: 'left',
        vertical: 'unset',
        items: [
            { content: ['blockSM', 'blockSM', 'blockSM', 'blockSM', 'blockSM'] },
            { content: ['text', 'empty', 'blockMD', 'blockMD', 'blockMD', 'blockMD', 'blockMD', 'empty', 'empty', 'empty', 'button'] }
        ]
    }
]
export const statsSkeleton = (variable, breakpoint) => [
    {
        row: [100],
        itemsStyles: {
            padding: '1rem',
            background: variable.whiteColor,
            overflow: 'hidden',
            ...uikit.mb2
        },
        items: [{
            row: mediaSkeleton(breakpoint, { 767: [25, 25, 25, 25] }, [50, 50, 50, 50]),
            items: [
                {
                    row: [mediaSkeleton(breakpoint, { 767: 'auto' }, 100), mediaSkeleton(breakpoint, { 767: 90 }, 100)],
                    items: [
                        {
                            type: 'icon',
                            ...mediaSkeleton(breakpoint, { 767: {} }, { align: 'center' })
                        },
                        {
                            content: [
                                {
                                    type: 'text',
                                    width: 50,
                                    gap: [0, 0, 1, 0],
                                    ...mediaSkeleton(breakpoint, { 767: {} }, { align: 'center' })
                                },
                                {
                                    type: 'title',
                                    width: 70,
                                    mode: 'light',
                                    ...mediaSkeleton(breakpoint, { 767: { gap: 0 } }, { align: 'center' })
                                }
                            ]
                        }]
                }
            ]
        }]
    }
]
export const centeredFormSkeleton = (variable, breakpoint) => [
    {
        row: [100],
        itemsStyles: {
            padding: '1.5rem 0',
            'border-radius': '20px',
            background: variable.whiteColor,
            overflow: 'hidden',
            ...uikit.mb2
        },
        items: [{
            content: [
                {
                    row: mediaSkeleton(breakpoint, { 767: [27, 46, 27] }, [1, 98, 1]),
                    items: [
                        'empty',
                        {
                            content: [
                                'blockMD',
                                'empty',
                                'blockMD',
                                'empty',
                                'blockMD',
                                'empty',
                                'blockMD',
                                'empty',
                                'blockMD',
                                'empty',
                                'blockMD',
                                'empty',
                                'blockMD',
                                'empty',
                                'blockMD',
                                'empty',
                                'blockMD'
                            ]
                        }
                    ]
                }
            ]
        }]
    }
]