import React, { useContext, useMemo } from 'react';
import {
    P, Div, svgSprite
} from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/grid-styles';
import Styles from '../../common/decorators/Styles';
import { dataSender } from '../../common/utils/router';
import UserContext from '../../common/utils/getContext';
import Page from './Pages';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import { t } from '../../common/components/T'
import { LangContext } from '../../common/decorators/Language';

const Pagination = ({
    meta,
    links,
    styles,
    setViewData,
    setTableData,
    convertData, // need to convert DATA from back to front component
    setUrl,
    gridStyles,
    smallPagination,
    token
}) => {
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    const widthDimensions = useWindowDimensions();

    const screenWidth = useMemo(() => widthDimensions > 768, [widthDimensions]);

    const {
        userToken, setAlertData, lang
    } = useContext(UserContext);
    const paginationUrlBlank = useMemo(() => links.first.replace(/\d+$/, ''), [links.first]); // шаблон урлы которая потом будет подставляться во все ссылки

    const pagination = (url) => {
        dataSender(
            url,
            'GET',
            false,
            (response) => {
                setAlertData(null);
                const sendData = convertData ? convertData(response.data) : response.data;
                if (setViewData){
                    setViewData((pre) => {
                        return {
                            ...pre,
                            tbody: sendData
                        };
                    });
                } else {
                    setTableData(sendData);
                }
                setUrl && setUrl(url);
            },
            (data) => {
                if (data.message) { setAlertData({ type: 'error', cont: data.message }); } else { setAlertData({ type: 'error', cont: data.false }); }
            },
            token || userToken,
            lang
        );
    };
    return (
        screenWidth && !smallPagination ?
            <Div styles={gridStyles?.paginationWrapper ? gridStyles.paginationWrapper : styles.paginationWrapper}>
                <Div
                    className='paginationArrow' styles={styles.paginationArrows}
                    effects={{
                        hover: { ...styles.paginationArrowsActive, ...styles.formCursorPointer },
                        focus: { ...styles.paginationArrowsActive, ...styles.formCursorPointer }
                    }}
                    data-uitest='615_uitest' onClick={() => meta.current_page !== 1 && pagination(`${paginationUrlBlank}${meta.current_page - 1}`)}
                >
                    <Div>
                        {svgSprite('angle-left', { style: styles.paginationArrowsItem })}
                    </Div>
                </Div>

                <Div styles={styles.paginationBlock}>
                    <Page
                        metaCurrentPage={meta.current_page}
                        pagination={pagination}
                        paginationUrlBlank={paginationUrlBlank}
                        metaLastPage={meta?.last_page}
                        styles={styles}
                    />
                </Div>

                <Div
                    className='paginationArrow' styles={styles.paginationArrows}
                    effects={{
                        hover: { ...styles.paginationArrowsActive, ...styles.formCursorPointer },
                        focus: { ...styles.paginationArrowsActive, ...styles.formCursorPointer }
                    }}
                    data-uitest='616_uitest' onClick={() => meta.current_page !== meta.last_page && pagination(`${paginationUrlBlank}${meta.current_page + 1}`)}
                >
                    <Div>
                        {svgSprite('angle-right', { style: styles.paginationArrowsItem })}
                    </Div>
                </Div>
            </Div>
            :
            <Div styles={!!gridStyles?.paginationWrapperMobile ? gridStyles.paginationWrapperMobile : styles.paginationWrapperMobile}>
                <Div>
                    <P styles={styles.paginationBlockMobile}>{meta.from} - {meta.to}{' '}{t('of', 'из', 'all', translation)}{' '}{meta.total}</P>
                </Div>
                <Div styles={styles.paginationArrowsMobileWrap}>
                    <Div
                        className='paginationArrow' styles={styles.paginationArrowsMobile}
                        effects={{
                            hover: { ...styles.paginationArrowsActive, ...styles.formCursorPointer },
                            focus: { ...styles.paginationArrowsActive, ...styles.formCursorPointer }
                        }}
                        data-uitest='617_uitest' onClick={() => meta.current_page !== 1 && pagination(`${paginationUrlBlank}${meta.current_page - 1}`)}
                    >
                        <Div>
                            {svgSprite('angle-left', { style: styles.paginationArrowsItem })}
                        </Div>
                    </Div>
                    <Div
                        className='paginationArrow' styles={styles.paginationArrowsMobile}
                        effects={{
                            hover: { ...styles.paginationArrowsActive, ...styles.formCursorPointer },
                            focus: { ...styles.paginationArrowsActive, ...styles.formCursorPointer }
                        }}
                        data-uitest='618_uitest' onClick={() => meta.current_page !== meta.last_page && pagination(`${paginationUrlBlank}${meta.current_page + 1}`)}
                    >
                        <Div>
                            {svgSprite('angle-right', { style: styles.paginationArrowsItem })}
                        </Div>
                    </Div>
                </Div>
            </Div>
    );
};

export default Styles(Pagination, getStyles);