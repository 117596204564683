// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { Div, Tooltip } from '../../../hybrid/wrappers';
import T from '../../../common/components/T'

const InvestitionMonth = ({ val }) => {
    // const month = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    // return (
    //     <>
    //         <Div styles={{
    //             display: 'flex', 'align-items': 'center', 'justify-content': 'flex-start'
    //         }}>

    //             <Tooltip
    //                 place='top'
    //                 text={ <T textName='investitions-month-tooltip' defaultStr='Выплачено дохода за количество месяцев владения лицензией' page='all'/> }
    //             >
    //                 <Div styles={{
    //                     display: 'flex', 'align-items': 'center', 'justify-content': 'flex-start'
    //                 }}>
    //             {month?.map((_, index) => {
    //                 return (
    //                     <Div key={index} styles={{
    //                         width: '12px',
    //                         height: '12px',
    //                         'border-radius': '50%',
    //                         'margin-right': '2px',
    //                         background: index + 1 <= val?.month ? (val?.kumulativ ? 'rgb(250, 255, 27)' : '#58AE24') : '#E6E6E6'
    //                     }}
    //                     />
    //                 )
    //             })}
    //                     </Div>
    //             </Tooltip>

    //             {!val?.status ?
    //                 <Div styles={{
    //                     padding: '6px 8px',
    //                     background: '#EFF7EA',
    //                     'border-radius': '8px',
    //                     'font-weight': 600,
    //                     'font-size': '14px',
    //                     'line-height': '17px',
    //                     display: 'flex',
    //                     'align-items': 'center',
    //                     color: '#58AE24',
    //                 'margin-left': '63px'
    //             }}>
    //                     <T textName='false-status-label-close' defaultStr='Закрыта' page='all'/>
    //                 </Div>
    //                 : null
    //             }
    //         </Div>
    //     </>
    // )

    const width = val?.isYear == 1 || val?.special ? (+val?.calculated / +val?.total_income) * 100 : (+val?.payed / +val?.total_income) * 100

    return (
        <Tooltip
            place='top'
            text={val?.isYear ? <T textName='isyeartooltip-label' defaultStr='Нулевое значение в прогрессбаре заменится на рассчитанное по истечению срока кумулятива!' page='all'/> : null}
        >
            <Div styles={{
                width: '200px',
                'border-radius': '20px',
                border: '1px solid black',
                height: '20px',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                'font-weight': '500',
                position: 'relative',
                overflow: 'hidden'
            }}>
                <Div styles={val?.isYear ? {
                    width: width > 100 ? '100%' : `${width}%`,
                    'border-radius': '0px 20px 20px 0',
                    height: '20px',
                    background: 'orange',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    'z-index': '-1'
                } : {
                    width: width > 100 ? '100%' : `${width}%`,
                    'border-radius': '0px 20px 20px 0',
                    height: '20px',
                    background: 'lightgreen',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    'z-index': '-1'
                }}
                >
                </Div>
                {`${val?.payed} / ${val?.total_income}`}
            </Div>

        </Tooltip>
    )
};

InvestitionMonth.propTypes = {
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ])
};

export default InvestitionMonth;