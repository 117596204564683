import { media } from "../../../../common/utils/utils";
import uikit from "../../../uikit/styles";

const stylesheets = (width, height, variable = {}) => ({
    userNameBlockIcon: {

    },
    userNameBlock: media(width, height, 'w', {
        768: {
            display: 'inline-flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            height: '30px',
            'align-items': 'center',
            'font-weight': 500,
            color: variable.blackColor,
            background: 'linear-gradient(108.49deg, #FCFF60 0%, #DCE118 100%)',
            'font-size': '1rem',
            'line-height': '1.5',
            padding: '6px 8px',
            'border-radius': '6px'
        },
        0: {
            display: 'inline-flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            height: '22px',
            'align-items': 'center',
            'font-weight': 500,
            color: variable.blackColor,
            'font-size': '1rem',
            'line-height': '1.5',
            padding: '6px 8px',
            background: 'linear-gradient(108.49deg, #FCFF60 0%, #DCE118 100%)',
            'border-radius': '6px'
        }
    }),
    td: {
        position: 'relative',
        'z-index': '1',
        'text-align': 'left',
        'border-bottom-width': '1px',
        'border-bottom-color': variable.calcColor('black', .12),
        'border-style': 'solid',
        'padding-top': '12px',
        'padding-bottom': '12px',
        'padding-left': '24px',
        'padding-right': '0'
    },
    th: media(width, height, 'w', {
        768: {
            'font-weight': 700,
            'background-color': variable.greyColor3,
            'font-size': '0.875rem',
            'line-height': '1.715',
            'text-transform': 'uppercase',
            'text-align': 'left',
            'padding-top': '16px',
            'padding-right': '0',
            'padding-bottom': '16px',
            'padding-left': '24px',
            'white-space': 'nowrap',
            color: variable.blackColor
        },
        0: {
            'font-weight': 700,
            'background-color': variable.greyColor3,
            'font-size': '0.875rem',
            'line-height': '1.47',
            'text-transform': 'uppercase',
            'text-align': 'left',
            'padding-top': '10px',
            'padding-right': '0',
            'padding-bottom': '10px',
            'padding-left': '18px',
            'white-space': 'nowrap',
            color: variable.blackColor
        }
    }),
    thContent: {
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'center',
        'align-items': 'center',
        color: variable.blackColor
    },
    dropdownBlockRow: {
        display: 'flex',
        'align-items': 'center',
        'flex-direction': 'row',
        'justify-content': 'flex-start',
        'align-self': 'flex-start',
        'background-color': variable.greyColor3,
        'border-radius': '14px',
        padding: '8px',
        transition: 'box-shadow 0.3s'
    },
    dropdownBlockRowActiveWeb: {
        'background-color': variable.greyColor3,
        'border-radius': '14px'
    },
    dropdownBlockColumn: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'flex-start',
            'flex-direction': 'column',
            'justify-content': 'flex-start',
            'align-self': 'flex-start',
            position: 'absolute',
            left: 0,
            right: 0,
            top: '32px',
            width: 'fit-content',
            'z-index': 10,
            'background-color': variable.greyExtraDarkTransparentColor,
            'border-radius': '8px',
            padding: '8px'
        },
        0: {
            display: 'flex',
            'align-items': 'flex-start',
            'flex-direction': 'column',
            'justify-content': 'flex-start',
            'align-self': 'flex-start',
            position: 'absolute',
            width: 'fit-content',
            left: 0,
            right: 0,
            top: '32px',
            'z-index': 10,
            'background-color': variable.greyExtraDarkTransparentColor,
            'border-radius': '8px',
            padding: '6px'
        }
    }),
    dropdownBlockColumnActiveWeb: {
        'background-color': variable.whiteColor,
        'box-shadow': `0px 10px 10px ${variable.calcColor('black', .05)}`
    },
    dropdownItem: {
        display: 'flex',
        'align-items': 'center',
        color: variable.blackColor,
        ...uikit.text,
        'font-weight': 500
    },
    package_null: {
        'background-color': '#F0F5F7',
        color: variable.blueColor2,
        'font-weight': '600'
    },
    td__depth: {
        width: '18px',
        height: '18px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '5px',
        'background-color': variable.blueColor,
        'align-self': 'center',
        'margin-right': '12px',
        position: 'relative',
        'z-index': 5,
        color: variable.whiteColor
    },
    rangName: media(width, height, 'w', {
        768: {
            padding: '4px 10px 4px 25px',
            'border-radius': '0% 10% 10% 0%',
            ...uikit.small,
            'line-height': '1.14',
            'font-weight': '500',
            height: '24px',
            left: '-20px',
            top: '12px',
            position: 'relative',
            'white-space': 'nowrap'
        },
        0: {
            padding: '0 10px 0 25px',
            'border-radius': '0% 10% 10% 0%',
            ...uikit.small,
            'line-height': '1.14',
            'font-weight': '500',
            height: '24px',
            left: '-20px',
            top: '2px',
            position: 'relative',
            'white-space': 'nowrap',
            display: 'flex',
            'align-items': 'center'
        }
    }),
    rangSvgBox: media(width, height, 'w', {
        768: {
            width: '50px',
            height: '50px',
            'border-radius': '50%',
            position: 'relative',
            'z-index': 2,
            'background': 'transparent',
            'text-align': 'center',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            cursor: 'pointer'
        },
        0: {
            width: '30px',
            height: '30px',
            'border-radius': '50%',
            position: 'relative',
            'z-index': 2,
            'background': 'transparent',
            'text-align': 'center',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            cursor: 'pointer'
        }
    }),
    newUserLabel: {
        background: variable.successColor,
        'font-weight': '500',
        ...uikit.tiny,
        'color': variable.whiteColor,
        'padding': '2px 8px',
        'margin-left': '8px',
        'border-radius': '10px'
    },
    deleteUserLabel: {
        background: variable.errorColor,
        'font-weight': '500',
        ...uikit.tiny,
        'color': variable.whiteColor,
        'padding': '2px 8px',
        'margin-left': '8px',
        'border-radius': '10px'
    },
    orderStatus: {
        'white-space': 'nowrap',
        'border-radius': '8px',
        'padding': '6px 8px',
        ...uikit.small,
        'line-height': '1.14',
        color: variable.whiteColor,
        display: 'flex',
        'align-self': 'center',
        'background-color': variable.greyColor3,
    },
    package_10: { 'background-color': 'rgba(206, 176, 18, 1)' },
    package_20: { 'background-color': 'rgba(206, 142, 18, 1)' },
    package_30: { 'background-color': 'rgba(135, 137, 44, 1)' },
    package_40: { 'background-color': 'rgba(44, 137, 76, 1)' },
    package_50: { 'background-color': 'rgba(44, 104, 137, 1)' },

    package_60: { 'background-color': 'rgba(44, 58, 137, 1)' },
    package_70: { 'background-color': 'rgba(77, 44, 137, 1)' },
    package_80: { 'background-color': 'rgba(137, 44, 104, 1)' },
    package_90: { 'background-color': 'rgba(137, 44, 50, 1)' },
    package_wrap: { 'background-color': 'rgba(137, 44, 50, 1)' },
});
export default stylesheets;