import React from 'react';
import { Div, P } from '../../hybrid/wrappers';
import GridComponent from '../grid/GridComponent';
import T, { t } from '../../common/components/T';
import Display from '../../common/components/Display';
import { Skeleton } from '../plug/Skeleton';

export const transformSearchUserData = (dataUser) => {
    // convert data for SEARCH window format
    const group = [
        'username',
        'usernameFIO',
        'contacts'
    ];

    if (dataUser) {
        const newData = [];
        let accum = {};
        Object.keys(dataUser?.data).map((idx) => {
            group.map((item, key) => {
                {
                    Object.keys(dataUser.data[idx]).map((alias) => {
                        if (group[key] === 'contacts' && !dataUser.data[idx].contacts) {
                            accum.contacts = {
                                email: dataUser.data[idx].email,
                                phone: dataUser.data[idx].phone,
                                viber: dataUser.data[idx].viber,
                                telegram: dataUser.data[idx].telegram,
                                whatsapp: dataUser.data[idx].whatsapp,
                                facebook: dataUser.data[idx].facebook,
                                instagram: dataUser.data[idx].instagram,
                                twitter: dataUser.data[idx].twitter
                            };
                        } else if (group[key] === 'usernameFIO') {
                            accum.usernameFIO = dataUser.data[idx].full_name ||
                                <Display type='fio' data={dataUser.data[idx]}/>;
                        } else if (group[key] === alias) {
                            accum[`${alias}`] = dataUser.data[idx][`${alias}`];
                        }
                    });
                }
            });
            Object.keys(dataUser.data[idx]).forEach((item) => {
                if (accum[item] === undefined) {
                    accum[item] = dataUser.data[idx][item]
                }
            })
            newData[idx] = accum;
            accum = {};
        });
        const accumReturn = {
            ...dataUser,
            data: newData
        };
        return accumReturn;
    }
    return false;
};
export const getUserTable = (
    userTableData, styles, gridHandlers, gridConfig, thead, transformSearchData, translates
) => {
    const stylesTableWrapper = {
        conttype: 'scroll',
        table: styles.searchTable
    };
    const dataThead = thead || {
        data: [
            {
                title: t(
                    'SearchWindow-id', 'ID', 'widget', translates, 'searchWindow'
                ),
                type: 'login',
                name: 'username'
            },
            {
                title: t(
                    'SearchWindow-FIO', 'ФИО', 'widget', translates, 'searchWindow'
                ),
                type: 'text',
                name: 'usernameFIO',
                align: 'left'
            },
            {
                name: 'contacts',
                title: t(
                    'SearchWindow-Contacts', 'Контакты', 'widget', translates, 'searchWindow'
                ),
                type: 'contacts',
                align: 'left'
            }
        ]
    };
    return (
        <Skeleton
            elements={[
                {
                    type: 'table',
                    size: {
                        rows: 10,
                        columns: 4
                    }
                }]
            }
            wait={Boolean(userTableData)}
        >
            <Div conttype='scroll' styles={styles.searchWindowTable}>
                {
                    !!userTableData?.data?.length
                        ?
                        <GridComponent
                            viewThead={dataThead}
                            viewData={userTableData}
                            gridName='userSearch'
                            convertData={transformSearchData || transformSearchUserData}
                            handlers={gridHandlers}
                            variant={2}
                            gridStyles={{
                                ...gridConfig || {},
                                tableWrapper: stylesTableWrapper,
                                td: {
                                    usernameFIO: {
                                        p: {
                                            'max-width': ' 400px',
                                            'white-space': ' break-spaces'
                                        }
                                    },
                                    ...gridConfig?.td || {}
                                },
                                username: {
                                    icoWrap1: {
                                        styles: styles.userNameBlock
                                    }
                                },
                            }}
                        />
                        :
                        <Div styles={styles.noDataAlert}>
                            <P>
                                <T textName='gridNoData' defaultStr='Нет данных' page='all' />
                            </P>
                        </Div>
                }
            </Div>
        </Skeleton>
    )
}