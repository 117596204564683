import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { Div } from '../../../../hybrid/wrappers';
import FormInput from './FormInput';
import FromInputTitle from '../FormFieldTitle';
import T, { t } from '../../../../common/components/T';
import { LangContext } from '../../../../common/decorators/Language';

const FormRange = (props) => {
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    return (
        <>
            <Div styles={props?.compStyle?.wrapper}>
                {
                    !!props.title

                &&
                    <FromInputTitle
                        styles={props.styles}
                        type={props.type}
                        errors={props.errors}
                        title={props.title}
                        compStyle={props.compStyle}
                        hint={props.hint}
                        required={props.required}
                    />

                }
                <Div styles={{ ...props.styles.formRangeFlex, ...props?.compStyle?.flex }}>
                    {
                        props.options.map((option, index) =>
                            <React.Fragment key={`range_${option}`}>
                                {
                                    props.placeholder === false ?
                                        <FormInput
                                            {...props}
                                            value={!!props?.value ? props?.value[option] || null : !!props?.formData ? props?.formData[option] || null : null}
                                            compStyle={props?.compStyle[option] || {}}
                                            attribute={option}
                                            placeholder={index === 0 ? t('from', 'От', 'all', translation) : t('to', 'До', 'all', translation)}
                                            title={false}
                                        />
                                        :
                                        <FormInput
                                            {...props}
                                            value={!!props?.value ? props?.value[option] || null : !!props?.formData ? props?.formData[option] || null : null}
                                            compStyle={props?.compStyle[option] || {}}
                                            attribute={option}
                                            title={{ body: index === 0 ? <T textName='from' defaultStr='От' page='all' /> : <T textName='to' defaultStr='До' page='all' /> }}
                                        />

                                }

                                {
                                    index === 0 && <Div styles={{ ...props.styles.formRangeSeparator, ...props?.compStyle?.separator }}/>
                                }
                            </React.Fragment>)
                    }
                </Div>

            </Div>
        </>

    );
};

FormRange.propTypes = {
    options: PropTypes.array,
    value: PropTypes.object
};

export default Styles(FormRange, getStyles);