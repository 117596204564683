import { getData, navigate } from './router';
import {
    API_SERVER_URL, getCategoriesTreeFetch, SITE_URL
} from './utils';
import { zendoStorage } from '../../hybrid/wrappers';
import memoize from 'lodash.memoize';
import values from 'lodash.values';
import axios from 'axios/index';
import moment from 'moment'

export const getMemoizedData = memoize(async (url, userToken, lang, currency) => {
    let key = Object.keys(getMemoizedData.cache['__data__']['string']['__data__']).find((key) => {
        let array = key.split('_');
        return array[0] === url
            && array[1] === userToken
            && array[2] === lang
            && array[3] === currency
    })
    if (key) {
        getMemoizedData.cache.delete(key);
    }
    if (!url) {
        return false;
    }
    const resultUrl = url.includes('https://') || url.includes('http://') ? url : `${API_SERVER_URL}${url}`
    try {
        const res = await fetch(resultUrl, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;charset=UTF-8',
                ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
                ...lang ? { 'Accept-Language': lang } : {},
                mode: 'no-cors',
                ...currency ? { 'Accept-Currency': currency } : {}
            }
        })
        if (res.status === 500){
            console.warn(res);
            return { status: 500 }
        }
        if (res.status === 404 || !res){
            return false
        } else if (res.status === 503) {
            navigate('/[lang]/error/[type]', '/error/503', lang);
            return false
        } else {
            return await res.json();
        }
    } catch (e) {
        console.warn(url, e);
        return false;
    }
}, (...args) => [...values(args), moment().format('DD.MM.YYYY.hh.mm')].join('_'));

// Данные о пользователе
export const userProfileData = memoize(async (userToken, lang) => {
    userProfileData.cache.clear();
    try {
        return await axios({
            method: 'GET',
            url: `${API_SERVER_URL}/api/v1/user/profile`,
            headers: {
                ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;charset=UTF-8',
                ...lang ? { 'Accept-Language': lang } : {}
            }
        });
    } catch (e) {
        return false;
    }
}, (...args) => [...values(args), moment().format('DD.MM.YYYY.hh.mm')].join('_'));

export const checkIsAdmin = memoize(async (userToken) => {
    checkIsAdmin.cache.clear();
    return await axios({
        method: 'GET',
        url: `${API_SERVER_URL}/api/v1/admin/profile`,
        headers: {
            ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }).catch((e) => {
        console.warn(e)
        return false
    })
}, (...args) => [...values(args), moment().format('DD.MM.YYYY.hh.mm')].join('_'));

export const checkIsPartner = memoize(async (userToken, lang) => {
    checkIsPartner.cache.clear();
    try {
        return await axios({
            method: 'GET',
            url: `${API_SERVER_URL}/api/v1/user/partner`,
            headers: {
                ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;charset=UTF-8',
                ...lang ? { 'Accept-Language': lang } : {}
            }
        });
    } catch (e) {
        return e?.response?.status
    }
}, (...args) => [...values(args), moment().format('DD.MM.YYYY.hh.mm')
    .slice(0, -1)].join('_'));

export const getUserRoles = memoize(async (userToken, lang) => {
    getUserRoles.cache.clear();
    if (!userToken) {
        return []
    }
    const res = await getData('/api/v1/user/roles', userToken, lang)
    return res?.data || [];
}, (...args) => [...values(args), moment().format('DD.MM.YYYY.hh')
    .slice(0, -1)].join('_'));

// Данные о пользователе - очистка
export const clearUserCache = () => {
    getUserRoles.cache.clear();
    checkIsPartner.cache.clear();
    getMemoizedData.cache.clear();
    checkIsAdmin.cache.clear();
    userProfileData.cache.clear();
};

// Получение данных из таблицы settings
export const getSettings = memoize(async (alias) => {
    const res = await getData(`${SITE_URL}/api/settings/${alias}`);
    return res?.data?.data
})

// Возвращает false - если пользователь согласился использовать cookie или согласие не требуется
export const getDataCookies = memoize(async () => {
    let agree = zendoStorage.get('cookieAgreement')
    if (agree) {
        return false;
    }
    const res = await getSettings('cookie')
    if (res?.notification === '1') {
        return res;
    }
    return false
});

// Получение списка гео-данных
export const getGeoData = memoize(async (url, userToken, lang) => {
    const data = await axios({
        method: 'GET',
        url: url,
        headers: {
            ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=UTF-8',
            ...lang ? { 'Accept-Language': lang } : {}
        }
    });
    return data.data;
}, (...args) => [...values(args)].join('_'));

export const getGeoName = memoize(async (id, lang) => await getData(`/api/v1/geonames/${id}`, false, lang), (...args) => values(args).join('_'));

// Получение списка активных языков
export const getLangs = memoize(async () => {
    const res = await getData(`${SITE_URL}/api/languages?type=active`);
    return res?.data;
});

// Получение списка языков
export const getAllLangs = memoize(async () => {
    const res = await getData(`${SITE_URL}/api/languages`);
    return res?.data;
});

// Получение переводов
export const getLangLocale = memoize(async (language, page, group, name) => {
    let lang = language;
    if (!lang) {
        const langFetchRes = await getLangs();
        lang = langFetchRes?.data?.find((item) => item.isDefault) ? langFetchRes.data.find((item) => item.isDefault).alias : 'ru';
    }
    const trans = await fetch(`${SITE_URL}/api/languages/translations?lang=${lang}${page ? '&page=' + page : ''}${group ? '&group=' + group : ''}${name ? '&name=' + name : ''}`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const transJson = await trans.json();

        if (transJson && typeof transJson === 'object') {
            return transJson;
        } 
    return await trans.json();
}, (...args) => values(args).join('_'));

// Получение переводов
export const getCategoriesTree = memoize(async (data, userToken, lang, ip) => {
    return await getCategoriesTreeFetch(data, userToken, lang, ip)
}, (...args) => [...values(args)[0]?.map(d => d.id).join('_'), ...values(args).slice(1)].join('_'))

// Список активных валют
export const getCurrencies = memoize(async (lang, userToken) => await getData('/api/v1/user/finance/active-real-currency-list', userToken, lang), (...args) => [...values(args)].join('_'));

// Проверка пользователя на право быть спонсором
export const getIsSponsor = memoize(async (userToken, refer) => await getData(`/api/v1/sponsors/${refer}`, userToken), (...args) => [...values(args)].join('_'));

// Menu
export const getMenu = memoize(async (location) => {
    const data = await getData(`${SITE_URL}/api/menu?activity=true&location=${location}`)
    return { [location]: data?.data }
});

// Информация о пользователях
export const userInfo = memoize(async function (rowData, userToken, lang, setUserData) {
    try {
        let key = Object.keys(userInfo.cache['__data__']['string']['__data__']).find((key) => {
            let array = key.split('_');
            return array[0] === rowData?.links?.marketing_profile
                && array[1] === rowData?.id
                && array[2] === rowData?.links?.partner
                && array[3] === lang
        })
        if (key) {
            userInfo.cache.delete(key);
        }
    } catch (e) {
    }
    const data = await getData(rowData?.links?.partner || rowData?.links?.marketing_profile || `/api/v1/user/marketing/partners/${rowData.id}`, userToken, lang)
    setUserData({
        ...rowData,
        ...data.data,
        loaded: false
    })
    const rankData = await getData(data?.data?.links?.rank_progress || data?.data?.marketing_profile?.links?.rank_progress || `/api/v1/user/marketing/partners/${rowData.id}/rank-progress`, userToken, lang)
    return {
        ...rowData,
        ...data.data,
        rankData: rankData?.data,
        loaded: true
    }
}, (rowData, userToken, lang) => `${rowData?.links?.marketing_profile}_${rowData?.id}_${rowData?.links?.partner}_${lang}_${userToken}`);

export const getWishList = memoize(async (userToken, lang, currency) => {
    const res = await getData(
        '/api/v1/shop/wishlist/product-list', userToken, lang, null, currency
    )

    return res || {}
}, (...args) => values(args).join('_'));