// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P } from '../../../hybrid/wrappers';

const PartnerStats = ({
    getHandlers, gridStyles, val, name
}) =>
    <P
        {...getHandlers(false)}
        styles={gridStyles[name]?.wrapper?.styles || {}}
        effects={gridStyles[name]?.wrapper?.effects || {}}
    >
        {
            Object.keys(val).map((item, idx) => <React.Fragment key={`statspartner${idx}`}>{`${val[item]} `}</React.Fragment>)
        }
    </P>;
PartnerStats.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string
};

export default PartnerStats;