import React, {
    useCallback, useContext, useEffect, useState, useRef
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import {
    unscrollableBody, useWindowDimensionsHeight, useWindowScrollDimensions
} from '../../../../../common/utils/windowDimensions';
import ReactDOM from 'react-dom';
import { existLocales } from '../../../../../common/utils/dateLocales';
import UserContext from '../../../../../common/utils/getContext';
import CustomHeader from './CustomHeader';
import { Div } from '../../../../../hybrid/wrappers';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import getStyles from '../../../../../project/styles/widget-styles/datepicker-range-styles';
import Styles from '../../../../../common/decorators/Styles';
import PropTypes from 'prop-types';

const CustomPicker = ({
    pickerElement, styles, componentConfig, handlers = {}, setFocused, attribute, value, showTimeSelect
}) => {
    const { lang } = useContext(UserContext);


    const modalElement = useRef()
    const [langLocale, setLangLocale] = useState('ru');
    const heightDimensions = useWindowDimensionsHeight();

    useEffect(() => {
        if (existLocales[lang]){
            registerLocale(lang, existLocales[lang]);
            setLangLocale(lang);
        } else {
            (async () => {
                const locale = await import('date-fns/locale/ru');
                const localeDef = await locale;
                if (localeDef.default){
                    registerLocale('ru', localeDef.default);
                }
            })();
        }
    }, [lang]);

    const func = useCallback(() => {
        if (!isMobile && modalElement.current){
            const picker = pickerElement.current.getBoundingClientRect();
            const modal = modalElement.current.getBoundingClientRect();
            let lPos = picker.right - modal.width
            if (lPos < 0){
                lPos = picker.left;
            }
            let tPos = picker.top + window?.scrollY + picker.height + 10;
            if (tPos + modal.height > window?.scrollY + heightDimensions){
                tPos = picker.top + window?.scrollY - modal.height - 10;
                if (tPos < window?.scrollY){
                    tPos = picker.top + window?.scrollY + picker.height + 10;
                }
            }
            modalElement.current.style.left = lPos + 'px';
            modalElement.current.style.top = tPos + 'px';
        }
    }, [pickerElement, heightDimensions]);
    useEffect(() => {
        func()
    }, [func]);
    useEffect(() => {
        useWindowScrollDimensions(func)
        return () => {
            useWindowScrollDimensions(func, true)
        }
    }, [func]);

    const closeCalendal = useCallback((v) => {
        if (v && componentConfig?.onChange){
            componentConfig?.onChange(v)
        }
        if (v && componentConfig?.onSelect){
            componentConfig?.onSelect(v)
        }
        setFocused(false);
        !!handlers?.onBlur && handlers?.onBlur(attribute, value ? moment(value, showTimeSelect ? 'DD.MM.yyyy HH:mm' : 'DD.MM.yyyy').format(showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD') : null);
    }, [attribute, componentConfig, handlers, setFocused, showTimeSelect, value])

    unscrollableBody(isMobile, closeCalendal);
    const config = {
        ...componentConfig,
        locale: langLocale,
        onChange: closeCalendal,
        inline: true,
        renderCustomHeader: ({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
        }) =>
            <CustomHeader
                changeYear={changeYear}
                changeMonth={changeMonth}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
                date={date}
            />

    };

    return (
        ReactDOM.createPortal(<Div>
            <Div data-uitest='6_uitest' onClick={() => { closeCalendal() }} styles={styles.customDatePickerBackdrop}/>
            <Div styles={{
                ...styles.customDatePicker, width: showTimeSelect ? '340px' : '250px', height: '300px'
            }} childrenRef={modalElement}>
                <DatePicker
                    {...config}
                />
            </Div>
        </Div>
        ,
        document?.body)
    )
};

CustomPicker.propTypes = {
    pickerElement: PropTypes.object,
    styles: PropTypes.object,
    componentConfig: PropTypes.object,
    handlers: PropTypes.object,
    setFocused: PropTypes.func,
    attribute: PropTypes.string,
    value: PropTypes.string,
    showTimeSelect: PropTypes.bool
};
export default Styles(CustomPicker, getStyles);