import { navigate, getData } from './router';
import { clearUserCache, getLangLocale } from './memo';
import {
    getLangsConnect, getMenuConnect, getSettingsConnect, getLangLocaleConnect
} from './api';
import moment from 'moment/moment';
import axios from 'axios/index';
import { t } from '../../common/components/T';
import { zendoStorage } from '../../hybrid/wrappers';

const project = require('../../scripts/configs/project');

// Переменные среды

const getDomain = (domains, isCopy) => {
    if (!domains) return false;
    const array = domains.split('|');
    let URL = array[0];
    if (typeof window != 'undefined'){
        URL = array.find((d) => (isCopy ? 'ut.' : '') + d === window.location.host) || array[0];
    }
    return URL;
};


// получение элемента с LocalStorage
export const getLocalStorageItem = (key, defaultValue, needToAddToLs, needToParseValue) => {
    try {
        let ls_item = zendoStorage.get(key);
        if (!ls_item) {
            if (needToAddToLs) {
                zendoStorage.set(key, defaultValue)
            }
            return defaultValue
        }
        if (needToParseValue) {
            ls_item = JSON.parse(ls_item);
        }
        return ls_item;
    } catch (e){
        return defaultValue
    }
};

const getServerUrl = () => {
    let URL = getDomain(process.env.NEXT_PUBLIC_DOMAINS, process.env.NEXT_PUBLIC_ISCOPY);
    return URL ? `https://${process.env.NEXT_PUBLIC_ISCOPY ? 'at.' : 'api.'}${URL}` : process.env.NEXT_PUBLIC_SERVER_BACK_URL;
};
export const API_SERVER_URL = getServerUrl();
const getSiteUrl = () => {
    let URL = getDomain(process.env.NEXT_PUBLIC_DOMAINS, process.env.NEXT_PUBLIC_ISCOPY);
    return URL ? `https://${process.env.NEXT_PUBLIC_ISCOPY ? 'ut.' : ''}${URL}` : process.env.NEXT_PUBLIC_SERVER_FRONT_URL;
};
export const SITE_URL = getSiteUrl();

// Стили для определенной ширины/высоты экрана
// TODO: передаем объект, в котором ключи - min-width/min-height, используются только правила из подходящего объекта, type - width или height
export const media = (width, height, type, data) => {
    const keys = Object.keys(data).sort((a, b) => b - a);

    if (keys.length) {
        for (let i = 0;i < keys.length;i++) { if (Number(keys[i]) <= Number(type === 'w' ? width : height)) return data[keys[i]]; }
    } else { return {}; }
};

// Получение списка пунктов меню для определенного пользователя
export const rolesMenu = (menus, dataRoles) => {
    return menus ? Object.keys(menus).reduce((ac, key) => {
        if (!menus[key]) return ac;
        return {
            ...ac,
            [key]: menus[key]?.filter((m) =>
                m.showAll || !m?.roles.length || m?.roles?.some((r) =>
                    dataRoles && dataRoles.find((u) =>
                        u.name === r || u.name === 'superadmin'))) || []
        }
    }, {}) : {}
};

// Разлогин
export const delToken = async (userToken, lang, setter) => {
    clearUserCache();
    const del = async () => {
        if (setter) await setter(false);

        navigate('/[lang]/authentication/[type]', '/authentication/login', lang);
    };
    if (userToken) {
        navigate('/[lang]/logout', '/logout', lang);
    } else {
        await del();
    }
};

// Объединение списка групп со списком полей формы
export const compileGroupsData = (dataTabs, dataFields, translation) => {
    if (dataTabs && dataFields) {
        return dataTabs.reduce((accum, tab) => {
            accum[tab.alias] = {
                title: tab.title,
                fields: tab.alias === 'basic'
                    ? dataFields.filter((field) => field.group === tab.alias).map(el => !!el?.placeholder && !!el?.disabled ? { ...el, tooltip: t('input-tooltip-disabled', 'Для изменения данных после прохождения верификации обратитесь в службу поддержки', '/profile', translation) } : el)
                    : dataFields.filter((field) => field.group === tab.alias)
            };

            return accum;
        }, {});
    }
    return false;
};

// Получение корзины
export const getCartData = async (
    cartId, userToken, lang, ip, currency
) => {
    if (!cartId && !userToken){
        return {}
    }
    let cart = ''
    if (cartId && !userToken) {
        cart = `/${cartId}`
    }

    let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
        ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
        ...ip ? { 'ClientNodeIp': ip } : {},
        ...lang ? { 'Accept-Language': lang } : {}
    }

    if (currency) {
        headers['Accept-Currency'] = currency
    }

    let response = await fetch(`${API_SERVER_URL}/api/v1/shop/carts${cart}`,
        {
            method: cartId && !userToken ? 'GET' : 'POST',
            headers
        })
    return await response.json()
}

// Определение окончаний слов в зависимости от количества
export const declOfNum = (n, text_forms) => {
    n = Math.abs(n) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }
    if (n1 === 1) {
        return text_forms[0];
    }
    return text_forms[2];
};

// Получение прогресса для радиального прогресс-бара
export const getStatusProgress = (data) => {
    let conditionDone = 0;
    let conditionLength = 0;
    let diff = data ? 100 : 0;
    if (!!data?.conditions){
        conditionLength = Object.keys(data?.conditions).length;
        data?.conditions?.next_rank_package?.has_package && conditionDone++;
        if (!!data?.conditions?.volumes){
            conditionLength = conditionLength + data?.conditions?.volumes?.length - 1;
            data?.conditions?.volumes.forEach((volume) => {
                if (volume.accumulated>=volume.target){
                    conditionDone++
                }
            })
        }
        if (data.conditions.branches_with_rank?.number){
            conditionLength = conditionLength + Math.floor(data.conditions.branches_with_rank?.number) - 1;
            conditionDone = conditionDone + Math.floor(data.conditions.branches_with_rank?.target_number)
        }
        diff = !!conditionDone&&!!conditionLength ? 100/conditionLength*conditionDone : 0
        if (data?.next_rank_logical_operator === 'or'){
            if (data?.conditions?.next_rank_package?.has_package){
                diff = 100
            }
            if (!!data?.conditions?.volumes){
                let volumes = data?.conditions?.volumes.every((volume) => {
                    return volume.accumulated>=volume.target
                })
                if (volumes) diff = 100;
            }
            if (data.conditions.branches_with_rank?.number>=data.conditions.branches_with_rank?.target_number){
                diff = 100
            }
        }
    }
    if (diff > 100){
        diff = 100
    }
    return {
        dasharray: 394,
        dashoffset: (394 - 109) * (100-diff) / 100 + 109
    };
}

// Получение дерева категорий в магазине
export const getCategoriesTreeFetch = async (
    data, userToken, lang, ip, currency
) => {
    const newData = [];
    if (!data) return [];
    for (let i = 0;i < data.length;i++){
        if (data[i].has_children && !data[i].children?.length){
            let res = await getData(
                `${API_SERVER_URL}/api/v1/shop/product-categories?filter[parent_id]=${data[i].id}&page[size]=8`, userToken, lang, ip, currency
            )
            newData.push({
                ...data[i], children: res.data, has_children: true
            })
        } else {
            newData.push(data[i])
        }
    }
    return newData
}
export const getCategoriesTreeSsr = async (
    data, userToken, lang, ip, currency
) => {
    return await getCategoriesTreeFetch(
        data, userToken, lang, ip, currency
    )
}


// Получить url без get-параметров
export const removeURLParameter = (url, parameter) => {
    let urlparts = url?.split('?');
    if (urlparts.length >= 2) {
        let prefix = encodeURIComponent(parameter) + '=';
        let pars = urlparts[1]?.split(/[&;]/g);

        for (let i = pars.length;i-- > 0;) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

// Установить язык писем для пользователя
export const setLanguageForEmail = async (userToken, lang) => {
    try {
        return await axios({
            method: 'POST',
            url: `${API_SERVER_URL}/api/v1/user/profile/lang-code`,
            headers: {
                ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            data: { language_code: lang }
        });
    } catch (e) {
        return console.warn('error', e);
    }
};
export async function getWidgetTranslates(lang, widget, translates, setTranslates = () => {}){
    const staticData = translates?.widget_translates?.[widget]?.data;
    if (!staticData){
        const transData = await getLangLocale(lang, null, widget);
        if (transData?.data){
            setTranslates(transData.data);
            return transData.data
        } else {
            setTranslates({});
            return {}
        }
    } else {
        setTranslates(staticData)
        return staticData
    }
}
// Стандартные данные, актуальные для всех страниц
export async function getDefaultStaticProps(lang, page) {
    const langFetchRes = await getLangsConnect({ $or: [{ isDefault: true }, { active: true }] });
    const activeLangs = langFetchRes?.data || [];
    if (!lang && !page) return { activeLangs }

    let isActiveLanguage = activeLangs?.find((item) => item.alias === lang);
    if (!isActiveLanguage) return false;
    // TODO IP
    const currencies = await getData('/api/v1/user/finance/active-real-currency-list', false, lang, moment().format('1DD.ss.2MM.3mm'));
    const currency = currencies?.data?.find(el => el.main === true)?.code || 'USD'

    const translates = await getLangLocaleConnect({ page: { $in: ['all', ...page?.split(',') || []] } }, lang);
    const widget_translates = {
        datePickerRange: await getLangLocaleConnect({ page: 'widget', group: 'datePickerRange' }, lang),
        userModal: await getLangLocaleConnect({ page: 'widget', group: 'userModal' }, lang),
        speedProgress: await getLangLocaleConnect({ page: 'widget', group: 'speedProgress' }, lang),
        events: await getLangLocaleConnect({ page: 'widget', group: 'events' }, lang),
        userSidebar: await getLangLocaleConnect({ page: 'widget', group: 'userSidebar' }, lang),
        AppButtons: await getLangLocaleConnect({ page: 'widget', group: 'AppButtons' }, lang),
        excel: await getLangLocaleConnect({ page: 'widget', group: 'excel' }, lang),
        form: await getLangLocaleConnect({ page: 'widget', group: 'form' }, lang),
        month: await getLangLocaleConnect({ page: 'widget', group: 'month' }, lang),
        groupForm: await getLangLocaleConnect({ page: 'widget', group: 'groupForm' }, lang),
        grid: await getLangLocaleConnect({ page: 'widget', group: 'grid' }, lang),
        gridHeader: await getLangLocaleConnect({ page: 'widget', group: 'gridHeader' }, lang),
        gridFilter: await getLangLocaleConnect({ page: 'widget', group: 'gridFilter' }, lang),
        modal2FA: await getLangLocaleConnect({ page: 'widget', group: 'modal2FA' }, lang),
        searchWindow: await getLangLocaleConnect({ page: 'widget', group: 'searchWindow' }, lang),
        SiteSettings: await getLangLocaleConnect({ page: 'widget', group: 'SiteSettings' }, lang)
    }

    const seoRes = await getSettingsConnect('seo');
    const seo = seoRes?.data?.data || null;

    const timeRes = await getData('/api/v1/settings/date', false, lang, moment().format('1DD.ss.2MM.3mm'));
    const time = moment(timeRes?.data?.date, 'YYYY-MM-DD HH:mm:ss');
    const offset = time.diff(moment(new Date()));

    const menu = await getMenuConnect({ activity: true, location: 'site' });
    const menuOffice = await getMenuConnect({ activity: true, location: 'office' });
    const menuFooter = await getMenuConnect({ activity: true, location: 'footer' });
    const menuAdmin = await getMenuConnect({ activity: true, location: 'admin' });

    const settingsRes = await getSettingsConnect('display');
    const settings = settingsRes?.data?.data || null;

    const headers = { headers: { ClientNodeIp: moment().format('1DD.ss.2MM.3mm'), ...lang ? { 'Accept-Language': lang } : {} } }

    const fetchCaptcha = await fetch(`${API_SERVER_URL}/api/v1/captcha`, headers);
    const dataCaptcha = fetchCaptcha.status === 200 ? await fetchCaptcha.json() : null;
    const captcha = dataCaptcha ? dataCaptcha.data : dataCaptcha;
    return {
        captcha,
        currencies,
        currency,
        offset,
        settings,
        menu: {
            site: menu?.data, office: menuOffice?.data, footer: menuFooter?.data, admin: menuAdmin?.data
        },
        seo,
        activeLangs,
        translates: {
            ...translates.data,
            widget_translates
        },
        lang: lang
    };
}

// Стандартные данные, актуальные для страниц кабинета и панели управления
export async function getUserStaticProps (params, page) {
    const lang = params?.lang;
    const defaultProps = await getDefaultStaticProps(lang, page)
    if (!defaultProps){
        return { notFound: true }
    }
    return {
        props: { ...defaultProps },
        revalidate: project?.revalidate
    }
}

// Стандартные данные, актуальные для страниц кабинета и панели управления для динамических роутов
export async function getUserServerSideProps (params, page, res) {
    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')

    const lang = params?.lang;
    const defaultProps = await getDefaultStaticProps(lang, page)
    if (!defaultProps){
        return { notFound: true }
    }
    return { props: { ...defaultProps } }
}

// getStaticPath для активных языков
export const getLangPaths = async () => {
    const langFetchRes = await getLangsConnect({ $or: [{ isDefault: true }, { active: true }] });
    const activeLangs = langFetchRes?.data || [];

    return activeLangs?.map((lang) => ({ params: { lang: lang.alias } }))
}

export const camelizeStyles = (styles) => {
    if (!styles) return styles
    const replacer = s => s.replace(/-./g, x => x[1].toUpperCase());
    return Object.keys(styles)?.reduce((acc, curr) => ({
        ...acc,
        [replacer(curr)]: styles[curr]
    }), {})
}

export const checkFileSize = (image, scale) => new Promise((resolve) => {
    let img = new Image();
    img.onload = function() {
        if (this.width > scale || this.height > scale){
            resolve(false)
        } else {
            resolve(image)
        }
    }
    img.onerror = function (){
        resolve(image)
    }

    img.src = image;
})