import React from 'react';
import { Svg, Path } from '../../hybrid/wrappers';

const SvgSpriteLogo = ({ name, dataStyles }) => {
    switch (name) {
    case 'company-logo':
        return (
            <Svg {...dataStyles} viewBox='0 0 280 66'>
                <g clipPath='url(#clip0_347_60720)'>
                    <Path d='M16.1597 60.5175C16.1597 49.882 22.8423 38.0182 32.289 32.985C22.8423 27.9818 16.1597 16.118 16.1597 5.48252C16.1597 3.5951 16.3723 1.76759 16.7672 0C6.59145 7.51975 0 19.5034 0 32.985C0 46.4966 6.59145 58.4803 16.7672 65.97C16.3723 64.2324 16.1597 62.4049 16.1597 60.5175Z' />
                    <Path d='M54.5542 33.1947C54.5542 33.6441 54.5238 34.0935 54.4327 34.5429H43.2545C43.5887 36.2505 44.6822 37.1194 46.3224 37.1194C47.5375 37.1194 48.5398 36.61 49.0562 35.7113H54.22C53.1569 39.0967 50.1194 41.2238 46.3224 41.2238C41.6143 41.2238 38.1211 37.8384 38.1211 33.2846C38.1211 28.7308 41.5839 25.3754 46.3224 25.3754C51.1825 25.4053 54.5542 28.8207 54.5542 33.1947ZM43.3457 31.7267H49.4511C48.9955 30.2287 47.902 29.4498 46.3528 29.4498C44.834 29.4199 43.7709 30.2287 43.3457 31.7267Z' />
                    <Path d='M66.8561 36.2804V40.7743H63.2718C59.9306 40.7743 57.9258 38.767 57.9258 35.4715V29.8392H55.1616V28.6408L61.9049 21.5405H62.9681V25.8846H66.765V29.8392H63.12V34.5728C63.12 35.6513 63.7578 36.2804 64.8514 36.2804H66.8561Z' />
                    <Path d='M84.6865 33.1947C84.6865 33.6441 84.6561 34.0935 84.565 34.5429H73.3869C73.721 36.2505 74.8145 37.1194 76.4548 37.1194C77.6698 37.1194 78.6722 36.61 79.1885 35.7113H84.3524C83.2892 39.0967 80.2517 41.2238 76.4548 41.2238C71.7466 41.2238 68.2534 37.8384 68.2534 33.2846C68.2534 28.7308 71.7162 25.3754 76.4548 25.3754C81.3148 25.4053 84.6865 28.8207 84.6865 33.1947ZM73.478 31.7267H79.5834C79.1278 30.2287 78.0343 29.4498 76.4851 29.4498C74.936 29.4199 73.9032 30.2287 73.478 31.7267Z' />
                    <Path d='M96.8669 25.8247V30.4983H94.8621C93.0396 30.4983 92.2499 31.3072 92.2499 33.1947V40.7444H87.0557V25.9146H90.5185L91.3386 27.5623C92.4017 26.364 93.7079 25.8547 95.5 25.8547H96.8669V25.8247Z' />
                    <Path d='M113.725 31.6967V40.7743H108.531V32.4157C108.531 30.6781 107.863 29.8392 106.557 29.8392C104.947 29.8392 104.005 30.9477 104.005 32.8352V40.7743H98.811V25.9146H102.456L103.155 27.4425C104.279 26.2142 105.949 25.4652 107.893 25.4652C111.417 25.4652 113.725 27.9219 113.725 31.6967Z' />
                    <Path d='M116.824 25.9146H122.018V40.7743H116.824V25.9146ZM116.854 19.6831H121.957V24.0272H116.854V19.6831Z' />
                    <Path d='M135.444 36.2804V40.7743H131.859C128.518 40.7743 126.513 38.767 126.513 35.4715V29.8392H123.749V28.6408L130.492 21.5405H131.555V25.8846H135.352V29.8392H131.707V34.5728C131.707 35.6513 132.345 36.2804 133.439 36.2804H135.444Z' />
                    <Path d='M153.638 25.9147L147.32 40.1153C145.285 44.6691 143.432 46.3169 139.544 46.3169H137.752V41.9129H139.18C141.154 41.9129 141.762 41.4035 142.46 39.5461L136.233 25.9147H141.883L145.073 34.0935L148.049 25.9147H153.638Z' />
                    <Path d='M153.335 36.0109H158.377V40.7744H153.335V36.0109Z' />
                    <Path d='M170.284 25.6151C174.78 25.6151 177.939 28.9405 177.939 33.3445C177.939 37.7485 174.749 41.074 170.284 41.074C167.216 41.074 164.665 39.4562 163.419 36.9696L163.298 40.8043H162.539V20.6418H163.419V29.6895C164.726 27.2329 167.247 25.6151 170.284 25.6151ZM177.058 33.3445C177.058 29.3899 174.203 26.4839 170.254 26.4839C166.366 26.4839 163.419 29.4199 163.419 33.3445C163.419 37.2392 166.366 40.2052 170.254 40.2052C174.172 40.2052 177.058 37.2991 177.058 33.3445Z' fill={dataStyles?.textFill}/>
                    <Path d='M181.827 34.5429V25.9147H182.708V34.453C182.708 38.0182 184.804 40.2351 188.176 40.2351C191.547 40.2351 193.643 38.0182 193.643 34.453V25.9147H194.524V34.5429C194.524 38.5275 192.064 41.074 188.176 41.074C184.318 41.044 181.827 38.4975 181.827 34.5429Z' fill={dataStyles?.textFill}/>
                    <Path d='M198.321 36.7299H199.202C199.445 38.9469 201.237 40.2351 204.092 40.2351C206.978 40.2351 208.861 38.887 208.861 36.8497C208.861 34.2733 206.401 33.8239 203.91 33.4044C201.298 32.9551 198.594 32.5057 198.594 29.5996C198.594 27.2329 200.751 25.645 203.94 25.645C207.13 25.645 209.195 27.2329 209.317 29.8093H208.466C208.314 27.7122 206.644 26.4539 203.91 26.4539C201.237 26.4539 199.445 27.7122 199.445 29.5697C199.445 31.7866 201.632 32.1462 204.092 32.5656C206.735 32.985 209.712 33.5243 209.712 36.8198C209.712 39.3663 207.433 41.074 204.031 41.074C200.69 41.044 198.564 39.4262 198.321 36.7299Z' fill={dataStyles?.textFill}/>
                    <Path d='M213.66 21.0613H214.965V22.5892H213.66V21.0613ZM213.873 25.9147H214.753V40.7744H213.873V25.9147Z' fill={dataStyles?.textFill}/>
                    <Path d='M232.949 31.9663V40.7743H232.068V32.1161C232.068 28.5809 230.124 26.4239 226.874 26.4239C223.806 26.4239 221.163 29.0303 221.163 32.0862V40.7444H220.282V25.9146H220.981L221.133 29.3299C222.165 27.1429 224.444 25.615 227.026 25.615C230.671 25.615 232.949 28.1316 232.949 31.9663Z' fill={dataStyles?.textFill}/>
                    <Path d='M251.781 33.6142H237.717C237.839 37.419 240.664 40.2052 244.4 40.2052C247.073 40.2052 249.169 38.857 250.384 36.5501H251.325C249.898 39.4861 247.468 41.074 244.4 41.074C240.087 41.074 236.836 37.7485 236.836 33.3445C236.836 28.9705 240.087 25.645 244.4 25.645C248.744 25.645 251.811 28.9705 251.811 33.2546C251.811 33.3445 251.811 33.4943 251.781 33.6142ZM237.748 32.7753H250.931C250.688 29.0004 247.954 26.4539 244.4 26.4539C240.755 26.4539 237.991 29.1203 237.748 32.7753Z' fill={dataStyles?.textFill}/>
                    <Path d='M254.424 36.7299H255.305C255.548 38.9469 257.34 40.2351 260.195 40.2351C263.081 40.2351 264.964 38.887 264.964 36.8497C264.964 34.2733 262.504 33.8239 260.013 33.4044C257.401 32.9551 254.697 32.5057 254.697 29.5996C254.697 27.2329 256.854 25.645 260.043 25.645C263.233 25.645 265.298 27.2329 265.42 29.8093H264.569C264.417 27.7122 262.747 26.4539 260.013 26.4539C257.34 26.4539 255.548 27.7122 255.548 29.5697C255.548 31.7866 257.735 32.1462 260.195 32.5656C262.838 32.985 265.815 33.5243 265.815 36.8198C265.815 39.3663 263.536 41.074 260.134 41.074C256.793 41.044 254.667 39.4262 254.424 36.7299Z' fill={dataStyles?.textFill}/>
                    <Path d='M268.548 36.7299H269.429C269.672 38.9469 271.464 40.2351 274.32 40.2351C277.205 40.2351 279.089 38.887 279.089 36.8497C279.089 34.2733 276.628 33.8239 274.137 33.4044C271.525 32.9551 268.822 32.5057 268.822 29.5996C268.822 27.2329 270.978 25.645 274.168 25.645C277.357 25.645 279.423 27.2329 279.544 29.8093H278.694C278.542 27.7122 276.871 26.4539 274.137 26.4539C271.464 26.4539 269.672 27.7122 269.672 29.5697C269.672 31.7866 271.859 32.1462 274.32 32.5656C276.962 32.985 279.939 33.5243 279.939 36.8198C279.939 39.3663 277.661 41.074 274.259 41.074C270.918 41.044 268.791 39.4262 268.548 36.7299Z' fill={dataStyles?.textFill}/>
                </g>
                <defs>
                    <clipPath id='clip0_347_60720'>
                        <rect width='280' height='66' fill='white'/>
                    </clipPath>
                </defs>
            </Svg>
        );
    case 'index-logo':
        return (
            <Svg {...dataStyles} viewBox='0 0 236 54' fill='none'>
                <g clipPath='url(#clip0_342_59870)'>
                    <Path d='M13.6203 49.5143C13.6203 40.8125 19.2528 31.1058 27.215 26.9877C19.2528 22.8942 13.6203 13.1875 13.6203 4.4857C13.6203 2.94144 13.7995 1.44621 14.1323 0C5.55565 6.15252 0 15.9573 0 26.9877C0 38.0427 5.55565 47.8475 14.1323 53.9755C13.7995 52.5538 13.6203 51.0586 13.6203 49.5143Z' fill='#2E6D8E'/>
                    <Path d='M45.9813 27.1593C45.9813 27.527 45.9557 27.8947 45.8789 28.2624H36.4574C36.739 29.6596 37.6607 30.3704 39.0432 30.3704C40.0673 30.3704 40.9121 29.9537 41.3474 29.2183H45.6997C44.8036 31.9882 42.2434 33.7286 39.0432 33.7286C35.0749 33.7286 32.1306 30.9587 32.1306 27.2329C32.1306 23.507 35.0493 20.7617 39.0432 20.7617C43.1395 20.7862 45.9813 23.5806 45.9813 27.1593ZM36.5342 25.9582H41.6802C41.2962 24.7326 40.3745 24.0953 39.0688 24.0953C37.7887 24.0708 36.8926 24.7326 36.5342 25.9582Z' fill='#2E6D8E'/>
                    <Path d='M56.3502 29.6841V33.3609H53.3292C50.5129 33.3609 48.8232 31.7186 48.8232 29.0223V24.414H46.4934V23.4335L52.1771 17.6242H53.0731V21.1784H56.2734V24.414H53.2012V28.2869C53.2012 29.1693 53.7388 29.6841 54.6605 29.6841H56.3502Z' fill='#2E6D8E'/>
                    <Path d='M71.3786 27.1593C71.3786 27.527 71.353 27.8947 71.2762 28.2624H61.8546C62.1362 29.6596 63.0579 30.3704 64.4404 30.3704C65.4645 30.3704 66.3094 29.9537 66.7446 29.2183H71.0969C70.2009 31.9882 67.6407 33.7286 64.4404 33.7286C60.4721 33.7286 57.5278 30.9587 57.5278 27.2329C57.5278 23.507 60.4465 20.7617 64.4404 20.7617C68.5367 20.7862 71.3786 23.5806 71.3786 27.1593ZM61.9314 25.9582H67.0774C66.6934 24.7326 65.7717 24.0953 64.466 24.0953C63.1603 24.0708 62.2898 24.7326 61.9314 25.9582Z' fill='#2E6D8E'/>
                    <Path d='M81.645 21.1294V24.9533H79.9552C78.4191 24.9533 77.7534 25.6151 77.7534 27.1593V33.3364H73.3755V21.2029H76.2941L76.9854 22.5511C77.8815 21.5706 78.9823 21.1539 80.4929 21.1539H81.645V21.1294Z' fill='#2E6D8E'/>
                    <Path d='M95.8543 25.9337V33.3609H91.4764V26.522C91.4764 25.1003 90.9131 24.414 89.8122 24.414C88.4553 24.414 87.6616 25.3209 87.6616 26.8652V33.3609H83.2837V21.2029H86.3559L86.9448 22.453C87.8921 21.448 89.3002 20.8352 90.9387 20.8352C93.9086 20.8352 95.8543 22.8452 95.8543 25.9337Z' fill='#2E6D8E'/>
                    <Path d='M98.4656 21.2029H102.844V33.3609H98.4656V21.2029ZM98.4912 16.1044H102.792V19.6587H98.4912V16.1044Z' fill='#2E6D8E'/>
                    <Path d='M114.16 29.6841V33.3609H111.139C108.323 33.3609 106.633 31.7186 106.633 29.0223V24.414H104.303V23.4335L109.987 17.6242H110.883V21.1784H114.083V24.414H111.011V28.2869C111.011 29.1693 111.548 29.6841 112.47 29.6841H114.16Z' fill='#2E6D8E'/>
                    <Path d='M129.495 21.2029L124.17 32.8216C122.455 36.5474 120.893 37.8956 117.616 37.8956H116.106V34.2923H117.309C118.973 34.2923 119.485 33.8756 120.074 32.3559L114.825 21.2029H119.587L122.276 27.8947L124.785 21.2029H129.495Z' fill='#2E6D8E'/>
                    <Path d='M129.239 29.4635H133.489V33.3609H129.239V29.4635Z' fill='#2E6D8E'/>
                    <Path d='M143.525 20.9578C147.314 20.9578 149.977 23.6786 149.977 27.2819C149.977 30.8852 147.289 33.606 143.525 33.606C140.94 33.606 138.789 32.2823 137.739 30.2478L137.637 33.3854H136.997V16.8888H137.739V24.2914C138.84 22.2814 140.965 20.9578 143.525 20.9578ZM149.235 27.2819C149.235 24.0463 146.828 21.6686 143.5 21.6686C140.223 21.6686 137.739 24.0708 137.739 27.2819C137.739 30.4685 140.223 32.8951 143.5 32.8951C146.802 32.8951 149.235 30.5175 149.235 27.2819Z' fill='black'/>
                    <Path d='M153.254 28.2624V21.2029H153.997V28.1888C153.997 31.1058 155.763 32.9197 158.605 32.9197C161.447 32.9197 163.213 31.1058 163.213 28.1888V21.2029H163.956V28.2624C163.956 31.5225 161.882 33.606 158.605 33.606C155.354 33.5815 153.254 31.498 153.254 28.2624Z' fill='black'/>
                    <Path d='M167.156 30.0518H167.898C168.103 31.8656 169.614 32.9197 172.02 32.9197C174.453 32.9197 176.04 31.8166 176.04 30.1498C176.04 28.0418 173.966 27.6741 171.867 27.3309C169.665 26.9632 167.386 26.5956 167.386 24.2179C167.386 22.2814 169.204 20.9823 171.892 20.9823C174.581 20.9823 176.322 22.2814 176.424 24.3895H175.707C175.579 22.6736 174.171 21.6441 171.867 21.6441C169.614 21.6441 168.103 22.6736 168.103 24.1934C168.103 26.0073 169.947 26.3014 172.02 26.6446C174.248 26.9877 176.757 27.429 176.757 30.1253C176.757 32.2088 174.837 33.606 171.969 33.606C169.153 33.5815 167.361 32.2578 167.156 30.0518Z' fill='black'/>
                    <Path d='M180.085 17.232H181.185V18.4821H180.085V17.232ZM180.264 21.2029H181.006V33.3609H180.264V21.2029Z' fill='black'/>
                    <Path d='M196.342 26.1543V33.3609H195.6V26.2769C195.6 23.3845 193.961 21.6196 191.222 21.6196C188.636 21.6196 186.409 23.7522 186.409 26.2524V33.3364H185.666V21.2029H186.255L186.383 23.9973C187.254 22.2079 189.174 20.9578 191.35 20.9578C194.422 20.9578 196.342 23.0168 196.342 26.1543Z' fill='black'/>
                    <Path d='M212.216 27.5025H200.362C200.464 30.6155 202.845 32.8951 205.994 32.8951C208.247 32.8951 210.014 31.7921 211.038 29.9047H211.832C210.628 32.3069 208.58 33.606 205.994 33.606C202.359 33.606 199.619 30.8852 199.619 27.2819C199.619 23.7031 202.359 20.9823 205.994 20.9823C209.655 20.9823 212.241 23.7031 212.241 27.2084C212.241 27.2819 212.241 27.4045 212.216 27.5025ZM200.387 26.8162H211.499C211.294 23.7276 208.99 21.6441 205.994 21.6441C202.922 21.6441 200.592 23.8257 200.387 26.8162Z' fill='black'/>
                    <Path d='M214.443 30.0518H215.186C215.39 31.8656 216.901 32.9197 219.308 32.9197C221.74 32.9197 223.327 31.8166 223.327 30.1498C223.327 28.0418 221.253 27.6741 219.154 27.3309C216.952 26.9632 214.674 26.5956 214.674 24.2179C214.674 22.2814 216.491 20.9823 219.18 20.9823C221.868 20.9823 223.609 22.2814 223.711 24.3895H222.994C222.866 22.6736 221.458 21.6441 219.154 21.6441C216.901 21.6441 215.39 22.6736 215.39 24.1934C215.39 26.0073 217.234 26.3014 219.308 26.6446C221.535 26.9877 224.044 27.429 224.044 30.1253C224.044 32.2088 222.124 33.606 219.256 33.606C216.44 33.5815 214.648 32.2578 214.443 30.0518Z' fill='black'/>
                    <Path d='M226.348 30.0518H227.09C227.295 31.8656 228.806 32.9197 231.212 32.9197C233.644 32.9197 235.232 31.8166 235.232 30.1498C235.232 28.0418 233.158 27.6741 231.059 27.3309C228.857 26.9632 226.578 26.5956 226.578 24.2179C226.578 22.2814 228.396 20.9823 231.084 20.9823C233.773 20.9823 235.513 22.2814 235.616 24.3895H234.899C234.771 22.6736 233.363 21.6441 231.059 21.6441C228.806 21.6441 227.295 22.6736 227.295 24.1934C227.295 26.0073 229.139 26.3014 231.212 26.6446C233.44 26.9877 235.949 27.429 235.949 30.1253C235.949 32.2088 234.029 33.606 231.161 33.606C228.345 33.5815 226.553 32.2578 226.348 30.0518Z' fill='black'/>
                </g>
                <defs>
                    <clipPath id='clip0_342_59870'>
                        <rect width='236' height='54' fill='white'/>
                    </clipPath>
                </defs>
            </Svg>

        );
    case 'index-logo-inc':
        return (
            <Svg {...dataStyles} viewBox="0 0 164 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1260_1685)">
                <path d="M13.284 49.517C13.284 40.7955 18.778 31.0958 26.568 26.9796C18.778 22.9042 13.284 13.1638 13.284 4.48302C13.284 2.93434 13.448 1.42642 13.776 0C5.412 6.15396 0 15.9758 0 26.9796C0 38.0242 5.412 47.846 13.817 53.9592C13.489 52.5736 13.284 51.0657 13.284 49.517Z" fill="#2E6D8E"/>
                <path d="M44.69 27.1834C44.69 27.5502 44.649 27.917 44.608 28.2838H35.506C35.793 29.6694 36.695 30.403 38.007 30.403C38.991 30.403 39.811 29.9547 40.221 29.2619H44.403C43.542 32.0332 41.082 33.7857 38.007 33.7857C34.194 33.7857 31.365 31.0143 31.365 27.3057C31.365 23.5562 34.194 20.8257 38.007 20.8257C41.943 20.7849 44.69 23.597 44.69 27.1834ZM35.588 25.9608H40.549C40.18 24.7381 39.278 24.086 38.007 24.086C36.777 24.086 35.916 24.7381 35.588 25.9608Z" fill="#2E6D8E"/>
                <path d="M54.653 29.7102V33.3781H51.742C49.036 33.3781 47.396 31.7479 47.396 29.0174V24.4121H45.141V23.434L50.635 17.606H51.496V21.1925H54.571V24.4121H51.619V28.2838C51.619 29.1396 52.152 29.6694 53.054 29.6694H54.653V29.7102Z" fill="#2E6D8E"/>
                <path d="M69.413 27.1834C69.413 27.5502 69.372 27.917 69.331 28.2838H60.229C60.516 29.6694 61.418 30.403 62.73 30.403C63.714 30.403 64.534 29.9547 64.944 29.2619H69.126C68.265 32.0332 65.805 33.7857 62.73 33.7857C58.917 33.7857 56.088 31.0143 56.088 27.3057C56.088 23.5562 58.917 20.8257 62.73 20.8257C66.666 20.7849 69.413 23.597 69.413 27.1834ZM60.311 25.9608H65.272C64.903 24.7381 64.001 24.086 62.73 24.086C61.5 24.086 60.639 24.7381 60.311 25.9608Z" fill="#2E6D8E"/>
                <path d="M79.0899 21.1517V24.9826H77.4497C75.9736 24.9826 75.3175 25.6347 75.3175 27.1834V33.3781H71.094V21.1925H73.9233L74.5794 22.5374C75.4405 21.5592 76.5066 21.1109 77.9418 21.1109H79.0899V21.1517Z" fill="#2E6D8E"/>
                <path d="M92.783 25.9608V33.3781H88.56V26.5313C88.56 25.1049 88.027 24.4121 86.961 24.4121C85.649 24.4121 84.87 25.3087 84.87 26.8574V33.3781H80.647V21.1925H83.599L84.173 22.4558C85.075 21.437 86.428 20.8257 88.027 20.8257C90.938 20.8257 92.783 22.8226 92.783 25.9608Z" fill="#2E6D8E"/>
                <path d="M95.325 21.1925H99.5485V33.3781H95.325V21.1925ZM95.366 16.0981H99.5075V19.6438H95.366V16.0981Z" fill="#2E6D8E"/>
                <path d="M110.454 29.7102V33.3781H107.543C104.837 33.3781 103.197 31.7479 103.197 29.0174V24.4121H100.942V23.434L106.436 17.606H107.297V21.1925H110.372V24.4121H107.42V28.2838C107.42 29.1396 107.953 29.6694 108.855 29.6694H110.454V29.7102Z" fill="#2E6D8E"/>
                <path d="M126.075 21.1925L120.95 32.8483C119.31 36.557 117.793 37.9019 114.636 37.9019H113.16V34.3155H114.308C115.907 34.3155 116.399 33.9079 116.973 32.4L111.93 21.1925H116.522L119.105 27.917L121.524 21.1925H126.075Z" fill="#2E6D8E"/>
                <path d="M125.829 29.4657H129.929V33.3781H125.829V29.4657Z" fill="#2E6D8E"/>
                <path d="M133.168 17.2392H134.234V18.5026H133.168V17.2392ZM133.332 21.1925H134.07V33.3781H133.332V21.1925Z" fill="black"/>
                <path d="M148.83 26.1645V33.3781H148.092V26.2868C148.092 23.3932 146.493 21.6408 143.869 21.6408C141.368 21.6408 139.236 23.76 139.236 26.2868V33.3781H138.498V21.1924H139.072L139.195 24.0045C140.015 22.2113 141.901 20.9479 143.992 20.9479C146.985 20.9479 148.83 23.0264 148.83 26.1645Z" fill="black"/>
                <path d="M151.987 27.2649C151.987 23.6377 154.693 20.9479 158.26 20.9479C160.966 20.9479 163.098 22.4558 163.959 24.7789H163.221C162.36 22.8226 160.515 21.6408 158.26 21.6408C155.103 21.6408 152.725 24.0453 152.725 27.2649C152.725 30.4438 155.144 32.8891 158.26 32.8891C160.474 32.8891 162.319 31.7072 163.262 29.7102H164C163.098 32.074 160.925 33.5819 158.26 33.5819C154.693 33.6226 151.987 30.8921 151.987 27.2649Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_1260_1685">
                <rect width="164" height="54" fill="white"/>
                </clipPath>
                </defs>
            </Svg>
        );    
    case 'index-logo-inc-office':
        return (
            <Svg {...dataStyles} viewBox="0 0 164 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1260_1685)">
                <path d="M13.284 49.517C13.284 40.7955 18.778 31.0958 26.568 26.9796C18.778 22.9042 13.284 13.1638 13.284 4.48302C13.284 2.93434 13.448 1.42642 13.776 0C5.412 6.15396 0 15.9758 0 26.9796C0 38.0242 5.412 47.846 13.817 53.9592C13.489 52.5736 13.284 51.0657 13.284 49.517Z" fill="#2E6D8E"/>
                <path d="M44.69 27.1834C44.69 27.5502 44.649 27.917 44.608 28.2838H35.506C35.793 29.6694 36.695 30.403 38.007 30.403C38.991 30.403 39.811 29.9547 40.221 29.2619H44.403C43.542 32.0332 41.082 33.7857 38.007 33.7857C34.194 33.7857 31.365 31.0143 31.365 27.3057C31.365 23.5562 34.194 20.8257 38.007 20.8257C41.943 20.7849 44.69 23.597 44.69 27.1834ZM35.588 25.9608H40.549C40.18 24.7381 39.278 24.086 38.007 24.086C36.777 24.086 35.916 24.7381 35.588 25.9608Z" fill="#2E6D8E"/>
                <path d="M54.653 29.7102V33.3781H51.742C49.036 33.3781 47.396 31.7479 47.396 29.0174V24.4121H45.141V23.434L50.635 17.606H51.496V21.1925H54.571V24.4121H51.619V28.2838C51.619 29.1396 52.152 29.6694 53.054 29.6694H54.653V29.7102Z" fill="#2E6D8E"/>
                <path d="M69.413 27.1834C69.413 27.5502 69.372 27.917 69.331 28.2838H60.229C60.516 29.6694 61.418 30.403 62.73 30.403C63.714 30.403 64.534 29.9547 64.944 29.2619H69.126C68.265 32.0332 65.805 33.7857 62.73 33.7857C58.917 33.7857 56.088 31.0143 56.088 27.3057C56.088 23.5562 58.917 20.8257 62.73 20.8257C66.666 20.7849 69.413 23.597 69.413 27.1834ZM60.311 25.9608H65.272C64.903 24.7381 64.001 24.086 62.73 24.086C61.5 24.086 60.639 24.7381 60.311 25.9608Z" fill="#2E6D8E"/>
                <path d="M79.0899 21.1517V24.9826H77.4497C75.9736 24.9826 75.3175 25.6347 75.3175 27.1834V33.3781H71.094V21.1925H73.9233L74.5794 22.5374C75.4405 21.5592 76.5066 21.1109 77.9418 21.1109H79.0899V21.1517Z" fill="#2E6D8E"/>
                <path d="M92.783 25.9608V33.3781H88.56V26.5313C88.56 25.1049 88.027 24.4121 86.961 24.4121C85.649 24.4121 84.87 25.3087 84.87 26.8574V33.3781H80.647V21.1925H83.599L84.173 22.4558C85.075 21.437 86.428 20.8257 88.027 20.8257C90.938 20.8257 92.783 22.8226 92.783 25.9608Z" fill="#2E6D8E"/>
                <path d="M95.325 21.1925H99.5485V33.3781H95.325V21.1925ZM95.366 16.0981H99.5075V19.6438H95.366V16.0981Z" fill="#2E6D8E"/>
                <path d="M110.454 29.7102V33.3781H107.543C104.837 33.3781 103.197 31.7479 103.197 29.0174V24.4121H100.942V23.434L106.436 17.606H107.297V21.1925H110.372V24.4121H107.42V28.2838C107.42 29.1396 107.953 29.6694 108.855 29.6694H110.454V29.7102Z" fill="#2E6D8E"/>
                <path d="M126.075 21.1925L120.95 32.8483C119.31 36.557 117.793 37.9019 114.636 37.9019H113.16V34.3155H114.308C115.907 34.3155 116.399 33.9079 116.973 32.4L111.93 21.1925H116.522L119.105 27.917L121.524 21.1925H126.075Z" fill="#2E6D8E"/>
                <path d="M125.829 29.4657H129.929V33.3781H125.829V29.4657Z" fill="#2E6D8E"/>
                <path d="M133.168 17.2392H134.234V18.5026H133.168V17.2392ZM133.332 21.1925H134.07V33.3781H133.332V21.1925Z" fill="black"/>
                <path d="M148.83 26.1645V33.3781H148.092V26.2868C148.092 23.3932 146.493 21.6408 143.869 21.6408C141.368 21.6408 139.236 23.76 139.236 26.2868V33.3781H138.498V21.1924H139.072L139.195 24.0045C140.015 22.2113 141.901 20.9479 143.992 20.9479C146.985 20.9479 148.83 23.0264 148.83 26.1645Z" fill="black"/>
                <path d="M151.987 27.2649C151.987 23.6377 154.693 20.9479 158.26 20.9479C160.966 20.9479 163.098 22.4558 163.959 24.7789H163.221C162.36 22.8226 160.515 21.6408 158.26 21.6408C155.103 21.6408 152.725 24.0453 152.725 27.2649C152.725 30.4438 155.144 32.8891 158.26 32.8891C160.474 32.8891 162.319 31.7072 163.262 29.7102H164C163.098 32.074 160.925 33.5819 158.26 33.5819C154.693 33.6226 151.987 30.8921 151.987 27.2649Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_1260_1685">
                <rect width="164" height="54" fill="white"/>
                </clipPath>
                </defs>
            </Svg>
        );    
    case 'index-logo-inc-office-yellow':
        return (
            <Svg {...dataStyles} viewBox="0 0 164 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1260_1685)">
                <path d="M13.284 49.517C13.284 40.7955 18.778 31.0958 26.568 26.9796C18.778 22.9042 13.284 13.1638 13.284 4.48302C13.284 2.93434 13.448 1.42642 13.776 0C5.412 6.15396 0 15.9758 0 26.9796C0 38.0242 5.412 47.846 13.817 53.9592C13.489 52.5736 13.284 51.0657 13.284 49.517Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M44.69 27.1834C44.69 27.5502 44.649 27.917 44.608 28.2838H35.506C35.793 29.6694 36.695 30.403 38.007 30.403C38.991 30.403 39.811 29.9547 40.221 29.2619H44.403C43.542 32.0332 41.082 33.7857 38.007 33.7857C34.194 33.7857 31.365 31.0143 31.365 27.3057C31.365 23.5562 34.194 20.8257 38.007 20.8257C41.943 20.7849 44.69 23.597 44.69 27.1834ZM35.588 25.9608H40.549C40.18 24.7381 39.278 24.086 38.007 24.086C36.777 24.086 35.916 24.7381 35.588 25.9608Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M54.653 29.7102V33.3781H51.742C49.036 33.3781 47.396 31.7479 47.396 29.0174V24.4121H45.141V23.434L50.635 17.606H51.496V21.1925H54.571V24.4121H51.619V28.2838C51.619 29.1396 52.152 29.6694 53.054 29.6694H54.653V29.7102Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M69.413 27.1834C69.413 27.5502 69.372 27.917 69.331 28.2838H60.229C60.516 29.6694 61.418 30.403 62.73 30.403C63.714 30.403 64.534 29.9547 64.944 29.2619H69.126C68.265 32.0332 65.805 33.7857 62.73 33.7857C58.917 33.7857 56.088 31.0143 56.088 27.3057C56.088 23.5562 58.917 20.8257 62.73 20.8257C66.666 20.7849 69.413 23.597 69.413 27.1834ZM60.311 25.9608H65.272C64.903 24.7381 64.001 24.086 62.73 24.086C61.5 24.086 60.639 24.7381 60.311 25.9608Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M79.0899 21.1517V24.9826H77.4497C75.9736 24.9826 75.3175 25.6347 75.3175 27.1834V33.3781H71.094V21.1925H73.9233L74.5794 22.5374C75.4405 21.5592 76.5066 21.1109 77.9418 21.1109H79.0899V21.1517Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M92.783 25.9608V33.3781H88.56V26.5313C88.56 25.1049 88.027 24.4121 86.961 24.4121C85.649 24.4121 84.87 25.3087 84.87 26.8574V33.3781H80.647V21.1925H83.599L84.173 22.4558C85.075 21.437 86.428 20.8257 88.027 20.8257C90.938 20.8257 92.783 22.8226 92.783 25.9608Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M95.325 21.1925H99.5485V33.3781H95.325V21.1925ZM95.366 16.0981H99.5075V19.6438H95.366V16.0981Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M110.454 29.7102V33.3781H107.543C104.837 33.3781 103.197 31.7479 103.197 29.0174V24.4121H100.942V23.434L106.436 17.606H107.297V21.1925H110.372V24.4121H107.42V28.2838C107.42 29.1396 107.953 29.6694 108.855 29.6694H110.454V29.7102Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M126.075 21.1925L120.95 32.8483C119.31 36.557 117.793 37.9019 114.636 37.9019H113.16V34.3155H114.308C115.907 34.3155 116.399 33.9079 116.973 32.4L111.93 21.1925H116.522L119.105 27.917L121.524 21.1925H126.075Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M125.829 29.4657H129.929V33.3781H125.829V29.4657Z" fill="rgba(250, 255, 27, 1)"/>
                <path d="M133.168 17.2392H134.234V18.5026H133.168V17.2392ZM133.332 21.1925H134.07V33.3781H133.332V21.1925Z" fill="#fff"/>
                <path d="M148.83 26.1645V33.3781H148.092V26.2868C148.092 23.3932 146.493 21.6408 143.869 21.6408C141.368 21.6408 139.236 23.76 139.236 26.2868V33.3781H138.498V21.1924H139.072L139.195 24.0045C140.015 22.2113 141.901 20.9479 143.992 20.9479C146.985 20.9479 148.83 23.0264 148.83 26.1645Z" fill="#fff"/>
                <path d="M151.987 27.2649C151.987 23.6377 154.693 20.9479 158.26 20.9479C160.966 20.9479 163.098 22.4558 163.959 24.7789H163.221C162.36 22.8226 160.515 21.6408 158.26 21.6408C155.103 21.6408 152.725 24.0453 152.725 27.2649C152.725 30.4438 155.144 32.8891 158.26 32.8891C160.474 32.8891 162.319 31.7072 163.262 29.7102H164C163.098 32.074 160.925 33.5819 158.26 33.5819C154.693 33.6226 151.987 30.8921 151.987 27.2649Z" fill="#fff"/>
                </g>
                <defs>
                <clipPath id="clip0_1260_1685">
                <rect width="164" height="54" fill="white"/>
                </clipPath>
                </defs>
            </Svg>
        );    
    case 'index-logo-leaders':
        return (
            <Svg {...dataStyles} viewBox="0 0 292 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1307_1865)">
                <path d="M23.616 88.0302C23.616 72.5253 33.3831 55.2815 47.232 47.9638C33.3831 40.7185 23.616 23.4023 23.616 7.96981C23.616 5.2166 23.9076 2.53585 24.4907 0C9.62133 10.9404 0 28.4015 0 47.9638C0 67.5985 9.62133 85.0596 24.5636 95.9275C23.9804 93.4641 23.616 90.7834 23.616 88.0302Z" fill="#2E6D8E"/>
                <path d="M79.4489 48.326C79.4489 48.9781 79.376 49.6302 79.3031 50.2823H63.1218C63.632 52.7457 65.2356 54.0498 67.568 54.0498C69.3173 54.0498 70.7751 53.2528 71.504 52.0211H78.9387C77.408 56.9479 73.0347 60.0634 67.568 60.0634C60.7893 60.0634 55.76 55.1366 55.76 48.5434C55.76 41.8777 60.7893 37.0234 67.568 37.0234C74.5653 36.9509 79.4489 41.9502 79.4489 48.326ZM63.2676 46.1525H72.0871C71.4311 43.9789 69.8276 42.8196 67.568 42.8196C65.3813 42.8196 63.8507 43.9789 63.2676 46.1525Z" fill="#2E6D8E"/>
                <path d="M97.1609 52.8181V59.3389H91.9858C87.1751 59.3389 84.2596 56.4408 84.2596 51.5864V43.3992H80.2507V41.6604L90.0178 31.2996H91.5484V37.6755H97.0151V43.3992H91.7671V50.2823C91.7671 51.8038 92.7147 52.7457 94.3182 52.7457H97.1609V52.8181Z" fill="#2E6D8E"/>
                <path d="M123.401 48.326C123.401 48.9781 123.328 49.6302 123.255 50.2823H107.074C107.584 52.7457 109.188 54.0498 111.52 54.0498C113.269 54.0498 114.727 53.2528 115.456 52.0211H122.891C121.36 56.9479 116.987 60.0634 111.52 60.0634C104.741 60.0634 99.712 55.1366 99.712 48.5434C99.712 41.8777 104.741 37.0234 111.52 37.0234C118.517 36.9509 123.401 41.9502 123.401 48.326ZM107.22 46.1525H116.039C115.383 43.9789 113.78 42.8196 111.52 42.8196C109.333 42.8196 107.803 43.9789 107.22 46.1525Z" fill="#2E6D8E"/>
                <path d="M140.604 37.603V44.4136H137.688C135.064 44.4136 133.898 45.5728 133.898 48.326V59.3389H126.389V37.6755H131.419L132.586 40.0664C134.116 38.3275 136.012 37.5306 138.563 37.5306H140.604V37.603Z" fill="#2E6D8E"/>
                <path d="M164.948 46.1525V59.3389H157.44V47.1668C157.44 44.6309 156.492 43.3992 154.597 43.3992C152.265 43.3992 150.88 44.9932 150.88 47.7464V59.3389H143.372V37.6755H148.62L149.641 39.9215C151.244 38.1102 153.65 37.0234 156.492 37.0234C161.668 37.0234 164.948 40.5736 164.948 46.1525Z" fill="#2E6D8E"/>
                <path d="M169.467 37.6755H176.975V59.3389H169.467V37.6755ZM169.54 28.6189H176.902V34.9223H169.54V28.6189Z" fill="#2E6D8E"/>
                <path d="M196.363 52.8181V59.3389H191.188C186.377 59.3389 183.461 56.4408 183.461 51.5864V43.3992H179.452V41.6604L189.22 31.2996H190.75V37.6755H196.217V43.3992H190.969V50.2823C190.969 51.8038 191.916 52.7457 193.52 52.7457H196.363V52.8181Z" fill="#2E6D8E"/>
                <path d="M224.133 37.6755L215.022 58.397C212.107 64.9902 209.41 67.3811 203.797 67.3811H201.173V61.0053H203.214C206.057 61.0053 206.932 60.2808 207.952 57.6L198.987 37.6755H207.15L211.742 49.6302L216.043 37.6755H224.133Z" fill="#2E6D8E"/>
                <path d="M223.696 52.3834H230.986V59.3389H223.696V52.3834Z" fill="#2E6D8E"/>
                <path d="M236.743 30.6475H238.638V32.8936H236.743V30.6475ZM237.035 37.6755H238.347V59.3389H237.035V37.6755Z" fill="black"/>
                <path d="M264.587 46.5147V59.3389H263.275V46.7321C263.275 41.5879 260.432 38.4724 255.767 38.4724C251.321 38.4724 247.531 42.24 247.531 46.7321V59.3389H246.219V37.6755H247.239L247.458 42.6747C248.916 39.4868 252.268 37.2408 255.986 37.2408C261.307 37.2408 264.587 40.9358 264.587 46.5147Z" fill="black"/>
                <path d="M270.199 48.4709C270.199 42.0226 275.01 37.2408 281.351 37.2408C286.162 37.2408 289.952 39.9215 291.483 44.0513H290.171C288.64 40.5736 285.36 38.4724 281.351 38.4724C275.739 38.4724 271.511 42.7472 271.511 48.4709C271.511 54.1223 275.812 58.4694 281.351 58.4694C285.287 58.4694 288.567 56.3683 290.244 52.8181H291.556C289.952 57.0204 286.089 59.7011 281.351 59.7011C275.01 59.7736 270.199 54.9192 270.199 48.4709Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_1307_1865">
                <rect width="291.556" height="96" fill="white"/>
                </clipPath>
                </defs>
            </Svg>
        );
    default:
        return null;
    }
};

export default SvgSpriteLogo;