// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN || null,
    beforeSendTransaction(event) {
        // Modify or drop the event here
        if (process.env.NEXT_PUBLIC_NO_FOLLOW){
            return null
        }
        for (const key in event.measurements) {
            if (event.measurements[key]?.value > 2000){
                return event;
            }
        }
        return null;
    },
    // Adjust this value in production, or use tracesSampler for greater control
    ignoreErrors: [
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        'TypeError: Cancelled',
        'Large Render Blocking Asset',
        'ResizeObserver loop limit exceeded',
        'Load failed',
        "Cannot read properties of undefined (reading 'init')",
        "undefined is not an object (evaluating 'window.fcWidget.init')"
    ],
    tracesSampleRate: 1.0
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});