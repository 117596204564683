import React from 'react';
import {
    Div, P, svgSprite
} from '../../hybrid/wrappers';
import { useWindowDimensions } from '../../common/utils/windowDimensions';

const SearchWindowHead = ({
    styles, closeModal, title, customStyles
}) => {
    const widthDimensions = useWindowDimensions();

    return (
        <Div styles={{ ...styles.searchWindowTitleBlock, ...customStyles?.searchWindowTitleBlock }}>
            {
                widthDimensions <= 768 &&
                <Div
                    data-uitest='677_uitest' onClick={() => closeModal()}
                    styles={styles.formCursorPointer}
                >
                    {svgSprite('arrow-left', {
                        fill: styles.variable.whiteColor,
                        width: '16px',
                        height: '16px'
                    })}
                </Div>
            }

            <Div>
                <P styles={styles.searchWindowTitle}>{title}</P>
            </Div>
            {
                widthDimensions > 768 ?
                    <Div
                        data-uitest='678_uitest' onClick={() => closeModal()}
                        styles={styles.searchWindowTitleClose}
                    >
                        <P>
                            {svgSprite('cross', {
                                style: { ...styles.searchWindowTitleSvg, ...styles.formCursorPointer },
                                fill: styles.variable.whiteColor
                            })}
                        </P>
                    </Div> : <Div/>
            }

        </Div>
    )
};
export default SearchWindowHead;