import React from 'react';
import moment from 'moment'
import UserContext from '../utils/getContext';
import {
    getLangs, getCurrencies,
    getUserRoles, getIsSponsor, getSettings, clearUserCache, getMenu, getAllLangs
} from '../utils/memo';
import {
    removeURLParameter, rolesMenu, SITE_URL, setLanguageForEmail
} from '../utils/utils';
import {
    historyStore, getData, getTokenByIdAndHash
} from '../utils/router';
import { Uploader } from '../components/T';
import { zendoStorage } from '../../hybrid/wrappers';
import SeoData from '../../widgets/seoData/SeoData';
import Alert from '../../widgets/alert/Alert';
import CookieModal from '../../widgets/cookieModal/CookieModal';
import Router from 'next/router';
import Language from './Language';
import Tooltips from './Tooltips';
const langs = require('../../scripts/configs/langs');

const UserContextComponent = (OriginalComponent) => class WrapperComponent extends React.Component{
    constructor(params){
        super(params);

        this.localStorageChangeHandler = this.localStorageChangeHandler.bind(this);
        this.setPrevScene = this.setPrevScene.bind(this);
        this.activeScene = { url: 'Home', params: false };

        this.changeData = this.changeData.bind(this);
        this.changeAlertSetter = this.changeAlertSetter.bind(this);
        this.onRouteChangeStart = this.onRouteChangeStart.bind(this);
        this.onRouteChangeDone = this.onRouteChangeDone.bind(this);
        this.newToken = this.newToken.bind(this);
        this.projectTime = this.projectTime.bind(this);

        const props = params?.pageProps;

        this.state = props?.file ? {} : {
            activeLangs: props?.activeLangs,
            allLangs: props?.allLangs,
            settings: props?.settings,
            seo: props?.seo,
            currencies: props?.currencies,
            captcha: props?.captcha,
            menu: props?.menu,
            roles: [],
            lang: props?.lang || params?.router?.query?.lang || '',
            offset: props?.offset,
            userToken: zendoStorage.get('userToken') || false,
            refer: false,
            setAlert: () => true,
            routerLoader: false,
            notFound: params?.router?.asPath !== '/' && !params?.router?.query?.lang,
            localization: zendoStorage.get('localization') ? JSON.parse(zendoStorage.get('localization')) : {}
        };
    }

    localStorageChangeHandler(e){
        if (!e.key){
            this.setState(state => (
                {
                    ...state,
                    userToken: zendoStorage.get('userToken') ? zendoStorage.get('userToken') : false,
                    refer: zendoStorage.get('refer') || false,
                    localization: zendoStorage.get('localization') || {}
                }
            ))
        } else if (['userToken', 'refer', 'localization'].includes(e.key)){
            this.setState(state => (
                {
                    ...state,
                    [e.key]: !!e.newValue ? e.newValue : e.key === 'localization' ? {} : false
                }
            ))
        }
    }

    setPrevScene(scene, params){
        const oldScene = this.activeScene;
        if (this.activeScene.url !== scene || this.activeScene.params !== params) {
            this.activeScene = { url: scene, params: params };

            if (this.activeScene.url !== '/logout' && (this.state.prevUrl?.url !== oldScene.url || this.prevUrl?.params !== oldScene.params)) zendoStorage.set('prevUrlData', JSON.stringify(oldScene));
        }
    };
    async newToken (userToken, session) {
        clearUserCache();
        if (userToken){
            zendoStorage.set('userToken', userToken, session);
        } else {
            zendoStorage.remove('userToken');
        }
        const roles = userToken ? await getUserRoles(userToken) : [];
        this.setState({ userToken, roles });
    };
    async componentDidMount(){
        if (this.props.pageProps?.file || this.props.pageProps?.redirect) return;
        let {
            activeLangs, allLangs, currencies, settings, seo, offset, roles, localization, lang, userToken, menu
        } = this.state;

        const localizationCopy = { ...localization }
        const params = this.getDomenData(window?.location?.href)
        const refer = params?.ref || zendoStorage.get('refer');

        if (refer && lang){
            try {
                const dataValidReferalReq = await getIsSponsor(userToken, refer);

                if (!dataValidReferalReq || dataValidReferalReq?.errors){
                    if (params?.ref){
                        return document.location.assign(`${SITE_URL}/${lang}/error/refer`);
                    } else {
                        this.setState({ refer: false });
                        zendoStorage.remove('refer');
                    }
                } else {
                    this.setState({ refer });
                    zendoStorage.set('refer', refer)
                    if (params?.ref){
                        const domain = SITE_URL.replace('http://', '').replace('https://', '');
                        const redirectUrl = removeURLParameter(window.location.href.replace('http://', '').replace('https://', '')
                            .replace(domain, ''), 'ref');

                        return document.location.assign(`${SITE_URL}${redirectUrl}`);
                    }
                }
            } catch {
                if (params?.ref){
                    return document.location.assign(`${SITE_URL}/${lang}/error/refer`);
                } else {
                    this.setState({ refer: false });
                    zendoStorage.remove('refer')
                }
            }
        } else if (params?.id && params?.hash && lang){
            try {
                const dataValidHash = await getTokenByIdAndHash(params?.id, params?.hash)
                if (!dataValidHash){
                    return document.location.assign(`${SITE_URL}/${lang}/error/403`);
                } else {
                    await this.newToken(dataValidHash)
                    if (params?.id && params?.hash){
                        return document.location.assign(`${SITE_URL}/${lang}/office`);
                    }
                }
            } catch {
                return document.location.assign(`${SITE_URL}/${lang}/error/403`);
            }
        }

        window.addEventListener('storage', this.localStorageChangeHandler);
        historyStore('on');

        Router.router.events.on('routeChangeStart', this.onRouteChangeStart);
        Router.router.events.on('routeChangeComplete', this.onRouteChangeDone);
        Router.router.events.on('routeChangeError', this.onRouteChangeDone);
        if (!allLangs) {
            allLangs = await getAllLangs();
            this.setState({ allLangs })
        }

        if (!activeLangs){
            activeLangs = await getLangs();
            this.setState({ activeLangs })
        }

        let isActiveLang, sliceLenght, actualLang;
        if (!lang){
            sliceLenght = this.props.router.asPath.length > 3 ? 4 : 3
            actualLang = this.props.router.asPath.slice(0, sliceLenght);
            isActiveLang = activeLangs?.find((l) => `/${l.alias}${sliceLenght === 3 ? '' : '/'}` === actualLang);
            if (isActiveLang){
                lang = isActiveLang.alias;
                this.setState({ notFound: false, lang });
            }
        }

        if ((!currencies || userToken) && lang){
            currencies = await getCurrencies(lang, userToken);
            this.setState({ currencies })
        }
        if (!localizationCopy.country?.id) {
            let localizationData = await getData('/api/v1/geonames/country-by-ip', userToken, lang) || {};
            localizationCopy.country = { id: localizationData?.data?.geoname_id, code: localizationData?.data?.country };
            const localCurrency = currencies?.data?.find(el => el.code === localizationData?.data?.currency);
            const localLang = activeLangs?.find(el => el.alias === localizationData?.data?.language);

            if (localCurrency && !localizationCopy.currency){
                localizationCopy.currency = localCurrency.code;
            } else if (localizationCopy.currency){
                const isActiveCurrency = currencies?.data?.find(el => el.code === localizationCopy.currency);
                if (!isActiveCurrency){
                    if (localCurrency){
                        localizationCopy.currency = localCurrency.code;
                    } else {
                        localizationCopy.currency = false;
                    }
                }
            }
            if (localLang && !localizationCopy.language && (!lang || this.props.router.asPath === '/')) {
                localizationCopy.language = localLang.alias;
            }
        }

        if (!localizationCopy.currency) {
            localizationCopy.currency = currencies?.data?.find(el => el.main === true)?.code || 'USD'
        } else {
            const isActiveCurrency = currencies?.data?.find(el => el.code === localizationCopy.currency);
            if (!isActiveCurrency){
                localizationCopy.currency = currencies?.data?.find(el => el.main === true)?.code || 'USD'
            }
        }

        if (!lang && !isActiveLang || this.props.router.asPath === '/'){
            const prevLang = activeLangs?.find((l) => localizationCopy.language === l.alias);
            const defaultLang = activeLangs?.find((l) => l.isDefault);

            lang = prevLang?.alias || defaultLang?.alias || 'ru';

            const isLang = langs?.find((l) => `/${l}${sliceLenght === 3 ? '' : '/'}` === actualLang);
            if (isLang){
                const actualPath = this.props.router.asPath.slice(sliceLenght);
                return document.location.assign(`${SITE_URL}/${lang}/${actualPath}`);
            } else if (this.props.router.asPath !== '/') {
                return document.location.assign(`${SITE_URL}/${lang}${this.props.router.asPath}`);
            }
        }
        localizationCopy.language = lang;

        this.setState({ localization: localizationCopy })

        if (!settings){
            settings = await getSettings('display');
            this.setState({ settings })
        }
        if (!this.state.seo){
            seo = await getSettings('seo');
            this.setState({ seo })
        }
        if (!offset){
            const timeRes = await getData('/api/v1/settings/date', false, lang);
            const time = moment(timeRes?.data?.date, 'YYYY-MM-DD HH:mm:ss');
            offset = time.diff(moment(new Date()));
            this.setState({ offset })
        }

        if (userToken && !roles.length){
            roles = await getUserRoles(userToken, lang);
            this.setState({ roles })
        }

        if (!menu){
            let newMenu = {};

            let site = await getMenu('site')
            newMenu = { ...newMenu, ...site }
            this.setState({ menu: newMenu })


            let footer = await getMenu('footer')
            newMenu = { ...newMenu, ...footer }
            this.setState({ menu: newMenu })

            let office = await getMenu('office')
            newMenu = { ...newMenu, ...office }
            this.setState({ menu: newMenu })

            let admin = await getMenu('admin')
            newMenu = { ...newMenu, ...admin }
            this.setState({ menu: newMenu })
        }
    }

    componentWillUnmount(){
        window.removeEventListener('storage', this.localStorageChangeHandler);
        historyStore('off');
        Router.router.events.off('routeChangeStart', this.onRouteChangeStart);
        Router.router.events.off('routeChangeComplete', this.onRouteChangeDone);
        Router.router.events.off('routeChangeError', this.onRouteChangeDone);
    }

    changeAlertSetter (handler) { this.setState({ setAlert: handler }) };
    onRouteChangeStart () { this.setState({ routerLoader: true }) };
    onRouteChangeDone () { this.setState({ routerLoader: false }) };
    projectTime () {
        return moment().add(this.state.offset)
    }
    getDomenData (href) {
        try {
            if (!href) return false;
            const url = new URL(href);
            const searchParams = new URLSearchParams(url.search);
            return {
                ref: searchParams.get('ref'),
                id: searchParams.get('id'),
                hash: searchParams.get('hash')
            };
        } catch (e) {
            return false
        }
    }
    async changeData (key, data) {
        this.setState({ [key]: data });
        if (key === 'lang'){
            const oldLang = this.state.lang;
            const newLang = data;
            if (oldLang !== newLang){
                const route = this.props.router.asPath === '/' ? '/[lang]' : this.props.router.route;
                const asPath = `/${newLang}${this.props.router.asPath.replace(`/${oldLang}${this.props.router.asPath === `/${oldLang}` ? '' : '/'}`, '/')}`

                this.props.router.push(route, asPath);
            }
            if (this.state.userToken){
                setLanguageForEmail(this.state.userToken, newLang)
            }
        } else if (key === 'localization') {
            zendoStorage.set('localization', JSON.stringify(data));
        }
    }

    render () {
        const params = this.getDomenData(`${SITE_URL}${this.props.router.asPath}`) || {}
        if (params?.ref || params?.id && params?.hash || this.state.notFound) return null;

        const {
            setAlert, seo, activeLangs, menu, roles, allLangs
        } = this.state;
        const { translates } = this.props.pageProps || {};

        const global = {
            ...this.state,
            activeLangs: activeLangs || [],
            allLangs: allLangs || [],
            menu: rolesMenu(menu, roles) || {},
            time: this.projectTime,
            setUserToken: this.newToken,
            setAlertData: setAlert,
            setState: this.changeData
        };
        const currPageName = this.props.router.asPath.substring(this.props.router.asPath.lastIndexOf('/') + 1)
        const defaultSeo = seo?.find(s => s.alias === currPageName) || seo?.find((s) => s.alias === 'default') || null;

        return (
            <UserContext.Provider value={global}>
                <Language page='all' translates={translates}>
                    <Tooltips>
                        <Uploader/>
                        <Alert changeAlertSetter={this.changeAlertSetter}/>
                        <SeoData lang={this.state.lang} data={defaultSeo}/>
                        <OriginalComponent {...this.props}/>
                        <CookieModal/>
                    </Tooltips>
                </Language>
            </UserContext.Provider>
        );
    }
};
export default UserContextComponent;