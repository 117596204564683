import { media } from "../../../../common/utils/utils";
import uikit from "../../../uikit/styles";

const stylesheets = (width, height, variable = {}) => ({
    userNameBlock: media(width, height, 'w', {
        768: {
            display: 'inline-flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            height: '30px',
            'align-items': 'center',
            ...uikit.text,
            color: variable.blackColor,
            'font-weight': 500,
        },
        0: {
            display: 'inline-flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            height: '22px',
            'align-items': 'center',
            ...uikit.text,
            color: variable.blackColor,
            'font-weight': 500
        }
    }),
    searchWindowTitleBlock: media(width, height, 'w', {
        992: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'position': 'relative',
            'align-items': 'flex-start',
            padding: '36px 0 36px 64px',
            background: variable.blueColor,
            'border-radius': '20px 0px 0px 0px'
        },
        768: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'position': 'relative',
            'align-items': 'flex-start',
            padding: '24px 0 24px 36px',
            background: variable.blueColor,
            'border-radius': '20px 0px 0px 0px'
        },
        0: {
            'background-color': variable.blueColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '10px',
            'margin-bottom': '12px',
            'box-shadow': `0px 2px 15px ${variable.blackExtraTransparentColor}`,
            'border-radius': '0px 0px 20px 20px'
        }
    }),
    searchWindowTitle: media(width, height, 'w', {
        992: {
            color: variable.whiteColor,
            ...uikit.title,
            'text-align': 'center',
            'font-weight': 600
        },
        0: {
            color: variable.whiteColor,
            ...uikit.title,
            'font-weight': 600
        }
    }),
    searchWindowTitleSvg: {
        fill: variable.whiteColor,
        width: '12px',
        height: '12px'
    },
});
export default stylesheets;