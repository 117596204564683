// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { Div, svgSprite } from '../../../hybrid/wrappers';
import { admin } from '../../../project/uikit/color-variables';
import { t } from '../../../common/components/T';
import { UserProfileContext } from '../../../common/utils/getContext';

export const transformVolumes = (total_personal_volume,
    total_group_volume,
    total_qualification_volume,
    trans) => [
    {
        alias: 'coin-plus',
        volume: total_personal_volume || '0 PV',
        title: t(
            'transformVolumesoneCoin', 'Личный объем', 'widget', trans, 'userModal'
        )

    },
    {
        alias: 'coins',
        volume: total_group_volume || '0 PV',
        title: t(
            'transformVolumestwoCoin', 'Групповой объем', 'widget', trans, 'userModal'
        )
    },
    {
        alias: 'coins-plus',
        volume: total_qualification_volume || '0 PV',
        title: t(
            'transformVolumestwoCoinQual', 'Квалификационный объем', 'widget', trans, 'userModal'
        )
    }
]
const UserVolumes = ({
    styles, data, trans
}) => {
    const { officePage } = React.useContext(UserProfileContext);
    const volumes = transformVolumes(data?.marketing_profile?.total_personal_volume?.formatted_value,
        data?.marketing_profile?.total_group_volume?.formatted_value,
        data?.marketing_profile?.total_qualification_volume?.formatted_value,
        trans)
    return (
        volumes.map((volumeItem, idx) =>
            <Div styles={styles.userModalVolumeItem} key={`userInfo${idx}`}>
                <Div styles={{ ...styles.userModalInfoIcon, 'background-color': admin[`officeVolumesColorTransparent_${idx}`] }}>
                    {
                        svgSprite(volumeItem.alias, {
                            fill: officePage ? styles.variable.blackColor : admin[`officeVolumesColor_${idx}`],
                            width: '20px',
                            height: '20px'
                        })
                    }
                </Div>
                <Div>
                    <Div styles={styles.userModalVolumeValue}>{volumeItem.volume}</Div>
                    <Div styles={styles.userModalVolumeTitle}>{volumeItem.title}</Div>
                </Div>
            </Div>)
    )
};
UserVolumes.propTypes = {
    styles: PropTypes.object,
    volumes: PropTypes.array
};
export default UserVolumes;