import { media } from "../../../../common/utils/utils";
import uikit from "../../../uikit/styles";

const stylesheets = (width, height, variable = {}) => {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
    })(!window['safari'] || typeof safari !== 'undefined');
    return {
        formControlSearchIconSvg: {
            fill: variable.blackColor,
            width: '18px',
            height: '18px'
        },
        formButtonPrimary: media(width, height, 'w', {
            768: {
                ...uikit.button,
                color: variable.blackColor,
                'background-color': variable.yellowColor,
                'border-radius': '32px',
                transition: 'background-color .4s'
            },
            0: {
                ...uikit.button,
                color: variable.blackColor,
                'background-color': variable.yellowColor,
                'height': '60px',
                'border-radius': '32px'
            }
        }),
        formButtonPrimaryHover: { 'background-color': variable.greyColor3 },
        formButtonPrimaryFocus: { 'background-color': variable.greyColor4 },
        formControlRequiredMark: {
            color: variable.errorColor,
            'padding-left': '4px',
            ...uikit.small
        },
        input: media(width, height, 'w', {
            768: {
                ...uikit.text,
                'border': 'none',
                'width': '100%',
                'font-weight': 500
            },
            0: {
                ...isSafari ? {
                    'font-size': '16px',
                    'line-height': '1.5rem',
                    color: 'black'
                } : uikit.text,
                'border': 'none',
                'width': '100%',
                'font-weight': 500
            }
        }),
        formRadioSwitcherTogglerElement: media(width, height, 'w', {
            768: {
                'border-radius': '18px',
                'text-align': 'center',
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '4px 14px',
                'cursor': 'pointer',
                'text-transform': 'uppercase'
            },
            0: {
                'border-radius': '18px',
                'text-align': 'center',
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '4px 14px',
                'text-transform': 'uppercase'
            }
        }),
        formRadioSwitcherTogglerText: {
            ...uikit.small,
            'font-weight': '600',
            'line-height': '1.5',
            'text-align': 'center',
            'flex-shrink': 0,
            position: 'relative',
            'z-index': '1',
            color: variable.darkColor
        },
        modalContentWrapper: media(width, height, 'w', {
            768: {
                background: variable.whiteColor,
                padding: '32px',
                position: 'relative',
                width: '576px',
                'max-width': '100%'
            },
            0: {
                display: 'flex',
                'flex-direction': 'column',
                'align-items': 'center',
                background: variable.whiteColor,
                padding: '24px 12px 34px',
                position: 'relative',
                width: '576px',
                'max-width': '100%',
                'padding-left': '16px',
                'padding-right': '16px'
            }
        }),
        formModalSubtitle: media(width, height, 'w', {
            768: {
                ...uikit.title,
                color: variable.blackColor,
                'font-weight': '600',
                'padding-left': '20px',
                'padding-right': '20px',
                'margin-bottom': '32px',
                'text-align': 'center'
            },
            0: {
                ...uikit.title,
                color: variable.blackColor,
                'font-weight': '600',
                'margin-bottom': '20px',
                'padding-left': '35px',
                'padding-right': '35px',
                'text-align': 'center'
            }
        }),
        formModalInfoBlockModal: media(width, height, 'w', {
            768: {
                background: variable.greyColor3,
                'border-radius': '8px',
                padding: '16px',
                'margin-bottom': '24px'
            },
            0: {
                background: variable.greyColor3,
                'border-radius': '8px',
                padding: '16px',
                'margin-bottom': '8px'
            }
        }),
        formModalPrevValueText: media(width, height, 'w', {
            768: {
                'font-weight': 'normal',
                ...uikit.subtitle,
                'margin-right': '6px',
                color: variable.blackColor,
                'font-weight': 500
            },
            0: {
                'font-weight': 'normal',
                ...uikit.subtitle,
                color: variable.blackColor,
                'font-weight': 500
            }
        }),
        formCheckboxSwitcher: {
            width: '40px',
            height: '24px',
            'margin-right': '14px',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'border-radius': '20px',
            'background-color': variable.greyColor,
            position: 'relative'
        },
        formCheckboxSwitcherActive: {
            'border-color': variable.blueColor,
            'background-color': variable.greenColor
        },
        formCheckbox: {
            width: '20px',
            height: '20px',
            'margin-right': '10px',
            'border-style': 'solid',
            'border-width': '2px',
            'border-color': variable.greyColor,
            'background-color': variable.whiteColor,
            'border-radius': '6px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'flex-shrink': '0'
        },
        formRadioCircleWrap: {
            border: `2px solid ${variable.greyColor}`,
            width: '20px',
            'min-width': '20px',
            height: '20px',
            'margin-right': '10px',
            'border-radius': '20px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        },
        buttonGray: media(width, height, 'w', {
            768: {
                ...uikit.button,
                'background-color': variable.whiteColor,
                color: variable.blackColor,
                border: `1px solid ${variable.greyColor}`,
                'border-radius': '32px',
                transition: 'background-color .4s'
            },
            0: {
                ...uikit.button,
                'background-color': variable.whiteColor,
                color: variable.blackColor,
                'height': '60px',
                border: `1px solid ${variable.greyColor}`,
                'border-radius': '32px',
                transition: 'background-color .4s'
            }
        }),
        buttonGrayHover: {
            'background-color': variable.greyColor3,
            'border-color': 'transparent'
        },
        buttonGrayFocus: {
            'background-color': variable.greyColor4,
            'border-color': 'transparent'
        }
    }
};
export default stylesheets;