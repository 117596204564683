// @generated: @expo/next-adapter@2.1.5
import React, {
    useMemo, useState, useRef, useEffect, useCallback
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
    Div, Img, svgSprite
} from '../../../hybrid/wrappers';
import { rankColors } from '../../../project/uikit/color-variables';

import T, { t } from '../../../common/components/T';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';

const Rang = ({
    getHandlers,
    gridStyles,
    val,
    name,
    styles,
    align,
    gridName,
    refTable,
    refTableChild,
    idx,
    thead,
    translation
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const modal = useRef(false);
    const modalDropdown = useRef(false);
    const modalInner = useRef(false);
    const rankColor = useMemo(() => {
        if (typeof val === 'string') return {}
        if (val?.preview?.url || val?.preview?.links?.preview) {
            return rankColors[val.sort] || rankColors[rankColors.length - 1]
        }
        return rankColors[val?.sort ? val.sort : 0]
    }, [val]);

    const widthDimensions = useWindowDimensions();
    const isDesktop = widthDimensions > 1024;
    const leftSide = widthDimensions < 768 && idx === thead?.length - 1;

    const func = useCallback(() => {
        if (modal?.current) {
            const width = modalInner?.current?.getBoundingClientRect()?.width;
            const height = modalInner?.current?.getBoundingClientRect()?.height;
            const top = modalDropdown?.current?.getBoundingClientRect()?.top - refTable?.current.getBoundingClientRect()?.top;
            const left = modalDropdown?.current?.getBoundingClientRect()?.left - refTable?.current.getBoundingClientRect()?.left;
            modal.current.style.height = `${height}px`;
            modal.current.style.top = `${top}px`;
            modal.current.style.left = `${left - (leftSide ? width - 50 : 0)}px`;
            modal.current.style.width = `${width}px`;
        }
    }, [leftSide, refTable])

    useEffect(() => {
        const refTableChildVar = refTableChild?.current;
        func();
        if (refTableChildVar) {
            refTableChildVar.addEventListener('scroll', func)
        }

        return () => {
            refTableChildVar?.removeEventListener('scroll', func)
        }
    }, [func, refTableChild])

    useEffect(() => {
        func()
    }, [isOpen, setIsOpen, func])

    return (
        <Div>
            {val?.alter_name && typeof val.alter_name === 'string' ? val.alter_name : val?.alter_name ? (
                <Div styles={{display: 'flex', 'align-items': 'center'}}>{Array.from(Array(val?.alter_name).keys()).map(item => {
                    return (
                        <Div
                            key={item}
                            styles={{
                                display: 'flex',
                                height: '16px',
                                margin: '0 2px'
                            }}
                        >
                            {svgSprite('eternity-rank-star', {
                                width: '16px',
                                height: '16px',
                            })}
                        </Div>
                    )
                })}</Div>
            ) : (
                <Div
                styles={{ ...styles.rangSvgBox }}
                childrenRef={modalDropdown}
            >
                {
                    val?.name ? val.name : <Div><T textName='personal_WithoutRank-new' defaultStr='Member' page='all'/></Div>
                // val?.sort ?
                //     svgSprite(`rank${val?.sort}`, gridStyles?.[name]?.ico?.styles || {
                //             height: '28px',
                //             width: '28px'
                //     })
                //     :
                //     <Div styles={{
                //         background: '#48535F',
                //         'text-align': 'center',
                //         'border-radius': '16px',
                //         display: 'flex',
                //         'align-items': 'center',
                //         'justify-content': 'center',
                //         padding: '4px 12px',
                //         color: 'white'
                //     }}>
                //         <T textName='personal_WithoutRank-new' defaultStr='Member' page='all'/>
                //     </Div>
                }
            </Div>
            )}
        </Div>
    )
};

Rang.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string
};

export default Rang;