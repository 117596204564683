import uikit from './styles'

const extend = (inner, oldName, newName) => {
    return Object.keys(inner).reduce((acc, curr) => ({
        ...acc,
        [curr.replace(oldName, newName)]: inner[curr]
    }), {})
}

export const separated_title = (variable) => ({
    formTitle__separated_title: {
        ...uikit.text,
        'line-height': '1.25',
        color: variable.darkColor,
        'margin-bottom': '8px',
        'display': 'flex',
        'align-items': 'center'
    },
    formSelectDataWrap__separated_title: {
        display: 'flex',
        'align-items': 'flex-end',
        'justify-content': 'space-between'
    },
    formControlError__separated_title: {
        'border-width': '2px',
        padding: '11px 15px',
        'border-color': variable.redColor,
        'background': variable.redExtraLightColor,
        color: variable.darkColor
    },
    formControl__separated_title: {
        'flex-grow': '1',
        ...uikit.text,
        background: variable.whiteColor,
        width: '100%',
        padding: '12px 16px',
        'border-width': '1px',
        'border-style': 'solid',
        'border-color': variable.greyExtraDarkHalfTransparentColor,
        'border-radius': '12px',
        'box-shadow': 'none',
        color: variable.darkColor
    },
    formControlFocus__separated_title: {
        'border-width': '2px',
        'border-color': variable.blueColor,
        padding: '11px 15px',
        color: variable.darkColor
    }
})
export const separated_subtitle = (variable) => ({
    ...extend(separated_title(variable), 'separated_title', 'separated_subtitle'),
    formTitle__separated_subtitle: {
        ...uikit.subtitle,
        color: variable.darkColor,
        'font-weight': 500,
        'margin-bottom': '8px',
        'display': 'flex',
        'align-items': 'center'
    }
})
export const no_title = (variable) => ({
    formSelectDataWrap__no_title: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between'
    },
    formControlError__no_title: {
        'border-width': '2px',
        padding: '11px 15px',
        'border-color': variable.redColor,
        'background': variable.redExtraLightColor,
        color: variable.darkColor
    },
    formControl__no_title: {
        'flex-grow': '1',
        ...uikit.text,
        background: variable.whiteColor,
        width: '100%',
        padding: '12px 16px',
        'border-width': '1px',
        'border-style': 'solid',
        'border-color': variable.greyExtraDarkHalfTransparentColor,
        'border-radius': '12px',
        'box-shadow': 'none',
        color: variable.darkColor
    },
    formControlFocus__no_title: {
        'border-width': '2px',
        'border-color': variable.blueColor,
        padding: '11px 15px',
        color: variable.darkColor
    }
})
export const couple_title = (variable) => ({
    formControl__couple_title: {
        'flex-grow': '1',
        background: variable.whiteColor,
        width: '100%',
        padding: '8px 16px',
        'border-width': '1px',
        'border-style': 'solid',
        'border-color': variable.greyColor,
        'border-radius': '12px',
        color: variable.blackColor
    },
    formControlFocus__couple_title: {
        'border-width': '2px',
        'border-color': variable.blueColor,
        padding: '7px 15px',
        color: variable.blackColor
    },

    formTitle__couple_title: {
        'margin-bottom': '6px',
        ...uikit.small,
        color: variable.blackColor,
        'display': 'flex',
        'align-items': 'center'
    },
    formControlError__couple_title: {
        'border-width': '2px',
        padding: '7px 15px',
        'border-color': variable.redColor,
        'background': variable.redExtraLightColor,
        color: variable.blackColor
    },
    formSelectDataWrap__couple_title: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between'
    }
})
export const small = (variable) => {
    const defaults = extend(separated_title(variable), 'separated_title', 'small');
    return {
        ...defaults,
        formControlError__small: {
            ...defaults.formControlError__small,
            padding: '6px 15px'
        },
        formControl__small: {
            ...defaults.formControl__small,
            padding: '7px 16px'
        },
        formControlFocus__small: {
            ...defaults.formControlFocus__small,
            padding: '6px 15px'
        }
    }
}
export const clean = (variable) => {
    const inputStyles = {
        'flex-grow': '1',
        ...uikit.text,
        'background': 'transparent',
        width: '100%',
        padding: '0',
        'border': 'none',
        'border-radius': '0',
        'box-shadow': 'none',
        color: variable.darkColor
    }
    return {
        formControlError__clean: {
            'border': 'none',
            padding: '0',
            'background': 'transparent',
            color: variable.darkColor
        },
        formControl__clean: inputStyles,
        formControlFocus__clean: inputStyles
    }
}