// @generated: @expo/next-adapter@2.1.5
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Social from './SocialWeb';
import {
    Div, P, svgSprite, Img
} from '../../../hybrid/wrappers';
import T, { t } from '../../../common/components/T';
import { rankColors } from '../../../project/uikit/color-variables';
import { getStatusProgress } from '../../../common/utils/utils';
import Display from '../../../common/components/Display';
import Loader from '../../plug/Loader';

const UserData = ({
    styles,
    data,
    trans,
    loaderStyles
}) => {
    const rankColor = useMemo(() => {
        if (data.rank){
            return rankColors[data.rank.sort] || rankColors[rankColors.length-1]
        }
        return rankColors[0]
    }, [data]);
    const progress = useMemo(() => getStatusProgress(data.rankData), [data]);

    return <>
        <Div styles={styles.userModalTop}>
            <Div styles={styles.faceBlock}>
                {svgSprite('rounded-progress-path',
                    {
                        style: styles.progressDefault,
                        width: '122',
                        height: '122',
                        stroke: styles.variable.darkColor
                    })}
                {svgSprite('rounded-progress-value',
                    {
                        style: styles.progressBar,
                        width: '122',
                        height: '122',
                        stroke: rankColor.color
                    },
                    'UserData',
                    [progress.dasharray, progress.dashoffset])}
                <Div styles={styles.userPhotoWrapper}>
                    {data?.image?.links?.preview
                        ? <Img styles={styles.userAvatarStyle} src={data?.image?.links?.preview} />
                        :
                        svgSprite('rank0', {
                            width: '100px',
                            height: '100px',
                            ...data.loaded === false ? { style: { filter: 'blur(4px)' } } : {}
                        })

                    }
                </Div>
                {
                    data.rank?.name || data.loaded !== false ? <Div styles={{ ...styles.userPosition, background: '#48535F' }}>
                        {data?.rank?.alter_name ? (
                            <Div styles={{display: 'flex', 'align-items': 'center'}}>{Array.from(Array(data?.rank?.alter_name).keys()).map(item => {
                                return (
                                    <Div
                                        key={item}
                                        styles={{
                                            display: 'flex',
                                            height: '16px',
                                            margin: '0 2px'
                                        }}
                                    >
                                        {svgSprite('eternity-rank-star', {
                                            width: '16px',
                                            height: '16px',
                                        })}
                                    </Div>
                                )
                            })}</Div>
                        ) : data.rank?.name ? data.rank.name : <T textName='personal_WithoutRank-new-side' defaultStr='Member' page='all'/>}
                    </Div> : null
                }
            </Div>
            <Div styles={styles.userModalTopInfo}>
                {/* <Div styles={styles.userModalTopUsername}>
                    {
                        data.activity ?
                            svgSprite('check-circle-fill', { style: styles.userModalTopUsernameSvg })
                            : data.loaded === false ? null :
                                svgSprite('cross-circle-fill', { fill: styles.variable.redColor, style: styles.userModalTopUsernameSvgRed })

                    }
                    {data.username}
                </Div> */}
                {data.username}
                <Div styles={styles.userModalTopName}>
                    {
                        data.full_name ? data.full_name : <Display type='fio' data={{
                            first_name: data.first_name,
                            last_name: data.last_name,
                            patronymic: data.patronymic
                        }}/>
                    }
                </Div>
                {/* {
                    data.loaded !== false || data.package?.name ? <Div styles={styles.userModalTopStatus}>
                        <P styles={styles.userModalTopStatusText}>
                            {t(
                                'user_modal_package', 'Пакет', 'widget', trans, 'userModal'
                            )}:
                        </P>
                        <P>
                            {data.package?.name ? data.package.name : t(
                                'user_modal_no-package', 'Отсутствует', 'widget', trans, 'userModal'
                            )}
                        </P>
                    </Div> : null
                } */}
            </Div>
        </Div>
        {data.contacts ?
            <Social
                links={data.contacts}
                can_view_partner={data.can_view_partner || true}
            />
            : data.loaded === false ? <Loader compStyles={loaderStyles}/> : null}
    </>
};
UserData.propTypes = {
    styles: PropTypes.object,
    nowStatus: PropTypes.string,
    username: PropTypes.string,
    fio: PropTypes.string,
    pack: PropTypes.string
};
export default UserData;