import { useContext, useMemo } from 'react'
import UserContext from '../../../common/utils/getContext'
import Captcha from '../../captcha/Captcha'
import { getCaptchaFieldData } from '../formUtils'

const FormCaptcha = ({
    alias, attempts, fieldsErr, formStyles, setFormData
}) => {
    const { captcha } = useContext(UserContext)

    const captchaProps = useMemo(() => {
        return getCaptchaFieldData(
            captcha, alias, attempts, fieldsErr, formStyles, setFormData
        )
    }, [alias, attempts, captcha, fieldsErr, formStyles, setFormData])

    if (!captchaProps) return null
    return <Captcha {...captchaProps}/>
}
export default FormCaptcha;