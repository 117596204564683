import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';


const stylesheets = (width, height, variable = {}) => {
    return {
        cookieModalWrapperMob: {
            width: '100%',
            display: 'flex',
            background: variable.darkMiddleTransparentColor,
            'z-index': '9999',
            padding: '12px 0px'
        },
        cookieModalWrapperDesktop: {
            width: '100%',
            height: '80px',
            background: variable.darkMiddleTransparentColor,
            'z-index': '9999',
            transition: 'all .2s ease-in-out'
        },
        cookieModalWrapperTop: {
            position: 'fixed',
            top: '0',
            left: '0'
        },
        cookieModalWrapperBottom: {
            position: 'fixed',
            left: '0',
            bottom: '0'
        },
        cookieModalWrapperToTop: { transform: 'translateY(-100%)' },
        cookieModalWrapperToBottom: { transform: 'translateY(100%)' },
        cookieModalContentWrapperMob: {
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'space-between',
            'align-items': 'center',
            width: 'calc(100% - 32px)',
            height: '100%',
            margin: '0px auto'
        },
        cookieModalContentWrapper: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            width: 'calc(100% - 32px)',
            height: '100%',
            margin: '0px auto'
        },
        cookieModalTextContent: media(width, height, 'w', {
            768: {
                ...uikit.small,
                color: variable.whiteColor,
                'max-width': '1073px',
                overflow: 'hidden',
                'text-overflow': 'ellipsis',
                display: '-moz-box',
                '-moz-box-orient': 'vertical',
                display: '-webkit-box',
                '-webkit-line-clamp': '3',
                '-webkit-box-orient': 'vertical',
                'line-clamp': '3',
                'box-orient': 'vertical'
            },
            0: {
                ...uikit.small,
                color: variable.whiteColor
            }
        }),
        cookieModalBtnWrap: media(width, height, 'w', {
            768: {
                display: 'flex',
                'flex-direction': 'row',
                'margin-left': '40px'
            },
            0: {
                'margin-top': '16px',
                display: 'flex',
                'flex-direction': 'row',
                'justify-content': 'flex-end',
                width: '100%'
            }
        }),
        cookieModalCancelBtn: media(width, height, 'w', {
            768: {
                border: '1px solid',
                'border-color': variable.whiteLessTransparentColor,
                'box-sizing': 'border-box',
                'border-radius': '10px',
                width: '120px',
                height: '40px',
                background: 'transparent',
                color: variable.whiteColor,
                cursor: 'pointer'
            },
            0: {
                border: '1px solid',
                'border-color': variable.whiteLessTransparentColor,
                'box-sizing': 'border-box',
                'border-radius': '10px',
                width: 'calc(50% - 4px)',
                height: '40px',
                background: 'transparent',
                color: variable.whiteColor,
                cursor: 'pointer'
            }
        }),
        cookieModalApplyBtn: media(width, height, 'w', {
            768: {
                'box-sizing': 'border-box',
                'border-radius': '10px',
                'min-width': '105px',
                padding: '0 10px',
                height: '40px',
                background: variable.orangeColor,
                color: variable.whiteColor,
                'margin-left': '8px',
                cursor: 'pointer'
            },
            0: {
                'box-sizing': 'border-box',
                'border-radius': '10px',
                width: '100%',
                height: '40px',
                background: variable.orangeColor,
                color: variable.whiteColor,
                cursor: 'pointer'
            }
        }),
        cookieModalImage: { 'margin-right': '24px' },
        cookieModalMobileContent: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between'
        }
    }
};
export default stylesheets;