import React, {
    useState, useEffect, useRef, useCallback
} from 'react'
import { Div, svgSprite } from '../../../hybrid/wrappers'
import ReactDOM from 'react-dom';

const ModalDropDown = ({
    refTableChild, modal, modalDropdown, refTable, val, getHandlers, styles
}) => {
    const func = useCallback(() => {
        if (modal?.current) {
            const dropDownHeight = modal?.current?.getBoundingClientRect()?.height;
            const dropDownWidth = modal?.current?.getBoundingClientRect()?.width;
            const top = modalDropdown?.current?.getBoundingClientRect()?.top - refTable?.current.getBoundingClientRect()?.top + modalDropdown?.current?.getBoundingClientRect()?.height;
            const bottom = modalDropdown?.current?.getBoundingClientRect()?.top - refTable?.current.getBoundingClientRect()?.top - dropDownHeight;
            const left = modalDropdown?.current?.getBoundingClientRect()?.left - refTable?.current.getBoundingClientRect()?.left + modalDropdown?.current?.getBoundingClientRect()?.width / 2 - dropDownWidth / 2;
            const limitBottom = refTableChild?.current.getBoundingClientRect()?.height;
            if (top > limitBottom) {
                modal.current.style.top = `${bottom}px`;
            } else {
                modal.current.style.top = `${top}px`;
            }
            modal.current.style.left = `${left}px`;
        }
    }, [modal, modalDropdown, refTable, refTableChild])

    useEffect(() => {
        const refTableChildVar = refTableChild?.current;
        func()
        if (refTableChildVar) {
            refTableChildVar.addEventListener('scroll', func)
        }

        return () => {
            refTableChildVar?.removeEventListener('scroll', func)
        }
    }, [func, refTableChild])

    return (
        <Div styles={styles.dropdownWindow} childrenRef={modal} >
            {val.options.filter(option => !option.hidden).map((el, idx) =>
                <Div
                    key={`dropdownWindow${idx}`}
                    {...getHandlers('change', el.id)}

                    styles={styles.dropdownWindowElem}
                    effects={{ hover: { ...styles.dropdownWindowElemHover } }}
                >{el.title}</Div>)}
        </Div>
    )
}
const DropDown = ({
    styles,
    getHandlers,
    translation,
    val,
    refTable,
    gridName,
    refTableChild
}) => {
    const modal = useRef(null);

    const colors = {
        open: styles.variable.blueColor,
        on_hold: styles.variable.orangeDeepColorLight,
        canceled: styles.variable.redDarkColor,
        postponed: styles.variable.greyDeepExtraDarkColor,
        rejected: styles.variable.brownColor,
        returned: styles.variable.purpleDeepColor,
        in_delivery: styles.variable.blueSkyColor,
        done: styles.variable.greenColor,
        waiting_for_a_call: styles.variable.orangeColor
    }

    const ref = useRef();
    const [value, setValue] = useState(val.value || '')
    const [showWindow, setShowWindow] = useState(false)
    const activeElem = val.options.filter(el => el.id === value)
    useEffect(() => {
        if (val) {
            setValue(val.value)
            setShowWindow(false)
        }
    }, [val])

    return (
        <Div
            childrenRef={ref}
            styles={{ ...styles.dropdownWrappers, ...val?.disabled ? styles.dropdownWrappersDisabled : {} }}
            data-uitest='613_uitest' onClick={() => { setShowWindow(!showWindow) }}
        >
            <Div childrenRef={ref} styles={{ ...styles.dropdownElemWrappers, 'background-color': activeElem[0] ? colors[activeElem[0].alias] || colors[activeElem[0].id] : 'grey' }}>
                <Div styles={{ ...styles.dropdownElemTitle }}>{activeElem[0] ? activeElem[0].title : value}</Div>
                {
                    !val.disabled &&
                    <Div styles={showWindow ? { ...styles.dropdownElemSvg } : {}}>
                        {svgSprite('angle-down', {
                            width: '8px',
                            height: '4px',
                            fill: styles.variable.whiteColor,
                            style: { 'margin-bottom': '3px' }
                        })}
                    </Div>

                }
            </Div>
            {
                !!showWindow &&
                document.getElementById(`${gridName}portalDropdown`) &&
                ReactDOM.createPortal(<ModalDropDown
                    modal={modal}
                    styles={styles}
                    refTable={refTable}
                    getHandlers={getHandlers}
                    modalDropdown={ref}
                    val={val}
                    refTableChild={refTableChild}
                    translation={translation}
                />, document?.getElementById(`${gridName}portalDropdown`))
            }
        </Div>
    )
}
export default DropDown