import { createContext } from 'react';

const GlobalContext = createContext();

export const UserProfileContext = createContext();

export const UserFinanceContext = createContext();

export const StoreContext = createContext();

export default GlobalContext;