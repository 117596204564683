// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import { Div, svgSprite } from '../../../../../hybrid/wrappers';

const PasswordType = ({
    styles, setShowPass, showPass, compStyle
}) => {
    return (
        <Div
            styles={{
                ...styles.formControlIconWrap, ...compStyle?.formInputPasswordEye, fill: styles.variable.greyExtraDarkColor
            }}
            effects={{ hover: { fill: styles.variable.blueColor } }}
            data-uitest='601_uitest' onClick={() => setShowPass((pre) => !pre)}
        >
            {!showPass
                ? svgSprite('eye-slash', {
                    width: '20px',
                    height: '20px',
                    style: { pointerEvents: 'none' },
                    fill: styles.variable.blackColor
                })

                : svgSprite('eye', {
                    width: '20px',
                    height: '20px',
                    style: { pointerEvents: 'none' },
                    fill: styles.variable.blackColor
                })}
        </Div>
    );
};

export default PasswordType;