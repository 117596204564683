import { dataSender } from '../../common/utils/router';

export const sendFormDataFunc = (
    dataToSend,
    allowedSend,
    funcs,
    sendData,
    setAllowedSend,
    setFieldsErr,
    setAlertData,
    userToken,
    lang,
    message,
    currency
) => {
    if (allowedSend) {
        const modernDataToSend = dataToSend && Object.keys(dataToSend).length
            ? Object.keys(dataToSend).reduce((accum, key) => {
                if (funcs[key] && funcs[key].transform) {
                    if (dataToSend[key]) {
                        accum[key] = funcs[key].transform(dataToSend[key])
                    } else {
                        accum[key] = null
                    }
                } else if (funcs[key] && funcs[key].onChange) {
                    accum[key] = funcs[key].onChange(dataToSend[key]);
                } else {
                    accum[key] = dataToSend[key];
                }
                return accum;
            }, {})
            : dataToSend;
        if (sendData?.sender || sendData?.url) setAllowedSend(false);

        if (sendData?.sender) return sendData.sender(dataToSend, setAllowedSend, setFieldsErr);

        if (sendData?.url) {
            dataSender(
                sendData.url,
                sendData?.method || 'POST',
                modernDataToSend,
                (response) => {
                    setFieldsErr({});
                    if (window.grecaptcha){
                        window.grecaptcha.reset();
                    }
                    if (message?.success) {
                        setAlertData({ type: 'success', cont: message?.success });
                    }
                    if (sendData?.afterSenderSuccess) {
                        return sendData.afterSenderSuccess(response.data, setFieldsErr, setAllowedSend, dataToSend);
                    }
                    setAllowedSend(true);
                },
                (data, status, headers) => {
                    if (message?.error === false) {
                        setAlertData(false);
                    } else if (data.message) {
                        setAlertData({ type: 'error', cont: data.message });
                    }
                    if (data?.errors && Object.keys(data.errors).length) {
                        setFieldsErr(data.errors);
                    }
                    if (sendData?.afterSenderError) {
                        sendData.afterSenderError(
                            data, status, headers, setFieldsErr, setAllowedSend
                        );
                    } else {
                        setAllowedSend(true);
                    }
                },
                userToken,
                lang,
                currency,
                sendData?.dataType || 'json'
            );
        }
        if (sendData?.func) {
           sendData.func()
        }
    }
};

export const initFormHandlers = (
    handlers = {}, attribute, value, type, errors, setFocused
) => {
    const defaultHandlers = setFocused ? {
        onFocus: () => { setFocused(true) },
        onBlur: () => { setFocused(false) }
    } : {}
    if (handlers && Object.keys(handlers).length > 0) {
        return Object.keys(handlers).reduce((accum, key) => {
            const previous = accum[key];
            accum[key] = () => {
                previous && previous()
                handlers[key](attribute, value, type, errors)
            }

            return accum;
        }, defaultHandlers);
    }
    return defaultHandlers;
};

export const getCaptchaFieldData = (
    captcha, alias, attemps, fieldsErr, formStyles, setFormData
) => {
    if (!captcha || !alias) return null;
    const attribute = captcha?.request_key;
    return attribute && captcha?.settings ? {
        attribute: attribute,
        type: 'captcha',
        count: captcha?.settings.find(item => {
            return item.attribute === 'user::try_count'
        })?.value,
        version: captcha?.name,
        site_key: captcha?.settings.find(item => {
            return item.attribute === 'site_key'
        })?.value,
        value: {
            value:
            captcha?.settings?.find(item => {
                return item.attribute === alias
            })?.value,
            attribute:
            captcha?.settings?.find(item => {
                return item.attribute === alias
            })?.attribute
        },
        errors: fieldsErr?.[attribute],
        compStyle: formStyles?.[attribute] || {},
        tryCount: captcha?.settings?.find(el => el.attribute === 'user::try_count')?.value,
        setFormData,
        attempts: attemps || 0
    } : false
}