import React, {
    useState, useCallback, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import UserContext from '../../../../common/utils/getContext'
import { getData } from '../../../../common/utils/router';
import FormSelect from './FormSelect';

const FormDropdownSearch = (props) => {
    const {
        attribute,
        disabled,
        handlers = {},
        value,
        setFormData,
        routePage,
        searchParams,
        options = []
    } = props;
    const [dropData, setDropData] = useState([])
    const {
        userToken, setAlertData, lang
    } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            const data = await getData(`/api/v1/${routePage}`, userToken, lang);
            const globalErr = data.globalErr || data.globalErr;
            if (globalErr) {
                setAlertData({ type: 'error', cont: globalErr });
            } else if (data) {
                let newData = []
                data?.data?.map((elem) => {
                    const funcChild = (item, idx) => {
                        idx++
                        if (item.children && item.children.length) {
                            let line = ''
                            const oneLine = '- '
                            for (let i = 0;i < idx;i++) {
                                line = line + oneLine
                            }

                            item.children.map((child) => {
                                newData.push({
                                    ...child,
                                    name: `${line}${child.name}`
                                })
                                funcChild(child, idx)
                            })
                        }
                    }
                    newData.push(elem)
                    funcChild(elem, 0)
                })
                newData = newData
                    .filter((el) => el.name || el.code || el.title)
                    .map((el) => ({
                        id: el.id,
                        title: el.name || el.code || el.title || '-'
                    }))
                setDropData([...options, ...newData])
            }
        })();
    }, [userToken, setAlertData, lang, routePage, options]);
    const searchGlobalFunc = async (text, callback) => {
        const getParams = `?filter[${searchParams}]=${text}`
        const searchData = await getData(`/api/v1/${routePage}${getParams}`, userToken, lang);
        const globalErr = searchData.globalErr;
        if (globalErr) {
            setAlertData({ type: 'error', cont: globalErr });
        } else if (searchData && searchData?.data) {
            let newData = []
            searchData?.data?.map((elem) => {
                const funcChild = (item, idx) => {
                    idx++
                    if (item.children && item.children.length) {
                        let line = ''
                        const oneLine = '- '
                        for (let i = 0;i < idx;i++) {
                            line = line + oneLine
                        }

                        item.children.map((child) => {
                            newData.push({
                                ...child,
                                name: `${line}${child.name}`
                            })
                            funcChild(child, idx)
                        })
                    }
                }
                newData.push(elem)
                funcChild(elem, 0)
            })
            newData = newData.map((el) => ({
                value: el.id,
                label: el.name || el.code || el.title || '-'
            }))
            callback(newData)
        }
    };

    const [val, setVal] = useState(value);
    const changeHandler = useCallback((item) => {
        if (!disabled && item.length) {
            setFormData(attribute, item);
            setVal(item);
        } else if (item.length === 0) {
            setFormData(attribute, item)
        }
    }, [disabled, setFormData, attribute]);

    useEffect(() => {
        if (value) {
            setFormData(attribute, value);
            setVal(value || []);
        }
    }, [attribute, setFormData, value]);

    return (
        <FormSelect
            {...props}
            options={dropData}
            multiple={true}
            // isClear={true}
            value={val}
            loadOptions={searchGlobalFunc}
            handlers={{
                ...props.handlers || {},
                onChange: (e) => {
                    changeHandler(e);
                    handlers?.onBlur && handlers?.onBlur(attribute, e);
                }
            }}
        />
    )
};

FormDropdownSearch.propTypes = {
    attribute: PropTypes.string,
    disabled: PropTypes.bool,
    handlers: PropTypes.object,
    value: PropTypes.array,
    setFormData: PropTypes.func,
    searchParams: PropTypes.string,
    routePage: PropTypes.string
};

export default Styles(FormDropdownSearch, getStyles);