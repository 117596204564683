import { media } from '../../../common/utils/utils';
import officeStyles from './office/grid-styles'
import mainStyles from './main/grid-styles'
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    gridWrapper: {
        position: 'relative',
        'z-index': '4'
    },
    gridWrapperBorderRadius: { 'border-radius': '14px 14px 0px 0px' },
    gridWrapperMinHeight: { 'padding-bottom': '200px' },
    grid: {
        'border': 0,
        'border-spacing': 0,
        'border-collapse': 'collapse',
        width: '100%'
    },
    gridMinWidth: {},
    trHover: { 'background-color': variable.darkSuperTransparentColor },
    gridWrapperOverflow: {
        // 'overflow-x': 'auto',
        // 'overflow-y': 'hidden',
        overflow: 'auto' // чтоб появлялся scroll, когда у последнего открыты контакты
    },
    imageGrid: {
        width: '48px',
        height: '48px',
        'border-radius': '8px',
        overflow: 'hidden',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    },
    imageGridItem: { height: '100%' },
    row: {
        flexDirection: 'row',
        flex: 1,
        'padding-top': '5px',
        'padding-bottom': '5px'
        // ...uikit.flexRow
    },
    rowWeb: {
        'padding-top': '5px',
        'padding-bottom': '5px'
    },
    rowThWeb: {
        'text-transform': 'uppercase',
        ...uikit.small,
        'font-weight': '700',
        'background-color': variable.greyExtraLightColor
    },
    theadBlock: {},
    thead: {
        // 'border-radius': '20px',
        display: 'flex',
        'flex-direction': 'row'
        // 'align-items': 'center',
        // 'justify-content': 'center'
    },
    theadNative: media(width, height, 'w', {
        768: {
            height: 56,
            backgroundColor: variable.greyExtraDarkTransparentColor,
            borderTopLeftRadius: 14
        },
        0: {
            height: 44,
            backgroundColor: variable.greyExtraDarkTransparentColor,
            borderTopLeftRadius: 14
        }
    }),
    th: media(width, height, 'w', {
        768: {
            // 'font-weight': 700,
            'background-color': variable.greyExtraLightColor,
            'font-size': '0.875rem',
            'line-height': '1.715',
            'text-transform': 'uppercase',
            'text-align': 'left',
            'padding-top': '16px',
            'padding-right': '0',
            'padding-bottom': '16px',
            'padding-left': '16px',
            'white-space': 'nowrap'
        },
        0: {
            // 'font-weight': 700,
            'background-color': variable.greyExtraLightColor,
            'font-size': '0.875rem',
            'line-height': '1.47',
            'text-transform': 'uppercase',
            'text-align': 'left',
            'padding-top': '10px',
            'padding-right': '0',
            'padding-bottom': '10px',
            'padding-left': '14px',
            'white-space': 'nowrap'
        }
    }),
    thSticky: media(width, height, 'w', {
        768: {
            'background-color': variable.greyExtraLightColor,
            'font-size': '0.875rem',
            'line-height': '1.715',
            'text-transform': 'uppercase',
            'text-align': 'left',
            'white-space': 'nowrap',
            'padding-top': '16px',
            'padding-bottom': '16px',
            'max-height': '46px'
        },
        0: {
            'background-color': variable.greyExtraLightColor,
            'font-size': '0.875rem',
            'line-height': '1.47',
            'text-transform': 'uppercase',
            'text-align': 'left',
            'white-space': 'nowrap',
            'padding-top': '10px',
            'padding-bottom': '10px',
            'max-height': '46px'
        }
    }),
    thNative: {
        paddingLeft: 16,
        paddingTop: 12,
        paddingBottom: 12,
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 700,
        // height: 56,
        fontSize: 14,
        textTransform: 'uppercase'
    },
    thNativeText: {
        textTransform: 'uppercase',
        fontWeight: 700
    },
    tdFixedHeight: { height: '50px' },
    tdAlignCenter: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'text-align': 'center',
        'justify-content': 'center'
    },
    tdAlignLeft: {
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'flex-start',
        'align-items': 'center',
        'text-align': 'left'
    },
    tdAlignRight: {
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        'align-items': 'center',
        'text-align': 'right'
    },
    tdLeftText: { 'justify-content': 'flex-start' },
    filterThIconWrapper: {
        width: '18px',
        height: '18px',
        'padding-left': '4px'
    },
    thContent: {
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'center',
        'align-items': 'center',
        color: variable.darkColor
    },
    thContentLeft: { 'justify-content': 'flex-start' },
    thContenNative: { 'padding-left': '16px' },
    thFirstNative: {
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'flex-start'
    },
    filterThIcon: {
        width: '18px',
        height: '18px',
        fill: variable.darkColor
    },
    tdLast: {
        'padding-right': '24px',
        'white-space': 'nowrap'
    },
    td: {
        position: 'relative',
        'z-index': '1',
        'text-align': 'left',
        'border-bottom-width': '1px',
        'border-bottom-color': variable.darkExtraTransparentColor,
        'border-style': 'solid',
        'padding-top': '12px',
        'padding-bottom': '12px',
        'padding-left': '16px',
        'padding-right': '0'
    },
    tdSafari: { 'z-index': '0' },
    tdWrapNative: media(width, height, 'w', {
        768: {
            borderBottomWidth: 1,
            borderColor: variable.darkExtraTransparentColor,
            borderStyle: 'solid',
            height: 80,
            paddingTop: 4,
            paddingBottom: 4
        },
        0: {
            borderBottomWidth: 1,
            borderColor: variable.darkExtraTransparentColor,
            borderStyle: 'solid',
            height: 50,
            paddingTop: 4,
            paddingBottom: 4
        }
    }),
    tdNative: {
        borderBottomWidth: 0,
        flex: 1,
        flexShrink: 0,
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '0',
        'align-items': 'center',
        'justify-content': 'center'
    },
    tdNativeContent: {
        'justify-content': 'center',
        'flex-direction': 'row'
    },
    tdNativeRow: {
        flex: 1,
        flexShrink: 0,
        borderBottomWidth: 0,
        borderColor: variable.darkExtraTransparentColor,
        borderStyle: 'solid',
        paddingTop: 10,
        paddingLeft: 16,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tdFirst: {
        'display': 'flex',
        'flex-direction': 'row'
    },
    td__depthWrap: {
        display: 'flex',
        'flex-direction': 'row',
        position: 'relative',
        'z-index': 5
    },
    switcherLoader: {
        'margin-right': '12px',
        'pointer-events': 'none'
    },
    td__depth: {
        width: '18px',
        height: '18px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '5px',
        'background-color': variable.greyMainColor,
        'align-self': 'center',
        'margin-right': '12px',
        position: 'relative',
        'z-index': 5
    },
    td__nodepth: { 'padding-left': '16px' },
    divShowMenu: {
        width: '18px',
        height: '18px',
        borderRadius: '5px',
        'background-color': variable.greyMainColor,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'margin-right': '0',
        'z-index': '5',
        position: 'relative',
        'align-self': 'center'
    },
    gridDotsWrap: {
        width: '37px',
        display: 'flex',
        'flex-direction': 'column',
        'background-color': variable.whiteColor,
        position: 'relative',
        'z-index': 1,
        'margin-top': '-28px',
        'margin-bottom': '-28px',
        'padding-left': '9px',
        'border-radius': '5px'
    },
    gridDotsTop: {
        flex: 0.5,
        'border-left-width': '1px',
        'border-left-style': 'dotted',
        'border-left-color': variable.blackColor
    },
    gridDotsHorizontal: {
        'border-bottom-width': '1px',
        'border-bottom-style': 'dotted',
        'border-bottom-color': variable.blackColor
    },
    gridDotsBottom: {
        flex: 0.5,
        'border-left-width': '1px',
        'border-left-style': 'dotted',
        'border-left-color': variable.blackColor
    },
    userPackages: {
        color: variable.whiteColor,
        'background-color': variable.orangeDarkColor,
        'border-radius': '8px',
        'font-weight': 500,
        ...uikit.small,
        'line-height': '16px',
        padding: '4px 12px'
    },
    paginationArrows: media(width, height, 'w', {
        768: {
            color: variable.darkColor,
            'background-color': variable.greyExtraDarkTransparentColor,
            'border-radius': '50px',
            width: '40px',
            height: '40px',
            'flex-shrink': 2,
            'font-weight': 500,
            ...uikit.text,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        },
        0: {
            color: variable.darkColor,
            'background-color': variable.greyExtraDarkTransparentColor,
            'border-radius': '50px',
            width: '24px',
            height: '24px',
            'flex-shrink': 2,
            'font-weight': 500,
            ...uikit.text,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        }
    }),
    paginationArrowsNative: media(width, height, 'w', {
        992: {
            color: variable.darkColor,
            'background-color': variable.greyExtraDarkTransparentColor,
            'border-radius': '50px',
            width: '40px',
            height: '40px',
            'flex-shrink': 2,
            'font-weight': 500,
            ...uikit.text,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        },
        0: {
            color: variable.darkColor,
            'background-color': variable.greyExtraDarkTransparentColor,
            'border-radius': '50px',
            width: '24px',
            height: '24px',
            'flex-shrink': 2,
            'font-weight': 500,
            ...uikit.text,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        }
    }),
    paginationArrowsItem: {
        width: '7px',
        height: '11px',
        fill: 'currentColor'
    },
    paginationArrowsItemNative: {
        width: '7px',
        height: '11px',
        fill: variable.darkColor
    },

    paginationArrowsActive: {
        'background-color': variable.blueColor,
        stroke: variable.whiteColor
    },
    paginationWrapper: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'center'
    },
    paginationWrapperMobile: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'space-between'
    },
    paginationArrowsMobileWrap: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        margin: '0 -3px'
    },
    paginationArrowsMobile: {
        margin: '0 3px',
        width: '40px',
        height: '40px',
        background: variable.greyExtraDarkColorTransparent,
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'border-radius': '50%',
        'justify-content': 'center'
    },
    paginationBlockMobile: {
        ...uikit.small,
        color: variable.greySuperDarkColor
    },
    paginationBlock: {
        'border-radius': '20px',
        'background-color': variable.greyExtraDarkTransparentColor,
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'center',
        padding: '0 8px',
        margin: '0 8px'
    },
    paginationNumberBlock: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'justify-content': 'center',
            'min-width': '40px',
            height: '40px',
            'padding': '0 5px',
            'border-radius': '8px',
            'margin-left': '2px',
            'margin-right': '2px'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'justify-content': 'center',
            'min-width': '24px',
            height: '24px',
            padding: '0 3px',
            'border-radius': '8px',
            'margin-left': '1px',
            'margin-right': '1px'
        }
    }),
    paginationNumberBlockActive: { 'background-color': variable.blueColor },
    paginationNumber: {
        color: variable.darkColor,
        'font-weight': 500,
        ...uikit.text,
        display: 'flex',
        'align-items': 'center',
        'text-align': 'center',
        'flex-grow': 1,
        width: '100%',
        'flex-direction': 'row',
        'justify-content': 'center'
    },
    switcherWrap: {
        'margin-top': '4px',
        'margin-bottom': '4px'
    },
    switcherWrapRelative: {
        position: 'relative',
        'z-index': 2
    },
    widthFitContent: { width: 'fit-content' },
    modalInfoUserInfoBlock: { display: 'flex' },
    userNameBlock: media(width, height, 'w', {
        768: {
            display: 'inline-flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'background-color': variable.blueExtraTransparentColor,
            'border-radius': '8px',
            height: '30px',
            'align-items': 'center',
            padding: '0 8px',
            'font-weight': 500,
            ...uikit.text,
            color: variable.blueColor
        },
        0: {
            display: 'inline-flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'background-color': variable.blueExtraTransparentColor,
            'border-radius': '8px',
            height: '22px',
            'align-items': 'center',
            padding: '0 8px',
            'font-weight': 500,
            ...uikit.text,
            color: variable.blueColor
        }
    }),
    userNameBlockMb: { 'margin-bottom': '5px' },
    userNameBlockIcon: media(width, height, 'w', {
        768: {
            width: '20px',
            height: '20px',
            fill: variable.blueColor
        },
        0: {
            width: '16px',
            height: '16px',
            fill: variable.blueColor
        }
    }),
    userNamePNative: { 'font-size': '0.75rem' },
    userNameBlockNative: {
        display: 'flex',
        'flex-direction': 'row',
        'align-self': 'flex-start',
        'align-items': 'center',
        'justify-content': 'center',
        'background-color': variable.blueExtraTransparentColor,
        'border-radius': '8px',
        height: '22px',
        padding: '0 12px'
    },
    buyerName: {
        'white-space': 'nowrap',
        ...uikit.text,
        'line-height': '1.75',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'flex-start'
    },
    buyerUsername: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'margin-top': '4px'
    },
    buyerUsernameIcon: {
        width: '18px',
        height: '18px',
        'margin-right': '8px',
        display: 'flex'
    },
    buyerUsernameText: { ...uikit.small },
    //statuses
    status_posted: { 'background-color': variable.greenColor },
    status_confirmed: { 'background-color': variable.greenColor },
    status_done: { 'background-color': variable.greenColor },
    status_canceled: { 'background-color': variable.redDarkColor },
    status_failed: { 'background-color': variable.redDarkColor },
    status_deleted: { 'background-color': variable.redDarkColor },
    status_rejected: { 'background-color': variable.redDarkColor },
    status_hidden: {
        'background-color': variable.greyColor,
        color: variable.greySuperDarkColor
    },
    status_postponed: {
        'background-color': variable.greyColor,
        color: variable.greySuperDarkColor
    },
    status_inprocessing: { 'background-color': variable.orangeDeepColor },
    status_on_hold: { 'background-color': variable.orangeDeepColor },
    status_waiting: { 'background-color': variable.orangeColor },
    status_waiting_for_a_call: { 'background-color': variable.orangeColor },
    status_free: {
        background: variable.greyColor,
        color: variable.greySuperDarkColor
    },
    status_payment: {
        background: variable.orangeColor,
        color: variable.whiteColor
    },
    status_withdrawal: {
        background: variable.greenColor,
        color: variable.whiteColor
    },
    status_freezed: {
        background: variable.redDarkColor,
        color: variable.whiteColor
    },
    status_not_used: {
        background: variable.purpleDeepColor,
        color: variable.whiteColor
    },
    status_pending: {
        background: variable.blueColor,
        color: variable.whiteColor
    },
    status_broadcasted: {
        background: variable.blueColor,
        color: variable.whiteColor
    },
    status_delivering: { 'background-color': variable.blueSkyColor },
    status_in_delivery: { 'background-color': variable.blueSkyColor },
    status_returned: { 'background-color': variable.purpleDeepColor },
    status_new: { 'background-color': variable.blueColor },
    status_default: { 'background-color': variable.blueColor },
    status_active: { 'background-color': variable.greenColor },
    status_opened: { 'background-color': variable.blueColor },
    //statuses
    orderStatus: {
        'white-space': 'nowrap',
        'border-radius': '8px',
        'padding-top': '4px',
        'padding-bottom': '4px',
        'padding-left': '12px',
        'padding-right': '12px',
        'font-weight': '500',
        ...uikit.small,
        'line-height': '1.14',
        color: variable.whiteColor,
        display: 'flex',
        'align-self': 'center',
        'background-color': variable.greyColor3
    },
    orderStatusNew: { 'background-color': variable.blueColor },
    orderStatusInprocessing: { 'background-color': variable.orangeDeepColor },
    orderStatusInprocessingCommunication: { 'background-color': variable.orangeColor },
    orderStatusActiveCategory: { 'background-color': variable.greenColor },
    orderStatusPosted: { 'background-color': variable.greenColor },
    orderStatusUnActiveCategory: {
        'background-color': variable.greyColor,
        color: variable.greySuperDarkColor
    },
    orderStatusDeletedCategory: { 'background-color': variable.redDarkColor },
    orderStatusRejectCategory: { 'background-color': variable.redDarkColor },
    orderStatusDelivery: { 'background-color': variable.blueSkyColor },
    orderStatusReturned: { 'background-color': variable.purpleDeepColor },
    orderStatusWaiting: { 'background-color': variable.orangeColor },
    package_null: {
        'background-color': variable.greyColor,
        color: variable.greyExtraDarkColor
    },
    package_1: { 'background-color': variable.redDarkColor },
    package_2: { 'background-color': variable.orangeColor },
    package_3: { 'background-color': variable.greenColor },
    package_4: { 'background-color': variable.blueColor },
    package_5: { 'background-color': variable.blueSkyColor },
    package_10: { 'background-color': 'rgba(206, 176, 18, 1)' },
    package_20: { 'background-color': 'rgba(206, 142, 18, 1)' },
    package_30: { 'background-color': 'rgba(135, 137, 44, 1)' },
    package_40: { 'background-color': 'rgba(44, 137, 76, 1)' },
    package_50: { 'background-color': 'rgba(44, 104, 137, 1)' },

    package_60: { 'background-color': 'rgba(44, 58, 137, 1)' },
    package_70: { 'background-color': 'rgba(77, 44, 137, 1)' },
    package_80: { 'background-color': 'rgba(137, 44, 104, 1)' },
    package_90: { 'background-color': 'rgba(137, 44, 50, 1)' },
    package_wrap: { 'background-color': 'rgba(137, 44, 50, 1)' },

    tableButton: media(width, height, 'w', {
        768: {
            cursor: 'pointer',
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'center',
            width: '48px',
            height: '48px',
            'margin-left': '4px',
            'margin-right': '4px',
            'border-radius': '14px',
            'border-width': '1px',
            'border-style': 'solid',
            'border-color': variable.calcColor('dark', '0.15'),
            color: variable.blueColor
        },
        0: {
            cursor: 'pointer',
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'center',
            width: '40px',
            height: '40px',
            'margin-left': '2px',
            'margin-right': '2px',
            'border-radius': '14px',
            'border-width': '1px',
            'border-style': 'solid',
            'border-color': variable.calcColor('dark', '0.15'),
            color: variable.blueColor
        }
    }),
    tableButtonHover: {
        color: variable.blueColor,
        'background-color': variable.greyExtraDarkTransparentColor,
        'border-color': 'transparent'
    },
    tableButtonActive: {
        color: variable.blueColor,
        'background-color': variable.greyExtraDarkTransparentColor,
        'border-color': 'transparent'
    },
    tableButtonIcon: media(width, height, 'w', {
        768: {
            width: '24px',
            height: '24px',
            fill: variable.blueColor
        },
        0: {
            width: '22px',
            height: '22px',
            fill: variable.blueColor
        }
    }),
    tableButtonIconNew: { fill: variable.greenColor },
    tableButtonIconDelete: media(width, height, 'w', {
        768: {
            width: '16px',
            height: '18px',
            fill: variable.redDarkColor
        },
        0: {
            width: '16px',
            height: '18px',
            fill: variable.redDarkColor
        }
    }),
    tableButtonIconSmall: media(width, height, 'w', {
        768: {
            width: '14.25px',
            height: '10.5px',
            fill: variable.blueColor
        },
        0: {
            width: '14.25px',
            height: '10.5px',
            fill: variable.blueColor
        }
    }),
    tableButtonIconLock: media(width, height, 'w', {
        768: {
            width: '24px',
            height: '24px',
            fill: 'none'
        },
        0: {
            width: '22px',
            height: '22px',
            fill: 'red',
            fill: 'none'

        }
    }),
    flexRow: { ...uikit.flexRow },
    flexRowCenter: {
        ...uikit.flexRow,
        ...uikit.justifyContentCenter
    },

    flexColumn: { ...uikit.flexColumn },
    alignItemsEnd: { ...uikit.alignItemsEnd },
    alignItemsCenter: { ...uikit.alignItemsCenter },
    alignItemsStart: { ...uikit.alignItemsStart },
    paginationNumberActive: { color: variable.whiteColor },
    formCursorPointer: { ...uikit.pointer },
    marginRight8: { ...uikit.mr2 },
    marginTop24: { ...uikit.mt4 },
    paddingLeft0: { ...uikit.pl0 },
    paddingLeft1: { 'padding-left': '1px' },
    stubSwitcher: {
        width: '30px',
        height: 0
    },
    noData: {
        'padding-top': '16px',
        'padding-bottom': '16px',
        'padding-left': '16px',
        'padding-right': '16px'
    },
    horizontLine: {
        position: 'absolute',
        'z-index': '1',
        bottom: '50%',
        height: '1px',
        width: '25px'
    },
    horizontLineNative: { width: 37 },
    horizontLineWeb: {
        width: '37px',
        background: `repeating-linear-gradient(to right, ${variable.greyExtraDarkColor}, ${variable.greyExtraDarkColor} 2px, transparent 2px, transparent 5px)`
    },
    verticalLine: {
        position: 'absolute',
        'z-index': '1',
        bottom: '50%',
        width: '1px',
        height: '87px'
    },
    verticalLineWeb: { background: `repeating-linear-gradient(to top, ${variable.greyExtraDarkColor}, ${variable.greyExtraDarkColor} 2px, transparent 2px, transparent 5px)` },
    lineNative: {
        borderColor: variable.greyMainColor,
        borderWidth: '1px',
        borderStyle: 'dashed',
        borderRadius: '1px'
    },
    deletedRow: { background: variable.redBackground },
    blockedRow: { 'background': variable.orangeDeepTransparentColorLight },
    activeRow: {
        'background': variable.blueColorTransparent,
        position: 'absolute',
        'z-index': '1',
        top: '4px',
        bottom: '4px',
        left: 0,
        right: 0,
        'border-top': '1px solid transparent',
        'border-bottom': '1px solid transparent'
    },
    activeRowSafari: { 'z-index': '-1' },
    activeRowFirst: {
        'border-radius': '14px 0px 0px 14px',
        'border-left': '1px solid transparent'
    },
    activeRowLast: {
        'border-radius': '0px 14px 14px 0px',
        'border-right': '1px solid transparent'
    },
    deleteUserLabel: {
        background: variable.redDarkColor,
        'font-weight': '500',
        ...uikit.tiny,
        'color': variable.whiteColor,
        'padding': '2px 8px',
        'margin-left': '8px',
        'border-radius': '10px'
    },
    documentUserLabel: {
        'font-weight': 500,
        ...uikit.tiny,
        'line-height': '1.16',

        display: 'flex',
        'align-items': 'center',

        color: variable.greySuperDarkColor,
        background: variable.greyColor,
        'border-radius': '8px',
        'margin-left': '8px',

        padding: '5px 8px',
        'white-space': 'nowrap'
    },
    tableButtonIconClosed: media(width, height, 'w', {
        768: {
            width: '12px',
            height: '12px',
            fill: variable.redDarkColor
        },
        0: {
            width: '12px',
            height: '12px',
            fill: variable.redDarkColor
        }
    }),
    heightText: {
        height: '43px',
        'line-height': '43px'
    },
    blockedUserLabel: {
        background: variable.orangeColor,
        'font-weight': '500',
        ...uikit.tiny,
        'color': variable.whiteColor,
        'padding': '2px 8px',
        'margin-right': '8px',
        'margin-left': '8px',
        'border-radius': '10px'
    },
    newUserLabel: {
        background: variable.greenColor,
        'font-weight': '500',
        ...uikit.tiny,
        'color': variable.whiteColor,
        'padding': '2px 8px',
        'margin-left': '8px',
        'border-radius': '10px'
    },
    // For dropdown - text(category)
    svgDropPM: {
        width: '18px',
        height: '18px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'border-radius': '5px',
        background: variable.greyTransparentColor,
        // 'margin-left': '12px',
        'margin-right': '8px'
    },

    dropdownWrapper: {
        display: 'inline-flex',
        'flex-direction': 'column',
        position: 'relative'
    },
    dropdownWrapperNative: {
        display: 'flex',
        'flex-direction': 'row',
        position: 'relative'
    },
    dropdownBlockRow: {
        display: 'flex',
        'align-items': 'center',
        'flex-direction': 'row',
        'justify-content': 'flex-start',
        'align-self': 'flex-start',

        'background-color': variable.greyExtraDarkTransparentColor,
        'border-radius': '14px',
        padding: '8px',
        transition: 'box-shadow 0.3s'
    },
    dropdownBlockRowNative: {
        display: 'flex',
        'align-items': 'center',
        'flex-direction': 'row',
        'justify-content': 'flex-start',
        'align-self': 'flex-start',

        'background-color': variable.greyExtraDarkTransparentColor,
        'border-radius': '14px',
        padding: '8px'
    },
    dropdownBlockRowActiveWeb: {
        'background-color': variable.whiteColor,
        'border-radius': `${'14px'} ${'14px'} 0 0`,
        'box-shadow': `0px 10px 10px ${variable.blackSuperTransparentColor}`
    },
    dropdownBlockRowActiveNative: {
        'background-color': variable.whiteColor,
        'border-radius': `${'14px'} ${'14px'} 0 0`,
        shadowColor: variable.blackSuperTransparentColor,
        shadowOpacity: 0.20,
        shadowRadius: 1.41,
        elevation: 10
    },
    dropdownBlockColumn: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'flex-start',
            'flex-direction': 'column',
            'justify-content': 'flex-start',
            'align-self': 'flex-start',

            position: 'absolute',
            left: 0,
            right: 0,
            top: '32px',
            width: 'fit-content',
            'z-index': 10,
            'background-color': variable.greyExtraDarkTransparentColor,
            'border-radius': `0 0 ${'14px'} ${'14px'}`,
            padding: '8px'
        },
        0: {
            display: 'flex',
            'align-items': 'flex-start',
            'flex-direction': 'column',
            'justify-content': 'flex-start',
            'align-self': 'flex-start',

            position: 'absolute',
            width: 'fit-content',
            left: 0,
            right: 0,
            top: '32px',
            'z-index': 10,
            'background-color': variable.greyExtraDarkTransparentColor,
            'border-radius': `0 0 ${'14px'} ${'14px'}`,
            padding: '6px'
        }
    }),

    dropdownBlockColumnNative: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'flex-start',
            'flex-direction': 'column',
            'justify-content': 'flex-start',
            'align-self': 'flex-start',
            position: 'absolute',
            left: 0,
            right: 0,
            top: '32px',
            'z-index': 10,
            'border-radius': `0 0 ${'14px'} ${'14px'}`,
            padding: '8px'
        },
        0: {
            display: 'flex',
            'align-items': 'flex-start',
            'flex-direction': 'column',
            'justify-content': 'center',
            flex: 1,
            position: 'absolute',
            left: 0,
            right: 0,
            top: '32px',
            'z-index': 10,
            'border-radius': `0 0 ${'14px'} ${'14px'}`,
            padding: '6px'
        }
    }),
    dropdownBlockColumnActiveWeb: {
        'background-color': variable.whiteColor,
        'box-shadow': `0px 10px 10px ${variable.blackSuperTransparentColor}`
    },
    dropdownBlockColumnActiveNative: {
        'background-color': variable.whiteColor,
        borderBottomLeftRadius: `${'14px'}`,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: `${'14px'}`,
        borderTopRightRadius: 0,
        shadowColor: variable.blackSuperTransparentColor,
        shadowOpacity: 0.20,
        shadowRadius: 1.41,
        elevation: 10
    },
    dropdownItem: {
        display: 'flex',
        'align-items': 'center',
        color: variable.blueColor,
        ...uikit.text
    },
    contactIcon: {
        width: '20px',
        height: '20px',
        display: 'flex',
        marginRight: '8px'
    },
    contactIconNative: {
        width: 20,
        height: 20,
        display: 'flex',
        marginRight: 8
    },
    dropdownArrow: {
        width: '15px',
        height: '15px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'flex-direction': 'row',
        'margin-top': '4px',
        'margin-left': '6px'
    },
    cursorPointer: { 'cursor': 'pointer' },
    dropdownArrowSvg: {
        fill: variable.blueTotalGray,
        width: '10px',
        height: '10px'
    },
    imageTextCell: {
        display: 'flex',
        'align-items': 'center',
        'flex-direction': 'row'
    },
    imageTextCellImg: {
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '50%',
        'margin-right': '12px',
        background: variable.greyExtraLightColor
    },
    patronymicBlock: {
        ...uikit.flexRow,
        ...uikit.justifyContentStart,
        ...uikit.alignItemsCenter,
        padding: '4px 6px 4px 6px',
        'border-radius': '6px',
        'background-color': variable.blueExtraTransparentColor
    },
    dropdownWrappers: { position: 'relative' },
    dropdownWrappersDisabled: { 'pointer-events': 'none' },
    dropdownWindow: {
        position: 'absolute',
        'background-color': variable.whiteColor,
        'border-radius': '14px',
        padding: '8px',
        'box-shadow': `0 0 10px 10px ${variable.blackSuperTransparentColor}`,
        'z-index': '99'
    },
    dropdownWindowTop: {
        position: 'absolute',
        transform: 'translate(0, 4px)',
        'background-color': variable.whiteColor,
        'border-radius': '14px',
        padding: '8px',
        'box-shadow': `0 0 10px 10px ${variable.blackSuperTransparentColor}`,
        'z-index': '9'
    },
    dropdownWindowElem: {
        padding: '6px 16px',
        'border-radius': '8px',
        'background-color': variable.whiteColor,
        cursor: 'pointer'
    },
    dropdownWindowElemHover: {
        'background-color': variable.greyExtraLightColor,
        color: variable.blueColor
    },
    dropdownElemWrappers: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center',
            color: variable.whiteColor,
            padding: '8px 12px',
            'border-radius': '8px',
            'cursor': 'pointer'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            color: variable.whiteColor,
            padding: '5px 8px',
            'border-radius': '8px'
        }
    }),
    dropdownElemSvg: { transform: 'rotateZ(180deg)' },
    dropdownElemTitle: { 'margin-right': '5px' },
    sortIconSvg: {
        width: '24px',
        height: '24px',
        fill: variable.greySuperDarkColor,
        margin: '0 3px',
        'cursor': 'pointer'
    },
    sortIconSvgUp: { transform: 'rotateZ(180deg)' },
    sortIconSvgHover: { fill: variable.blueColor },
    btnAddMoreWrap: media(width, height, 'w', {
        768: {
            margin: '24px auto',
            width: '300px',
            'min-height': '48px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            padding: '12px 24px',
            border: `2px solid ${variable.darkExtraTransparentColor}`,
            'border-radius': '12px',
            cursor: 'pointer'
        },
        0: {
            margin: '24px auto',
            width: '213px',
            'min-height': '40px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            padding: '12px 16px',
            border: `2px solid ${variable.darkExtraTransparentColor}`,
            'border-radius': '12px',
            cursor: 'pointer'
        }
    }),
    btnAddHover: { 'background-color': variable.greyExtraLightColor },
    btnAddMoreText: {
        'text-align': 'center',
        'font-weight': '500',
        ...uikit.text,
        color: variable.blueColor
    },
    dFlex: { display: 'flex' },
    stickyCell: {
        'padding-bottom': '4px',
        'padding-top': '4px',
        'padding-left': 0,
        'padding-right': 0
    },
    markPrice: {
        background: variable.blueBackgroundLight,
        color: variable.blueColor,
        'border-radius': '4px',
        width: 'fit-content',
        padding: '4px 6px',
        'font-weight': 700,
        ...uikit.noWrap
    },
    dimentions: {
        background: variable.blueBackgroundLight,
        'border-radius': '4px',
        'padding': '4px 6px',
        'font-weight': 'bold',
        ...uikit.small,
        color: variable.blueColor
    },
    rangConteiner: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    },
    rangSvgBox: media(width, height, 'w', {
        768: {
            width: '50px',
            height: '50px',
            'border-radius': '50%',
            position: 'relative',
            'z-index': 2,
            'background': 'transparent',
            'text-align': 'center',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        },
        0: {
            width: '30px',
            height: '30px',
            'border-radius': '50%',
            position: 'relative',
            'z-index': 2,
            'background': 'transparent',
            'text-align': 'center',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        }
    }),
    rangSvgBoxInner: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            width: '40px',
            height: '40px',
            'background-color': variable.whiteColor,
            'border-radius': '40px'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            width: '32px',
            height: '32px',
            'background-color': variable.whiteColor,
            'border-radius': '32px'
        }
    }),
    rangSvgBoxTransparent: {
        width: '50px',
        height: '50px',
        'border-radius': '50%',
        position: 'relative',
        'z-index': 2,
        'background': variable.redBackground,
        'text-align': 'center',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    },
    rangName: media(width, height, 'w', {
        768: {
            padding: '10px 10px 10px 25px',
            'border-radius': '0% 8% 8% 0%',
            ...uikit.small,
            'line-height': '1.14',
            'font-weight': '500',
            height: '35px',
            left: '-20px',
            top: '8px',
            position: 'relative',
            'white-space': 'nowrap'
        },
        0: {
            padding: '0 10px 0 25px',
            'border-radius': '0% 8% 8% 0%',
            ...uikit.small,
            'line-height': '1.14',
            'font-weight': '500',
            height: '24px',
            left: '-20px',
            top: '2px',
            position: 'relative',
            'white-space': 'nowrap',
            display: 'flex',
            'align-items': 'center'
        }
    }),
    // rangName: {
    //     padding: '10px 10px 10px 25px',
    //     'border-radius': '0% 8% 8% 0%',
    //     ...uikit.small,
    //     'font-weight': '500',
    //     'line-height': '16px',
    //     height: '35px',
    //     left: '-20px',
    //     top: '8px',
    //     position: 'relative',
    //     'white-space': 'nowrap'
    // },
    rangConteinerLeft: { 'justify-content': 'flex-start' },
    rangConteinRight: { 'justify-content': 'flex-end' },
    rangModal: media(width, height, 'w', {
        768: {
            position: 'absolute',
            width: 0,
            overflow: 'hidden',
            transition: 'width .3s linear'
        },
        425: {
            position: 'absolute',
            width: 0,
            overflow: 'hidden',
            transform: 'translateX(-40px)'
        },
        0: {
            position: 'absolute',
            width: 0,
            overflow: 'hidden',
            transform: 'translateX(-60px)'
        }
    }),
    buttonContainerMain: {
        position: 'absolute',
        left: '-180px',
        padding: '10px',
        'border-radius': '12px',
        background: variable.whiteColor,
        'z-index': '10',
        'box-shadow': `0 0 50px 0 ${variable.blackColor}`
    },
    buttonContainer: {
        'min-width': '193px',

        'border-radius': '8px',
        ...uikit.text,

        display: 'flex',
        'align-items': 'center',
        'padding-left': '10px'
    },
    buttonContainerHover: {
        background: variable.greyExtraLightColor,
        cursor: 'pointer'
    }
});
export default stylesheets;