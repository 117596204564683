import { Div, svgSprite } from '../../../../../hybrid/wrappers';

const getClearIndicator = (styles, selectRef) => {
    const ClearIndicator = (props) => {
        const { clearValue } = props;
        return (
            <Div styles={{
                ...styles.formSelectClearIndicator, opacity: 'unset', 'margin-right': '15px'
            }} onClick={() => clearValue()} ref={selectRef}>
                {svgSprite('cross', {
                    width: 10, height: 10, fill: styles?.variable?.greyExtraDarkColor
                })}
            </Div>
        )
    }
    return ClearIndicator
}
export default getClearIndicator;