// @generated: @expo/next-adapter@2.1.5
import React, {
    useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { Div, svgSprite } from '../../../../hybrid/wrappers';
import InputItem from './InputItem';
import FormSelect from '../form-dropdown/FormSelect';
import UserContext from './../../../../common/utils/getContext'
import FormCheckbox from '../form-checkbox/FormCheckbox';
import { t } from '../../../../common/components/T';
import { getWidgetTranslates } from '../../../../common/utils/utils';
import { LangContext } from '../../../../common/decorators/Language';

const FormProductPrices = ({
    attribute,
    required,
    disabled,
    handlers = {},
    value,
    setFormData,
    styles,
    errors,
    options,
    currencies,
    marketing_currencies,
    defaultDataCurrency
}) => {
    const { lang } = useContext(UserContext)
    const [dataCurrency, setDataCurrency] = useState(defaultDataCurrency)
    const [mark, setMark] = useState(value && value.length > 0 && value[0].marketing_currency_id ? '0' : '1')
    const [transl, setTranslates] = useState(null)
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    const setMarkData = (attr, v) => {
        if (v === '0'){
            setFormData(attribute, value.map((item) => ({
                ...item,
                'marketing_currency_id': dataCurrency?.marketing_currency.value
            })))
        } else {
            setFormData(attribute, value.map((item) => {
                const accum = {};
                Object.keys(item).forEach((key) => {
                    if (key !== 'marketing_currency_id' && key !== 'scores_for_client'){
                        accum[key] = item[key]
                    }
                })
                return accum;
            }))
        }
        setMark(v)
    }

    const [disable, setDisable] = useState(false)
    const filterError = {}
    for (let key in errors) {
        const item = key.includes('prices')
        if (item) {
            filterError[key] = errors[key]
        }
    }
    let errorPrices = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    for (let key in filterError) {
        const id = Number(key.substring(7, 8))
        const value = key.substring(9)
        errorPrices[id][value] = filterError[key]
    }

    const addDiapason = () => {
        if (value.length > 10) {
            setDisable(true)
        } else {
            if (mark === '1') {
                setFormData(attribute, [...value, {
                    'currency_id': dataCurrency?.currency.value,
                    'product_count_from': '1',
                    'price_for_client': '',
                    'price_for_partner': ''
                }])
            } else if (mark === '0') {
                setFormData(attribute, [...value, {
                    'currency_id': dataCurrency?.currency.value,
                    'marketing_currency_id': dataCurrency?.marketing_currency.value,
                    'product_count_from': '1',
                    'price_for_client': '',
                    'scores_for_client': '',
                    'price_for_partner': ''
                    // "scores_for_partner": ''
                }])
            }
        }
    }
    const deleteDiapason = (idx, mark) => {
        if (mark) {
            const oldArr = value.filter(el => el.currency_id !== dataCurrency?.currency.value)
            const newArr = value.filter(el => el.currency_id === dataCurrency?.currency.value).filter(el => el.marketing_currency_id === dataCurrency?.marketing_currency.value)
            newArr.splice(idx, 1)
            setFormData(attribute, [...oldArr, ...newArr])
        } else {
            const oldArr = value.filter(el => el.currency_id !== dataCurrency?.currency.value)
            let newArr = value.filter(el => el.currency_id === dataCurrency?.currency.value)
            newArr.splice(idx, 1)
            setFormData(attribute, [...oldArr, ...newArr])
        }
    }
    let modernOptions = []
    if (mark === '0') {
        modernOptions = options
    } else {
        options.map((opt) => {
            if (opt.alias === 'product_count_from' || opt.alias === 'price_for_client' || opt.alias === 'price_for_partner') {
                modernOptions.push(opt)
            }
        })
    }

    return (
        <Div>
            <Div styles={styles.formProductPricesHeaderWrapper}>
                <Div styles={styles.formProductPricesHeaderItem}>
                    <FormSelect
                        compStyle={{
                            wrapper: {
                                ...styles.formProductPricesWrapper,
                                'justify-content': 'flex-start !important'
                            }
                        }}
                        type='dropdown'
                        attribute='currency_id'
                        default_value='1'
                        disabled={false}
                        hint={null}
                        options={currencies || []}
                        placeholder={t(
                            'form-ChooseCurrency', 'Выберите валюту', 'widget', transl, 'form'
                        )}
                        required={false}
                        title={{
                            body: t(
                                'form-currency', 'Валюта', 'widget', transl, 'form'
                            )
                        }}
                        setFormData={setFormData}
                        handlers={{
                            onChange: (e) => {
                                // const filteredValue = value?.filter((v) => v.currency_id !== e.value && (v?.price_for_client || v?.price_for_partner || v?.scores_for_client))
                                // setFormData(attribute, filteredValue)
                                setDataCurrency({
                                    ...dataCurrency,
                                    currency: e
                                })
                            }
                        }}
                        errors={errors['prices']}
                        value={dataCurrency?.currency}
                    />
                </Div>
                <Div>
                    <FormSelect
                        compStyle={{
                            wrapper: {
                                ...styles.formProductPricesWrapper,
                                'justify-content': 'flex-start !important'
                            }
                        }}
                        attribute='marketing_currency_id'
                        type='dropdown'
                        default_value='1'
                        disabled={true}
                        hint={null}
                        options={marketing_currencies || []}
                        placeholder={t(
                            'form-MarketingChooseCurrency', 'Выберите маркетинговую валюту', 'widget', transl, 'form'
                        )}
                        required={false}
                        title={{
                            body: t(
                                'form-MarketingCrrency', 'Маркетинговая валюта', 'widget', transl, 'form'
                            )
                        }}
                        setFormData={setFormData}
                        handlers={{
                            onChange: (e) => setDataCurrency({
                                ...dataCurrency,
                                marketing_currency: e
                            })
                        }}
                        errors={mark === '0' ? errors['prices'] : false}
                        isClear={false}
                        value={dataCurrency?.marketing_currency}
                    />
                </Div>
                <Div>
                    <FormCheckbox
                        compStyle={{ wrapper: { ...styles.formProductPricesWrapper } }}
                        attribute='mark_check'
                        type='checkbox'
                        disabled={defaultDataCurrency?.marketing_currency?.value == undefined}
                        hint={null}
                        required={true}
                        title={{
                            body: t(
                                'form-MarketingPrice', 'Маркетинговая стоимость не используется для этого товара', 'widget', transl, 'form'
                            )
                        }}
                        setFormData={setMarkData}
                        handlers={{}}
                        value={mark}
                    />
                </Div>
            </Div>
            <Div styles={{ ...styles.formProductPrices }}>
                {/* Web */}
                <Div styles={{ ...styles.formProductPricesColTabs }}>
                    {
                        modernOptions.map((option, idx) => {
                            return (
                                <Div styles={{ ...styles.formProductPricesColTabsItem }} key={`option${idx}`}>{option.title}</Div>
                            )
                        })
                    }
                </Div>
                {
                    value && dataCurrency?.currency && mark === '1' ? value.filter(e => e.currency_id === dataCurrency?.currency.value).map((item, index) =>
                        <Div key={`item${index}`} styles={{ ...styles.formProductPricesCol }}>
                            {!!index && <Div
                                styles={styles.formProductPricesColClose}
                                data-uitest='575_uitest' onClick={() => deleteDiapason(index)}
                            >
                                {
                                    svgSprite('cross', { style: styles.formCheckboxSvg })
                                }
                            </Div>}
                            {modernOptions.map((option, idx) => {
                                return (
                                    <InputItem
                                        value={value}
                                        key={`optionss${idx}`}
                                        attribute={attribute}
                                        styles={styles}
                                        setFormData={setFormData}
                                        handlers={handlers}
                                        required={required}
                                        disabled={disabled}
                                        errors={ Object.keys(errorPrices[index]).length !== 0 ? errorPrices[index][option.alias] : []}
                                        option={option}
                                        index={index}
                                        idx={idx}
                                        currency={dataCurrency?.currency}
                                        setDataCurrency={setDataCurrency}
                                    />
                                )
                            })}
                        </Div>) : value && dataCurrency?.currency && dataCurrency?.marketing_currency ? value.filter(e => e.currency_id === dataCurrency?.currency.value).map((item, index) =>
                        <Div key={`item${index}`} styles={{ ...styles.formProductPricesCol }}>
                            {!!index && <Div
                                styles={styles.formProductPricesColClose}
                                data-uitest='576_uitest' onClick={() => deleteDiapason(index, true)}
                            >
                                {
                                    svgSprite('cross', { style: styles.formCheckboxSvg })
                                }
                            </Div>}
                            {modernOptions.map((option, idx) => {
                                return (
                                    <InputItem
                                        value={value}
                                        key={`optionss${idx}`}
                                        attribute={attribute}
                                        styles={styles}
                                        setFormData={setFormData}
                                        handlers={handlers}
                                        required={required}
                                        disabled={disabled}
                                        errors={ Object.keys(errorPrices[index]).length !== 0 ? errorPrices[index][option.alias] : []}
                                        option={option}
                                        index={index}
                                        idx={idx}
                                        currency={dataCurrency?.currency}
                                        setDataCurrency={setDataCurrency}
                                    />
                                )
                            })}
                        </Div>) :
                        <Div styles={{ ...styles.formProductPricesCol }}>
                            {modernOptions.map((option, idx) => {
                                return (
                                    <InputItem
                                        value={[]}
                                        key={`optionss${idx}`}
                                        attribute={attribute}
                                        styles={styles}
                                        setFormData={setFormData}
                                        handlers={handlers}
                                        required={required}
                                        disabled={true}
                                        //   errors={ Object.keys(errorPrices[index]).length !== 0 ? errorPrices[index][option.alias] : []}
                                        option={option}
                                        index={1}
                                        idx={idx}
                                        currency={dataCurrency?.currency}
                                        setDataCurrency={setDataCurrency}
                                    />
                                )
                            })}
                        </Div>
                }
                {value && dataCurrency?.currency && mark === '1' ? <Div
                    styles={styles.formProductPricesAdd}
                    data-uitest='577_uitest' onClick={disable ? () => {} : () => addDiapason()}
                >
                    {svgSprite('plus', {
                        width: 14, height: 14, fill: styles.variable.blueColor
                    })}
                </Div> : value && dataCurrency?.currency && dataCurrency?.marketing_currency ? <Div
                    styles={styles.formProductPricesAdd}
                    data-uitest='578_uitest' onClick={disable ? () => {} : () => addDiapason()}
                >
                    {svgSprite('plus', {
                        width: 14, height: 14, fill: styles.variable.blueColor
                    })}
                </Div> : <></>}


            </Div>
        </Div>
    );
};

FormProductPrices.propTypes = {
    attribute: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    handlers: PropTypes.object,
    value: PropTypes.array,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    errors: PropTypes.array,
    options: PropTypes.array,
    currencies: PropTypes.array,
    marketing_currencies: PropTypes.array,
    defaultDataCurrency: PropTypes.object
};

export default Styles(FormProductPrices, getStyles);