import React, {
    useState, useCallback, useMemo
} from 'react'
import { Div, svgSprite } from '../../../../../hybrid/wrappers'
import SearchWindow from '../../../../searchWindow/SearchWindow';
import { useWindowDimensions } from '../../../../../common/utils/windowDimensions';

const ModalSeachType = ({
    styles,
    changeHandler,
    search = {}
}) => {
    const {
        icon, title, url, attrToInput
    } = search;
    const [hover, setHover] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const afterOpenSwitherClick = () => {
        setShowModal(false)
        return true
    }
    const getUserData = useCallback(async (value) => {
        changeHandler(String(value))
    }, [changeHandler]);

    const widthDimensions = useWindowDimensions();
    const screenWidth = useMemo(() => widthDimensions < 992,
        [widthDimensions]);

    return (
        <>
            <Div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowModal(true)
                }}
                styles={styles.formControlIconWrap}
            >
                {svgSprite(icon, {
                    style: hover ? { ...styles.formControlLoginSvg, ...styles.formControlLoginSvgHover }:
                        styles.formControlLoginSvg
                })}
            </Div>

            {
                showModal &&
                <SearchWindow
                    sendData={{ url }}
                    fixedWidth={!screenWidth ? { width: '1000px' } : {}}
                    portalClass={!screenWidth ? 'modalTop' : 'modalBottom'}
                    closeModal={setShowModal}
                    title={title}
                    gridHandlers={{
                        tr: {
                            onClick: (rowData) => {
                                if (rowData[attrToInput]) {
                                    getUserData(rowData[attrToInput]);
                                    afterOpenSwitherClick(rowData, 'new');
                                }
                            }
                        }
                    }}
                    customData={{ buttonNotShow: true }}
                    gridConfig={{
                        stickyCell: { 'background-color': 'transparent' },
                        td: {
                            styles: styles.usersGridTd,
                            div: styles.usersGridSell
                        },
                        0: { td: { div: styles.usersGridSellFirst } },
                        2: { td: { div: styles.usersGridSellLast } }
                    }}
                    customStyles={styles}
                />

            }
        </>
    )
}
export default ModalSeachType