import { media } from '../../../common/utils/utils';
import officeStyles from './office/share-styles'
import mainStyles from './main/share-styles'

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    modalShareBody: {
        width: '122px',
        display: 'flex',
        'flex-direction': 'column',
        position: 'absolute',
        background: variable.whiteColor,
        'border-radius': '14px',
        'box-shadow': `0 10px 100px 0 ${variable.blackTransparentColor}`,
        'z-index': 99
    },
    modalShareBodyBackground: {
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        'z-index': 9
    },
    modalShareSocialSvg: {
        width: '30px',
        height: '30px'
    },
    modalShare: {
        display: 'flex',
        'flex-direction': 'column',
        'flex-wrap': 'wrap',
        position: 'relative'
    },
    modalShareItemHover: { background: variable.greyExtraDarkTransparentColor },
    modalShareItemWrapper: {
        width: '100%',
        'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
        height: '44px'

    },
    modalShareItemWrapperLast: {
        border: 'none',
        'border-radius': '0 0 14px 14px'
    },
    modalShareItemWrapperFirst: { 'border-radius': '14px 14px 0 0' },
    modalShareItem: {
        display: 'flex',
        'align-items': 'center',
        height: '44px'

    },
    modalShareItemIcon: {
        height: '40px',
        width: '40px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
    },
    shareDecor: media(width, height, 'w', {
        768: { display: 'none' },
        0: {
            background: variable.greyExtraLightColor,
            'border-radius': '100px',
            'margin-bottom': '20px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            width: '32px',
            height: '4px'
        }
    }),
    btnShare: {
        display: 'flex',
        'align-items': 'center',
        'cursor': 'pointer',
        position: 'relative'
    },
    btnShareTitle: { 'margin-left': '6px' },
    modalShareBodyBottom: {
        'margin-top': '20px',
        left: '-54px',
        top: '100%'
    },
    modalShareBodyLeft: {
        right: '-150px',
        'margin-right': '20px',
        bottom: '22px'
    },
    modalShareBodyRight: {
        right: '100%',
        'margin-right': '20px',
        bottom: '0'
    },
    dropdownWhiteArrowRight: {
        top: '8px',
        left: '-12px',
        transform: 'rotate(180deg)'
    },
    dropdownWhiteArrowLeft: { top: '8px', right: '-12px' },
    dropdownWhiteArrowBottom: {
        transform: 'rotateZ(-91deg)',
        top: '-27px',
        right: 'calc(50% - 21px)'
    }
});
export default stylesheets;