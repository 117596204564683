import React, {
    useMemo, useState, useRef, useEffect
} from 'react';
import {
    Portal, Div, svgSprite, Tooltip
} from '../../../hybrid/wrappers';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import PropTypes from 'prop-types';

const WrapperButton = (props) => {
    if (props.hint){
        return (
            <Div {...props}>
                <Tooltip text={props.hint} styles={{ 'display': 'flex', 'align-items': 'center' }}>{props.children}</Tooltip>
            </Div>
        )
    }
    return <Div {...props}>{props.children}</Div>
}

const Buttons = ({
    tbodyRowIndexDel, gridStyles, getHandlers, styles, val, name
}) => {
    const ref = useRef(false)

    const handlers = getHandlers()

    const [showModal, setShowModal] = useState(false);
    const widthDimensions = useWindowDimensions();
    const mobile = useMemo(() => widthDimensions < 768, [widthDimensions]);
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (showModal && ref.current && !ref.current.contains(e.target)) {
                setShowModal(false)
            }
        }

        document.addEventListener('click', checkIfClickedOutside)
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [showModal])

    return (
        <Div
            ref={ref}
            styles={gridStyles[name]?.wrapper?.styles || {
                ...styles.flexRow, ...styles.alignItemsCenter, ...styles.justifyContentCenter
            }}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >

            {
                handlers?.createId
                &&
                    <Div
                        {...getHandlers('createId')}
                        styles={gridStyles[name]?.destroyIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.destroyIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }
                        }
                    >
                        {
                            svgSprite('folder-plus',
                                gridStyles[name]?.destroyIco?.styles || { style: { ...styles.tableButtonIcon, ...styles.tableButtonIconNew } })
                        }
                    </Div>

            }
            {
                val.comment
                &&
                    <>
                        {
                            mobile ?

                                val.comment.modal && val.comment.modal.show ?
                                    <Portal closeHandler={val.comment.closeModal}>
                                        <val.commentModal val={val}/>
                                    </Portal> : null
                                :
                                <>
                                    <val.commentModal val={val}/>
                                </>
                        }

                        <Div
                            {...getHandlers('comment')}
                            styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                            effects={gridStyles[name]?.updateIcoWrap?.effects || {
                                hover: styles.tableButtonHover,
                                focus: styles.tableButtonActive,
                                active: styles.tableButtonActive
                            }
                            }
                        >
                            {
                                svgSprite(!val?.comment?.value ? 'comment-add' : 'comment-lines',
                                    gridStyles[name]?.updateIco?.styles || { fill: !val?.comment?.value ? [styles.variable.greyExtraDarkColor, styles.variable.blueColor] : styles.variable.blueColor, style: styles.tableButtonIcon })
                            }
                        </Div>
                    </>


            }
            {
                val.data && Object.keys(val.data).length
                    ? Object.keys(val.data).map((key) =>

                        <WrapperButton
                            {...getHandlers(key)}
                            key={`${tbodyRowIndexDel}${key}`}
                            styles={{
                                ...gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb },
                                ...gridStyles[name]?.[key]?.styles || {},
                                ...val.data[key]?.variation ? gridStyles[name]?.[key]?.[val.data[key]?.variation]?.styles || {} : {}
                            }}
                            effects={gridStyles[name]?.updateIcoWrap?.effects || gridStyles[name]?.[key]?.effects || {
                                hover: styles.tableButtonHover,
                                focus: styles.tableButtonActive,
                                active: styles.tableButtonActive
                            }}
                            hint={val.data[key].hint || false}
                        >
                            {
                                val.data[key].icon ?
                                    svgSprite(val.data[key].icon, {
                                        ...styles.tableButtonIcon,
                                        ...gridStyles[name]?.[key]?.iconStyles,
                                        ...val.data[key]?.variation ? gridStyles[name]?.[key]?.[val.data[key]?.variation]?.iconStyles || {} : {}
                                    })
                                    : null
                            }
                        </WrapperButton>) : null
            }
            {
                val.view
                &&
                    <WrapperButton
                        {...getHandlers('view')}
                        styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.updateIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }
                        }
                        hint={val.view.hint || false}
                    >
                        {
                            svgSprite(val.view.active ? 'eye-slash' : 'eye',
                                gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                        }
                    </WrapperButton>

            }
            {
                val.update
                &&
                    <WrapperButton
                        {...getHandlers('update')}
                        styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.updateIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }
                        }
                        hint={val.update.hint || false}

                    >
                        {
                            svgSprite(val.updateIcon || 'pen-field',
                                gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                        }
                    </WrapperButton>

            }

            {
                val?.destroy
                &&
                    <WrapperButton
                        {...getHandlers('destroy')}
                        styles={gridStyles[name]?.destroyIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.destroyIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }
                        }
                        hint={val.destroy.hint || false}
                    >
                        {
                            svgSprite('trash',
                                gridStyles[name]?.destroyIco?.styles || { style: { ...styles.tableButtonIcon, ...styles.tableButtonIconDelete } })
                        }
                    </WrapperButton>

            }

        </Div>
    );
};

Buttons.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    styles: PropTypes.object,
    name: PropTypes.string
};

export default Buttons;