import React, {
    useState, useEffect, useRef, useContext
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { getGeoData, getGeoName } from '../../../../common/utils/memo';
import { API_SERVER_URL } from '../../../../common/utils/utils';
import FormSelect from './FormSelect'
import UserContext from '../../../../common/utils/getContext';

const countries = [0, 'country_id', 'delivery_country_id', 'country']
const states = [0, 'region_id', 'delivery_state_id']
const cities = [0, 'city_id', 'delivery_city_id']
const getIsCountry = (a) => {
    if (countries.indexOf(a) !== -1){
        return countries.indexOf(a)
    } else {
        return false
    }
}
const getIsState = (a) => {
    if (states.indexOf(a) !== -1){
        return states.indexOf(a)
    } else {
        return false
    }
}
const getIsCity = (a) => {
    if (cities.indexOf(a) !== -1){
        return cities.indexOf(a)
    } else {
        return false
    }
}
const getDisabled = (attribute, formData) => {
    if (getIsCountry(attribute)) return false;
    if (getIsState(attribute)){
        return !Boolean(formData[countries[getIsState(attribute)]])
    }
    if (getIsCity(attribute)){
        return !Boolean(formData[states[getIsCity(attribute)]])
    }
}
const FormDropdown = (props) => {
    const [options, setOptions] = useState(false)
    const [value, setValue] = useState(props.value)
    const nextPage = useRef({ value: '' })
    const searchQ = useRef({ value: '' })

    const [load, setLoad] = useState(false)
    const [prevValue, setPrevValue] = useState(getIsState(props.attribute) ? props.formData.country_id : getIsCity(props.attribute) ? props.formData.region_id : false)

    const { userToken, lang } = useContext(UserContext);

    useEffect(() => {
        if (getIsState(props.attribute) && props.formData[countries[getIsState(props.attribute)]] !== prevValue){
            setOptions(false)
            setPrevValue(props.formData[countries[getIsState(props.attribute)]])
        } else if (getIsCity(props.attribute) && props.formData[states[getIsCity(props.attribute)]] !== prevValue){
            setOptions(false)
            setPrevValue(props.formData[states[getIsCity(props.attribute)]])
            if (nextPage.current){ nextPage.current.value = null }
            if (searchQ.current){ searchQ.current.value = null }
        }
    }, [prevValue, props.attribute, props.formData])
    useEffect(() => {
        setValue(props.value)
    }, [props.value])
    useEffect(() => {
        (async () => {
            if (props?.value?.value){
                getGeoName(props?.value?.value, lang)
                    .then((res) => {
                        setValue({
                            ...props?.value,
                            label: res?.data?.name
                        })
                    })
            }
        })();
    }, [lang, props?.value, userToken])

    const getStates = async (q, page, next, callback) => {
        let url = getIsCountry(props.attribute)
            ? `${API_SERVER_URL}/api/v1/geonames/countries`
            : getIsState(props.attribute)
                ? props.formData[countries[getIsState(props.attribute)]]?.links?.states_of_country || `${API_SERVER_URL}/api/v1/geonames/states`
                : props.formData[states[getIsCity(props.attribute)]]?.links?.cities_of_state || `${API_SERVER_URL}/api/v1/geonames/cities`;
        const data = await getGeoData(url + '?page[size]=10' + (next ? '&page[number]=' + next : '') + (q ? '&filter[q]=' + q : ''), userToken, lang)

        setOptions((pre) => {
            if (pre && page) {
                return [...pre, ...data?.data?.map((item) => ({
                    ...item, title: item.name, id: item.geoname_id
                }))]
            } else {
                return data?.data?.map((item) => ({
                    ...item, title: item.name, id: item.geoname_id
                }))
            }
        })
        if (data?.meta?.current_page < data?.meta?.last_page){
            if (nextPage.current){ nextPage.current.value = data?.meta?.current_page+1 }
        } else {
            if (nextPage.current){ nextPage.current.value = null }
        }
        if (q){
            if (searchQ.current){ searchQ.current.value = q }
        } else {
            if (searchQ.current){ searchQ.current.value = null }
        }
        if (callback){
            callback(data?.data?.map((item) => ({
                ...item, label: item.name, value: item.geoname_id
            })))
        }
    }
    const searchHandler = async (e, callback) => {
        if (getIsCity(props.attribute)){
            await getStates(e, false, false, callback)
        }
    }
    const loadOptions = async () => {
        if (!options && !load){
            setLoad(true)
            await getStates('', false, 1)
            setLoad(false)
        }
    }
    const onMenuScrollToBottom = async (e, q) => {
        if (e.current?.value){
            setLoad(true)
            await getStates(q.current?.value, true, e.current?.value)
            setLoad(false)
        }
    }
    const changeHandler = (e, attribute) => {
        if (getIsCountry(attribute) && states[getIsCountry(attribute)] && cities[getIsCountry(attribute)]) {
            if (e) {
                props.setFormData(states[getIsCountry(attribute)], null)
                props.setFormData(cities[getIsCountry(attribute)], null)
                props.setFormData(attribute, e)
            } else {
                props.setFormData(states[getIsCountry(attribute)], null)
                props.setFormData(cities[getIsCountry(attribute)], null)
                props.setFormData(attribute, null)
            }
        } else if (getIsState(attribute) && cities[getIsState(attribute)] && cities[getIsState(attribute)]) {
            if (e) {
                props.setFormData(cities[getIsState(attribute)], null)
                props.setFormData(attribute, e)
            } else {
                props.setFormData(cities[getIsState(attribute)], null)
                props.setFormData(attribute, null)
            }
        } else {
            if (e) {
                props.setFormData(attribute, e)
            } else {
                props.setFormData(attribute, null)
            }
        }
    }

    useEffect(() => {
        if (props.preparedLoad) {
            loadOptions()
        }
    // eslint-disable-next-line
    }, [props.preparedLoad])

    const disabled = getDisabled(props.attribute, props.formData)
    return <><input type='hidden' ref={nextPage}/><input type='hidden' ref={searchQ}/><FormSelect
        {...props}
        disabled={disabled}
        options={options}
        isClear={true}
        value={value}
        isLoading={load}
        loadOptions={getIsCity(props.attribute) ? searchHandler : false}
        handlers={{
            ...props.handlers || {},
            onChange: (e, a) => { changeHandler(e, a) },
            onMenuOpen: async (a) => { await loadOptions(a) },
            onMenuScrollToBottom: async () => { await onMenuScrollToBottom(nextPage, searchQ) }
        }}
    /></>
};

FormDropdown.propTypes = {
    attribute: PropTypes.string,
    setFormData: PropTypes.func,
    handlers: PropTypes.object,
    formData: PropTypes.object
};

export default Styles(FormDropdown, getStyles);