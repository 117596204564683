import React, { useEffect, useState } from 'react';
import { Div, FieldError } from '../../hybrid/wrappers';

import ReCAPTCHA from 'react-google-recaptcha';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';

const Captcha = ({
    attribute,
    site_key,
    errors,
    styles,
    compStyle,
    value,
    tryCount,
    version,
    setFormData,
    attempts
}) => {
    const [visible, setVisible] = useState(value.attribute !== 'user::auth' && !!+value.value);

    function onChange(value) {
        if (value) {
            setFormData(attribute, value);
        }
    }

    useEffect(() => {
        if (!!errors?.length || errors?.message) {
            if (typeof window !== 'undefined') {
                if (version === 'reCaptcha_v2' && value?.value == 1 && window.grecaptcha) {
                    window.grecaptcha.reset();
                }
            };
        }
    }, [errors, value, version]);

    useEffect(() => {
        if (tryCount <= attempts) {
            if (value.attribute === 'user::auth' && !!+value.value) {
                setVisible(true);
            }
        }
    }, [attempts, tryCount, value]);

    if (!site_key || !visible) return null;
    return (
        <Div styles={{ 'margin-bottom': '24px' }}>
            {version === 'reCaptcha_v3'
                ?
                <GoogleReCaptchaProvider reCaptchaKey={site_key} >
                    <GoogleReCaptcha onVerify={onChange} />
                </GoogleReCaptchaProvider>
                : version === 'reCaptcha_v2' ?
                    <ReCAPTCHA
                        sitekey={site_key}
                        onChange={onChange}
                    />
                    : ''
            }
            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    );
}

Captcha.propTypes = {
    setFormData: PropTypes.func,
    attribute: PropTypes.string,
    site_key: PropTypes.string,
    errors: PropTypes.array,
    styles: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.object,
    captchaCount: PropTypes.number,
    tryCount: PropTypes.number,
    version: PropTypes.oneOf(['reCaptcha_v3', 'reCaptcha_v2'])
};
export default React.memo(Captcha);