import React, { useState, useEffect } from 'react'
import { Div } from '../../../hybrid/wrappers'
import FormCheckbox from '../../form/components/form-checkbox/FormCheckbox'

const ActiveRow = ({
    val,
    getHandlers,
    styles
}) => {
    const [active, setActive] = useState(val ? '1' : '0')
    const handlers = getHandlers('change')
    useEffect(() => {
        setActive(val ? '1' : '0');
    }, [val]);


    return (
        <>
            {
                val === 'disabled' ?
                    <Div styles={{
                        'pointer-events': 'none',
                        'opacity': '1',
                        width: '20px',
                        height: '20px',
                        'border': `1px solid ${styles.variable.greyMainColor}`,
                        'border-radius': '6px',
                        'background-color': styles.variable.greySuperLightColor
                    } }>
                    </Div>:
                    <FormCheckbox
                        setFormData={() => {}}
                        handlers={handlers}
                        value={active}
                        reload={active}
                        compStyle={{
                            wrapper: { 'margin-bottom': '0px !important', width: 'auto' },
                            label: { 'margin-bottom': '0px' }
                        }}
                    />
            }
        </>
    )
}
export default ActiveRow