import React, {useEffect, useState} from 'react'
import UserContext from '../common/decorators/UserContext';
import { withRouter } from 'next/router';
import '../project/stylesToApp.scss';
import 'react-dropdown/style.css';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import LogoutLayout from '../widgets/autoLogout/AutoLogout';
import { zendoStorage } from "../hybrid/wrappers";
import { checkIsPartner } from '../common/utils/memo';
import { dataSender } from '../common/utils/router';
import { API_SERVER_URL } from '../common/utils/utils';

const App = ({ Component, pageProps }) => {
    const [value, setValue] = useState(false)
    useEffect(() => {
        if (typeof window !== undefined) {
            setValue(window)
        }
    }, [])

    const token = zendoStorage.get('userToken')

    const [userData, setUserData] = useState(false)

    useEffect(() => {
        if (!token) {
            return
        } else {
            (async() => {
                let data;
                checkIsPartner.cache.clear()
                data = await checkIsPartner(token, pageProps?.lang)
                if (data?.data?.data && typeof window !== undefined) {
                    window.fcWidgetMessengerConfig = {
                        externalId: data?.data?.data?.username,
                        restoreId: data?.data?.data?.restore_id
                    }
                    setUserData(data?.data?.data)
                }
            })()
        }
    }, [token, pageProps?.lang])

    // useEffect(() => {
    //     if (typeof window !== undefined && userData?.username && userData?.restore_id) {
    //         window.fcWidgetMessengerConfig = {
    //             // externalId: userData?.username,
    //             // restoreId: userData?.restore_id,
    //         }
    //     }
    // }, [userData])


    // useEffect(() => {
    //     if (typeof window !== undefined) {
    //         if (!value?.fcWidget) {
    //             const script = document.createElement('script');
    //             script.src = '//eu.fw-cdn.com/12525179/567157.js';
    //             script.chat = true;
    //             document.body.appendChild(script);    
    //             console.log('GERE');
    //         } else if (token && userData && window.fcWidgetMessengerConfig?.externalId && value?.fcWidget) {
    //             (async () => {
    //                 window.fcWidget.destroy()
    //                 setTimeout(() => {
    //                     const script = document.createElement('script');
    //                     script.src = '//eu.fw-cdn.com/12525179/567157.js';
    //                     script.chat = true;
    //                     document.body.appendChild(script);   
    //                 }, 8000)   
    //                 console.log(window, 'WINDOW');
    //                 const {first_name, last_name, contacts: {email, phone}} = userData

    //                 window.fcWidget.user.get(function(resp) {   
    //                     console.log('HERE')
    //                     var status = resp && resp.status,
    //                     data = resp && resp.data;
    //                     console.log(resp, 'RESPONSE');
    //                     if (status !== 200) {
    //                     window.fcWidget.user.setProperties({
    //                         firstName: first_name,
    //                         lastName: last_name,
    //                         email: email,
    //                         phone: phone,
    //                     });
    //                     window.fcWidget.on('user:created', function(resp) {
    //                         var status = resp && resp.status,
    //                             data = resp && resp.data;
    //                         console.log(resp, 'respinner');
    //                         if (status === 200) {
    //                             if (data.restoreId) {
    //                                 //undate restore id
    //                                 (async() => {
    //                                     dataSender(
    //                                         `${API_SERVER_URL}/api/v1/user/profile/restore-chat`,
    //                                         'POST',
    //                                         {
    //                                             'restore_id': data.restoreId
    //                                         },
    //                                         () => {},
    //                                         () => {},
    //                                         token,
    //                                         'en'
    //                                     )
    //                                 })()
    //                             }
    //                         }
    //                     });
    //                     }
    //                 });
                    
    //             })()
    //         } else if (!token) {
    //             setUserData(false)
    //             window.fcWidget.user.clear()
    //         }
    //     }
    // }, [value?.fcWidget, token, pageProps, userData])


    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//eu.fw-cdn.com/12525179/567157.js';
        script.chat = true;
        script.async = true;
        document.body.appendChild(script);
        // if (!token && value?.fcWidget) {
        //     value.fcWidget.user.clear()
        // }
        if (typeof window !== undefined && value?.fcWidget && userData && token) {
            console.log('HERE');
            (async() => {
                // value.fcWidget.destroy()
                

                const {first_name, last_name, contacts: {email, phone}} = userData
                value.fcWidget.user.get(function(resp) {   
                var status = resp && resp.status,
                data = resp && resp.data;
                console.log(resp, 'RESPONSE');
                if (status !== 200) {
                value.fcWidget.user.setProperties({
                    firstName: first_name,
                    lastName: last_name,
                    email: email,
                    phone: phone,
                });
                value.fcWidget.on('user:created', function(resp) {
                    var status = resp && resp.status,
                        data = resp && resp.data;
                    console.log(resp, 'respinner');
                    if (status === 200) {
                        if (data.restoreId) {
                            //undate restore id
                            (async() => {
                                dataSender(
                                    `${API_SERVER_URL}/api/v1/user/profile/restore-chat`,
                                    'POST',
                                    {
                                        'restore_id': data.restoreId
                                    },
                                    () => {},
                                    () => {},
                                    token,
                                    'en'
                                )
                            })()
                        }
                    }
                });
                }
            });

            })()
        }
        return () => {
            if (value?.fcWidget) {
                value?.fcWidget.destroy()
            }
        }
    }, [token, value?.fcWidget, userData])

    return (
        <Component {...pageProps} />
    )
}

export default withRouter(UserContext(App));
