import { useState, useContext, useCallback, useEffect, useMemo} from 'react';
import FormInput from '../form-input/FormInput';
import { Div, BlankLink } from '../../../../hybrid/wrappers';
import T from '../../../../common/components/T';
import { dataSender } from '../../../../common/utils/router';
import { API_SERVER_URL } from '../../../../common/utils/utils';
import UserContext from '../../../../common/utils/getContext';


const PhoneSender = ({setFormData, attribute, urlConfirmation, urlSend, styles, fieldsErr}) => {
    const [disablePhoneInput, setDisablePhoneInput] = useState(false) //дизейблим виджет телефона, когда запрос отправление кода срабатывает
    const [sendData, setSendData] = useState(false) //Данные из виджета телефона для отправки запроса на полечение кода
    const [showCodeConfirm, setShowCodeConfirm] = useState(false) // Если запрос на получение кода срабатывает - выводим вторую форму для подтверждения кода
    const [code, setCode] = useState(false) //стейт для введеного кода
    const [successCode, setSuccessCode] = useState(false) //стейт удачного подтверждения кода
    const [errors, setErrors] = useState(false) //стейт для ошибки кода
    const [errorsPhone, setErrorsPhone] = useState(false) //стейт для ошибки номера телефона

    const outerErrors = useMemo(() => fieldsErr?.phone ?? [], [fieldsErr])
    const outerErrorsCode = useMemo(() => fieldsErr?.code ?? [], [fieldsErr])

    useEffect(() => {
        if (outerErrors?.length > 0) {
            setErrorsPhone(outerErrors)
        }
        if(!outerErrors?.length) {
            setErrorsPhone(false)
        }
        if (outerErrorsCode?.length > 0) {
            setErrors(outerErrorsCode)
        }
    }, [outerErrors,outerErrorsCode])

    const { lang, setAlertData } = useContext(UserContext);

    const [showInfoMessage, setIsShowInfoMessage] = useState(false)

    const getCodeREstrictions = () => {
        setDisablePhoneInput(true)
        setShowCodeConfirm(true)
        //Эта функция дизейблит основную кнопку "Получить код" потмоу что в кнопке "Отправить код повторно" будет использоваться та же функция sendPhoneHandler
    }

    const sendPhoneHandler = (isSendAgain) => {
        dataSender(
            `${API_SERVER_URL}${urlSend}`,
            'POST',
            { phone: sendData ? `+${sendData}` : null },
            () => {
                !isSendAgain && getCodeREstrictions()
                isSendAgain && setIsShowInfoMessage(true)
                setErrors(false)
                setErrorsPhone(false)
                setAlertData({
                    type: 'success',
                    cont: <T textName={'send-phone-code-success-new'} defaultStr={'Код успешно отправлен'} page='/authentication' />
                });
            },
            (res) => {
                setAlertData({type: 'error', cont: res?.errors?.phone || res?.message});
                if (typeof res === 'object' && res?.errors?.phone) {
                    setErrorsPhone(Object.values(res?.errors?.phone))
                }
            },
            false,
            lang
        )
    }

    const changeNumberHandler = () => {
        setDisablePhoneInput(false)
        setShowCodeConfirm(false)
        setErrors(false)
        setErrorsPhone(false)
        //функция для кнопки "Изменить номер" - убираем нижний блок и делаем поле доступным для ввода номера
    }

    const confirmCode = () => {
        dataSender(
            `${API_SERVER_URL}${urlConfirmation}`,
            'POST',
            {
                code: code ? code : null,
                phone: sendData ? `+${sendData}` : null
            },
            () => {
                setSuccessCode(true)
                setErrors(false)
                setAlertData({
                    type: 'success',
                    cont: <T textName={'code-successed-accept'} defaultStr={'Номер успешно подтвержден'} page='/authentication' />
                });
            },
            (res) => {
                setAlertData({
                    type: 'error',
                    cont: res?.errors?.code
                });
                if (typeof res === 'object' && res?.errors?.code) {
                    setErrors(Object.values(res?.errors?.code))
                }
            },
            false,
            lang
        )
    }
    
    return (
        <Div>
            <FormInput 
                compStyle={{
                    edit: {
                        buttonWrapper: disablePhoneInput || !sendData ? {'pointer-events': 'none'} : {},
                        button: styles.confirmPhoneButton,
                    }
                }}
                attribute={attribute}
                type='phone-widget'
                setFormData={setFormData}
                title={{ body: <T textName='register-fields-phone-title-new' defaultStr='Введите номер телефона' page='/authentication' /> }}
                buttons={{
                    edit: {   
                        name: <T textName={'confirm-phone-btnText-new'} defaultStr={'Получить код'} page='/authentication' />,
                        handler: () => {
                            sendPhoneHandler()
                        },
                        hint: <T textName='confirm-phone-btn-new-2' defaultStr='Подтверджение номера телефона' page='/authentication' />
                    }
                }}
                disabled={disablePhoneInput}
                setSendData={setSendData}
                errors={errorsPhone}
                required={true}
            />
            {showCodeConfirm ? (
                <Div>
                    <Div styles={errors ? {...styles.smsSendConfirmBlock, ...styles.smsSendConfirmBlockErrors} : styles.smsSendConfirmBlock}>
                        <FormInput 
                            type='input'
                            typeField='number'
                            maxLength='6'
                            attribute='code'
                            compStyle={{}}
                            setFormData={setFormData}
                            title={{ body: <T textName='register-fields-code-from-sms-title-new' defaultStr='Код из смс' page='/authentication' /> }}
                            placeholder='******'
                            disabled={successCode}
                            errors={errors}
                            getCustomData={true}
                            handlers={{
                                onChange: (e) => setCode(e)
                            }}
                        />
                        <Div
                            styles={successCode ? {...styles.smsSendConfirmButton, ...styles.smsSendConfirmButtonSuccess} : errors ? {...styles.smsSendConfirmButton, ...styles.smsSendConfirmButtonError} : styles.smsSendConfirmButton}
                            effects={{ hover: styles.smsSendConfirmButtonHover }}
                            onClick={() => confirmCode()}
                        >
                            {successCode ? <T textName='need-to-confirm-btn-success' defaultStr='Подтверждено' page='/authentication'/> : <T textName='need-to-confirm-btn' defaultStr='Подтвердить' page='/authentication'/>}
                        </Div>
                    </Div>
                    {!successCode ? (
                        <>
                            <Div styles={styles.showCodeConfirmUnderBlock}>
                                <T textName='confirm-phone-undertext' vars={{ getNewCode: <span onClick={() => sendPhoneHandler('isSendAgain')} style={{color: 'rgba(44, 104, 137, 1)', 'text-decoration': 'underline', 'font-weight': '600', cursor: 'pointer'}}><T textName='send-code-once-more' defaultStr='отправить повторно' page='/authentication'/></span>, changeNumber: <span onClick={() => changeNumberHandler()} style={{color: 'rgba(44, 104, 137, 1)', 'text-decoration': 'underline', cursor: 'pointer', 'font-weight': '600'}}><T textName='hange-input-number' defaultStr='изменить номер' page='/authentication'/></span> }} defaultStr='Не приходит смс: ${getNewCode} или ${changeNumber}' page='/authentication'/>
                            </Div>
                            <Div styles={showInfoMessage ? {...styles.showCodeConfirmUnderBlockMargin, color: 'red'} : styles.showCodeConfirmUnderBlockMargin}><T textName='need-to-confirm-btn-success-info-field-new' vars={{link: <BlankLink href={'mailto:support@eternity.inc'} styles={{ ...styles.linkDefault, ...styles.dInlineBlock, 'font-weight': 700 }} effects={{ hover: styles.linkDefaultHover }} title={'support@eternity.inc'} />}} defaultStr='Если у Вас возникли трудности с подтверждением телефона - попробуйте воспользоваться Live-чатом в правом нижнем углу или напишите на ${link}' page='/authentication'/> </Div>
                        </>
                    ) : null}
                </Div>
            ) : null}
        </Div>
    )
}
export default PhoneSender