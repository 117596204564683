import React, {
    useState, useEffect, useCallback
} from 'react';
import {
    Div, P, svgSprite
} from '../wrappers';
import PropTypes from 'prop-types';

const ModalDropDown = ({
    showDropdown, modal, dataArr, styles, children, ContactImg, refTable, modalDropdown, refTableChild
}) => {
    const [display, setDisplay] = useState({});

    const func = useCallback(() => {
        if (modal?.current) {
            const dropDownHeight = modalDropdown?.current?.getBoundingClientRect()?.height;
            const top = modalDropdown?.current?.getBoundingClientRect()?.top - refTable?.current.getBoundingClientRect()?.top + dropDownHeight;
            const left = modalDropdown?.current?.getBoundingClientRect()?.left - refTable?.current.getBoundingClientRect()?.left;
            const limitBottom = refTable?.current.getBoundingClientRect()?.height;

            if (top > limitBottom || top < 0) {
                setDisplay({ display: 'none' })
            } else {
                setDisplay({ display: 'flex' })
            }

            modal.current.style.top = `${top}px`;
            modal.current.style.left = `${left}px`;
        }
    }, [modal, modalDropdown, refTable])

    useEffect(() => {
        const refTableChildElement = refTableChild?.current;
        if (refTableChildElement) {
            refTableChildElement.addEventListener('scroll', func)
        }

        return () => {
            refTableChildElement.removeEventListener('scroll', func)
        }
    }, [refTableChild, func])

    return (
        <Div
            childrenRef={modal}
            styles={showDropdown
                ? {
                    ...styles.dropdownBlockColumn, ...styles.dropdownBlockColumnActiveWeb, ...display
                }
                : { ...styles.dropdownBlockColumn, display: 'none' }}
            onClick={e => { e.preventDefault();e.stopPropagation() }}
        >
            {
                dataArr.map((item, idx) => {
                    if (children[item] !== null) {
                        return (
                            <P styles={styles.dropdownItem} key={`dditem${idx}`}>
                                {svgSprite(ContactImg(item), {
                                    fill: styles.variable.greenDeepColor,
                                    style: styles.contactIcon
                                })}
                                {`${children[item]}`}
                            </P>
                        );
                    }
                })
            }
        </Div>
    )
}

ModalDropDown.propTypes = {
    showDropdown: PropTypes.bool,
    modal: PropTypes.object,
    dataArr: PropTypes.array,
    styles: PropTypes.object,
    ContactImg: PropTypes.func,
    refTable: PropTypes.object,
    modalDropdown: PropTypes.object,
    refTableChild: PropTypes.object
};
export default ModalDropDown;