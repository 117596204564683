import React, { useContext } from 'react'

import { P, Div } from '../../../../hybrid/wrappers';
import T, { t } from '../../../../common/components/T';

import { getData } from '../../../../common/utils/router';
import UserContext from '../../../../common/utils/getContext';

import getStyles from '../../../../project/styles/widget-styles/grid-styles';
import Styles from '../../../../common/decorators/Styles';

const ShowMoreBtn = ({
    styles,
    count,
    textOpen,
    textClose,
    url,
    filterArr,
    convertEndUrl,
    showMoreEndUrl,
    setShowMoreEndUrl,
    setTableData,
    convertData,
    setViewData,
    paginationLinks,
    translation,
    token
}) => {
    const {
        userToken, lang, setAlertData
    } = useContext(UserContext);

    const showMore = () => {
        (async () => {
            const newUrl = { ...showMoreEndUrl, page: { size: showMoreEndUrl.page.size + count || 5 } }
            const endUrl = await convertEndUrl(newUrl, filterArr)
            const updateStateGrid = await getData(`${url}${endUrl}`, token || userToken, lang)
            setShowMoreEndUrl(newUrl)

            if (updateStateGrid.message) {
                setAlertData({ type: 'error', cont: updateStateGrid.message });
                return
            }

            const sendData = convertData ? convertData(updateStateGrid) : updateStateGrid;
            if (setViewData){
                setViewData((pre) => {
                    return {
                        ...pre,
                        tbody: sendData
                    };
                });
            } else {
                setTableData(sendData);
            }
        })()
    }

    const showInitial = () => {
        (async () => {
            const newEndUrl = { ...showMoreEndUrl, page: { size: count || 5 } }
            const endUrl = await convertEndUrl(newEndUrl, filterArr)
            const initStateGrid = await getData(`${url}${endUrl}`, userToken, lang)
            setShowMoreEndUrl(newEndUrl)

            if (initStateGrid.message) {
                setAlertData({ type: 'error', cont: initStateGrid.message });
                return
            }

            const sendData = convertData ? convertData(initStateGrid) : initStateGrid;
            if (setViewData){
                setViewData((pre) => {
                    return {
                        ...pre,
                        tbody: sendData
                    };
                });
            } else {
                setTableData(sendData);
            }
        })()
    }

    return (
        <>
            {!paginationLinks.next && !paginationLinks.prev && showMoreEndUrl.page.size !== count &&
        <Div styles={styles.btnAddMoreWrap} data-uitest='619_uitest' onClick={showInitial}>
            <P styles={styles.btnAddMoreText}> <T textName='hide' defaultStr='Скрыть' page='all' />{` ${textClose}`}</P>
        </Div>
            }

            {paginationLinks.next &&
        <Div styles={styles.btnAddMoreWrap} effects={{ hover: { ...styles.btnAddHover } }} data-uitest='620_uitest' onClick={showMore}>
            <P styles={styles.btnAddMoreText}>{t(
                'grid-ShowNext-btn', 'Показать следующие', 'widget', translation, 'grid'
            )}{` ${count || 5} ${textOpen}`}</P>
        </Div>
            }
        </>
    )
}

export default Styles(ShowMoreBtn, getStyles);