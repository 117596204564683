// @generated: @expo/next-adapter@2.1.5
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Div } from '../../wrappers';
import WrapperModalPortal from './WrapperModalPortal';
import { UserProfileContext } from '../../../common/utils/getContext';

const ModalPortal = ({
    children, customClass, closeHandler, fullWidth, fullHeight, unscrollable = true
}) => {
    const { officePage } = useContext(UserProfileContext) ? useContext(UserProfileContext) : {};

    return ReactDOM.createPortal(<WrapperModalPortal unscrollable={unscrollable}>
        <div className={`${officePage && 'officePage'} ${customClass || 'modal'}`} data-uitest='42_uitest' onClick={(e) => { e.stopPropagation();closeHandler && closeHandler(); }}>
            <Div
                styles={{
                    ...fullWidth && { width: '100%' }, ...fullHeight && { height: '100%' }, ...{ 'max-width': '100%' }, ...{ 'max-height': '100%' }
                }}
                data-uitest='43_uitest' onClick={(e) => { e.stopPropagation(); }}
                className='scrollbar-hidden'
            >
                {children}
            </Div>
        </div>
    </WrapperModalPortal>,
    document?.body)
};
ModalPortal.propTypes = {
    customClass: PropTypes.string,
    closeHandler: PropTypes.func,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool
};
export default ModalPortal;