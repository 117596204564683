import React from 'react'
import PropTypes from 'prop-types';
import { Div, P } from '../../hybrid/wrappers';

const ProgressBar = ({
    progress = 0, styles, progressRef, numberRef, pointerRef
}) => {
    let progressBar = {
        'height': '100%',
        'width': `${progress}%`,
        'background': `linear-gradient(90deg, ${styles.variable.blueColor} 0%, ${styles.variable.blueExtraLightColor} 100%)`,
        'transition': 'width 0.2s ease'
    }
    const progressText = {
        'position': 'absolute',
        'left': `${progress}%`,
        'bottom': '30px',
        'transform': 'translate(-50%, 0)',
        'color': `${styles.variable.blueColor}`,
        'transition': 'left 0.2s ease'
    }
    const progressPointer = {
        'position': 'absolute',
        'left': `${progress}%`,
        'bottom': '0',
        'width': '1px',
        'height': '30px',
        'background': `${styles.variable.blueColor}`,
        'transition': 'left 0.2s ease'
    }
    const progressWraper = { 'position': 'relative' }
    const progressBarWraper = {
        'height': '10px',
        'background': `${styles.variable.greyExtraDarkTransparentColor}`,
        'border-radius': '6px',
        'overflow': 'hidden'
    }

    return (
        <Div styles={progressWraper}>
            <P childrenRef={numberRef} styles={progressText}>{`${progress}%`}</P>
            <Div childrenRef={pointerRef} styles={progressPointer}></Div>
            <Div styles={progressBarWraper}>
                <Div childrenRef={progressRef} styles={progressBar}></Div>
            </Div>
        </Div>
    )
}

ProgressBar.propTypes = {
    progress: PropTypes.number,
    styles: PropTypes.object
}

export default ProgressBar