import React, {
    useState, useCallback, useEffect, useContext, useMemo
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, P, svgSprite
} from '../../../../hybrid/wrappers';
import { useDropzone } from 'react-dropzone';
import UserContext from '../../../../common/utils/getContext';
import T, { t } from '../../../../common/components/T'
import FromInputTitle from '../FormFieldTitle';
import { getWidgetTranslates } from '../../../../common/utils/utils';
import { LangContext } from '../../../../common/decorators/Language';
import Loader from '../../../plug/Loader';
import {
    getFilesThumbs, getThumbs, onDropFunction, useDownload, useProgress
} from './helpers';
import { useWindowDimensions } from '../../../../common/utils/windowDimensions';


const FormInputDropzone = ({
    attribute,
    max = 999,
    maxScale,
    title,
    button,
    hint,
    compStyle,
    setFormData,
    value,
    multiple = true,
    styles,
    noDrag,
    previewTitle,
    deleteBtn = true,
    infoFile,
    accept = 'image/jpeg, image/jpg, image/png, .heic, .heif',
    downloading = false,
    exportData = {},
    deletefile= false,
    required,
    errors
}) => {
    const { setAlertData, lang } = useContext(UserContext);

    const screenWidth = useWindowDimensions() < 768;

    const [transl, setTranslates] = useState(null);
    const [isClickDownload, setIsClickDownload] = useState(false);
    const [loader, setLoader] = useState(true);
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};
    const loaderStyles = useMemo(() => ({
        loader: {
            padding: '0',
            transform: 'scale(0.6)',
            height: '50px',
            'background-color': 'none'
        }
    }), []);

    const { setConvertProgress, Bar } = useProgress()

    const downloadFile = useDownload(multiple, exportData, isClickDownload);

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);


    const nameForm = screenWidth ?
        t(
            'FormInputDropzone-InfoFile1-mob', 'Нажмите для загрузки файла', 'widget', translation, 'form'
        )
        : !infoFile
            ? t(
                'FormInputDropzone-InfoFile1', 'Перетащите изображение в это поле или воспользуйтесь кнопкой «Загрузить»', 'widget', translation, 'form'
            )
            : t(
                'FormInputDropzone-InfoFile2', 'Перетащите файл в это поле или воспользуйтесь кнопкой «Загрузить»', 'widget', translation, 'form'
            )


    const [val, setVal] = useState(value || multiple ? [] : null);

    const {
        getRootProps, getInputProps, fileRejections
    } = useDropzone({
        noDrag: !!noDrag,
        accept: accept,
        onDrop: onDropFunction(
            attribute, multiple, val, maxScale, max, setVal, setFormData, translation, setAlertData, setLoader, setConvertProgress
        )
    });


    useEffect(() => {
        if (fileRejections.length && fileRejections[0].errors?.length){
            if (fileRejections[0].errors[0].code === 'file-invalid-type'){
                setAlertData({
                    type: 'error', cont: `${t(
                        'FormInputDropzone-PermissibleFileExtension', 'Допустимое расширение файлов', 'widget', transl, 'form'
                    )} - ${accept}`
                });
            } else {
                setAlertData({
                    type: 'error', cont: t(
                        'FormInputDropzone-LoadingFileError', 'Ошибка загрузки файлов', 'widget', transl, 'form'
                    )
                });
            }
        }
    }, [accept, fileRejections, setAlertData, transl])

    const deleteImg = useCallback((file, e) => {
        e.preventDefault();
        e.stopPropagation();
        let newValue = multiple ? val.filter((item) => item !== file) : null;

        setVal(newValue);
        setFormData(attribute, newValue)
    }, [attribute, multiple, setFormData, val]);

    const thumbs = infoFile ?
        getFilesThumbs(
            val, hint, downloading, deletefile, deleteImg, downloadFile, transl, styles, compStyle, multiple, exportData, screenWidth, isClickDownload, setIsClickDownload
        )
        : getThumbs(
            val, deleteBtn, deleteImg, downloading, downloadFile, noDrag, styles, compStyle, isClickDownload, setIsClickDownload
        )

    useEffect(() => {
        setVal(multiple ? value || [] : value || null);
    }, [multiple, value]);
    return (
        <Div styles={{ ...styles.formGroup, ...compStyle?.wrapper || {} }}>
            {
                !!title && <FromInputTitle
                    id={`input_${attribute}`}
                    styles={styles}
                    errors={errors}
                    title={title}
                    compStyle={compStyle}
                    hint={title?.hint}
                    required={required}
                    variationStyle={'separated_title'}
                />
            }
            <Div styles={{ ...styles.formDropzoneInner, ...compStyle?.inner || {} }} {...getRootProps({ className: 'dropzone' })}>
                <input {...!downloading && getInputProps()} />
                {!noDrag &&
                    <Div styles={{ ...styles.formDropzoneLoadDrop, ...compStyle?.loadDrop }}>
                        <Div styles={compStyle?.imageGap}>
                            {
                                svgSprite(infoFile ? 'plug-load-file-image' : 'plug-load-image', { width: '72px', height: '72px' })
                            }
                        </Div>
                        <Div styles={{ 'width': '100%' }}>
                            {loader === 'heic' ?
                                <>
                                    <Div styles={{
                                        'max-width': '400px',
                                        'width': '100%',
                                        'margin': '0 auto'
                                    }}>
                                        <Loader compStyles={loaderStyles}/>
                                        <Bar/>
                                    </Div>
                                    Конвертация файла в формат jpeg
                                </>
                                : nameForm}
                        </Div>
                    </Div>
                }
                {
                    val?.length || !multiple && val ?
                        <Div styles={noDrag ? compStyle?.previewInner || {} : { ...styles.formDropzoneLoadImageContainerPreview, ...compStyle?.previewInner || {} }}>
                            {
                                previewTitle && <P styles={compStyle?.previewTitle || {}}>{previewTitle}</P>
                            }
                            {thumbs}
                        </Div> : null
                }
                <Div styles={{ ...styles.formDropzoneLoadLabelWrap, ...compStyle?.formLoadLabelWrap }}>
                    <P
                        effects={{ hover: { 'opacity': '.8' } }}
                        styles={noDrag ? compStyle?.button || {} : { ...styles.formDropzoneLoadLabel, ...compStyle?.button || {} }}
                    >
                        {
                            button?.icon !== false ? svgSprite(button?.icon || 'painting', {
                                width: '18px',
                                height: '18px',
                                style: { ...styles.formDropzoneLoadLabelIcon, ...compStyle?.buttonIcon }
                            }) : null
                        }

                        {button?.body || <T textName='upload' defaultStr='Загрузить' page='all' />}

                    </P>
                    {
                        hint &&
                        <Div styles={{ ...styles.formDropzoneText, ...compStyle?.formImageText }}>{hint}</Div>

                    }
                </Div>
            </Div>
            {/* {
                (val?.length || !multiple && val) && !simpleDiv ?
                    <Div styles={noDrag ? compStyle?.previewInner || {} : { ...styles.formDropzoneLoadImageContainerPreview, ...compStyle?.previewInner || {} }}>
                        {
                            previewTitle && <P styles={compStyle?.previewTitle || {}}>{previewTitle}</P>
                        }
                        {thumbs}
                    </Div> : null
            } */}
        </Div>
    );
};

FormInputDropzone.propTypes = {
    attribute: PropTypes.string,
    max: PropTypes.number,
    maxScale: PropTypes.number,
    title: PropTypes.object,
    button: PropTypes.object,
    hint: PropTypes.node,
    compStyle: PropTypes.object,
    setFormData: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    multiple: PropTypes.bool,
    styles: PropTypes.object,
    noDrag: PropTypes.bool,
    previewTitle: PropTypes.node,
    deleteBtn: PropTypes.bool,
    infoFile: PropTypes.bool,
    accept: PropTypes.string,
    downloading: PropTypes.bool,
    exportData: PropTypes.object,
    deletefile: PropTypes.bool,
    required: PropTypes.bool,
    errors: PropTypes.array
};

export default Styles(FormInputDropzone, getStyles);