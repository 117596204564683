// @generated: @expo/next-adapter@2.1.5
import React, { useCallback } from 'react';
import FormInput from './components/form-input/FormInput';
import ToggleInput from './components/form-input/ToggleInput';
import FormInputDisabled from './components/FormDisabledInfo';
import FormInputDropzone from './components/form-image/FormDropzone';
import FormCheckboxList from './components/form-checkbox/FormCheckboxList';
import FormCheckbox from './components/form-checkbox/FormCheckbox';
import FormCheckboxSwitcher from './components/form-checkbox/FormCheckboxSwitcher';
import FormSponsorWidget from './components/form-sponsor-widget/FormSponsorWidget';
import FormDate from './components/form-date/FormDate';
import FormChangePasswordWidget from './components/form-change-data-in-modal/FormChangeDataInModal';
import FormDropdownSearch from './components/form-dropdown/FormDropdownSearch';
import FormRadioSwitcher from './components/form-radio/FormRadioSwitcher';
import FormSelect from './components/form-dropdown/FormSelect';
import FormSelectLocation from './components/form-dropdown/FormSelectLocation';
import FormRange from './components/form-input/FormRangeValues';
import FormRadioCircles from './components/form-radio/FormRadioCircles';
import FormTextArea from './components/form-textarea/FormInputArea';
import FormProductPrices from './components/form-product-prices/FormProductPrices'
import Editor from './components/form-editor/FormEditor';
import FormColorPicker from './components/form-color/FormColorPicker';
import FormMap from './components/form-map/FormMap';
import Contact from '../contact/Contact';
import SelectDataWidget from './components/select-data-widget/SelectDataWidget';
import FormDateRange from './components/form-date/FormDateRange';
import FormCaptcha from './components/FormCaptcha';
import FormSelectCreatable from './components/form-select-creatable/FormSelect';
import PhoneSender from './components/phone-sender/PhoneSender';

const FormSwitcher = ({
    data,
    formName,
    funcs,
    formStyles,
    formData,
    fieldsErr,
    customData,
    sendFormData,
    message,
    setFormData,
    captchaData
}) => {
    const getPropsData = useCallback((item, idx) => {
        const {
            attribute, disabled, compStyles
        } = item;

        return {
            ...item,
            handlers: funcs?.[attribute] || {},
            compStyle: compStyles&&compStyles.constructor===Object ? compStyles : formStyles?.[attribute] || {},
            setFormData: setFormData,
            errors: fieldsErr?.[item.attribute],
            hint: item?.hint,
            disabled,
            customData: customData?.[attribute],
            key: `${attribute}${formName}${idx}`,
            message,
            routePage: item?.routePage ? item.routePage : null,
            fieldsErr: fieldsErr,
            maxLength: item?.maxlength
        }
    }, [customData, fieldsErr, formName, formStyles, funcs, message, setFormData])

    return (
        <>
            {
                data.map((item, idx) => {
                    const propsData = getPropsData(item, idx);
                    switch (item.type) {
                    case 'phone-widget':
                    case 'password':
                    case 'input':
                        return <FormInput {...propsData} sender={sendFormData}/>;
                    case 'phone-sender':
                        return <PhoneSender {...propsData} />
                    case 'toggle-input':
                        return <ToggleInput {...propsData} />;
                    case 'dropdown':
                    case 'select':
                        return <FormSelect {...propsData} />;
                    case 'select-creatable':
                        return <FormSelectCreatable {...propsData} />
                    case 'checkbox-list':
                        return <FormCheckboxList {...propsData} />;
                    case 'checkbox':
                        return <FormCheckbox {...propsData} />;
                    case 'checkbox-switcher':
                        return <FormCheckboxSwitcher {...propsData} />;
                    case 'radio-list':
                        return <FormRadioCircles {...propsData} />;

                    case 'sponsor-widget':
                        return <FormSponsorWidget {...propsData} />;

                    case 'select-data-widget':
                    case 'admin-sponsor-widget':
                        return <SelectDataWidget {...propsData} />;

                    case 'range-date-widget':
                        return (
                            <FormDateRange
                                {...propsData}

                            />
                        );
                    case 'date-widget':
                        return <FormDate {...propsData} />;
                    case 'radio-switcher':
                    case 'sex-widget':
                        return <FormRadioSwitcher {...propsData} />;
                    case 'location_widget':
                        return <FormSelectLocation {...propsData} formData={formData}/>;
                    case 'dropdown-search':
                        return <FormDropdownSearch
                            {...propsData} />;
                    case 'dropdown-multiselect':
                        return <FormSelect {...propsData} multiple={true}/>;
                    case 'image':
                    case 'dropzone':
                        return <FormInputDropzone {...propsData} />;

                    case 'info-field':
                        return <FormInputDisabled {...propsData} />;
                    case 'range-values':
                        return <FormRange {...propsData} />;

                    case 'password-change-widget':
                    case 'email-change-widget':
                        return (
                            <FormChangePasswordWidget
                                {...propsData}
                            />
                        );
                        //new Form-element
                    case 'textarea':
                        return (
                            <FormTextArea {...propsData} sender={sendFormData}/>
                        );
                    case 'editor':
                        return (
                            <Editor {...propsData}/>
                        )
                    case 'product-prices':
                        return (
                            <FormProductPrices
                                {...propsData}
                                errors={fieldsErr}
                            />
                        )
                    case 'color-picker':
                        return (
                            <FormColorPicker {...propsData}/>
                        )
                    case 'maps':
                        return (
                            <FormMap {...propsData} />
                        )
                    case 'contact':
                        return (
                            <Contact {...propsData} />
                        )
                    default:
                        return null;
                    }
                })
            }
            {
                captchaData ?
                    <FormCaptcha
                        alias={captchaData?.alias}
                        attempts={captchaData?.attempts}
                        fieldsErr={fieldsErr}
                        formStyles={formStyles}
                        setFormData={setFormData}
                    />
                    : null
            }

        </>
    );
};

export default FormSwitcher;