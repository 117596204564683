import { components } from 'react-select';
import { Div } from '../../../../../hybrid/wrappers';
import FromInputTitle from '../../FormFieldTitle';
import { getIsCoupleVariation } from '../../helpers';

const getControl = (
    title, hint, required, val, insertComponent, styles, compStyle, variationStyle, errors
) => {
    const Control = (props) => {
        return (
            <Div
                childrenRef={props?.innerRef}
                styles={
                    {
                        ...styles[`formControl__${variationStyle}`],
                        ...compStyle?.input || {},
                        ...errors && errors.length
                            ? {
                                ...styles[`formControlError__${variationStyle}`],
                                ...compStyle?.inputError || {}
                            } : {
                                ...props?.menuIsOpen
                                    ? {
                                        ...styles[`formControlFocus__${variationStyle}`],
                                        ...compStyle?.inputFocus || {}
                                    } : {},
                                ...props?.isDisabled
                                    ? {
                                        ...styles['formControlDisabled'],
                                        ...compStyle?.inputDisabled || {}
                                    } : {}
                            }
                    }
                }
                effects={{ hover: props?.isDisabled ? {} : styles[`formControlFocus__${variationStyle}`] }}
            >
                <Div styles={styles.formControlContent}>
                    <Div styles={styles.formControlInner}>
                        {
                            !!title && getIsCoupleVariation(variationStyle) ?
                                <FromInputTitle
                                    styles={styles}
                                    errors={errors}
                                    title={title}

                                    compStyle={compStyle}
                                    hint={hint}
                                    required={required}
                                    isFilled={Boolean(props?.isMulti ? val?.length : val?.id || val?.value)}
                                    variationStyle={variationStyle}
                                    focused={props?.menuIsOpen}
                                /> : null
                        }
                        <components.Control {...props} />
                    </Div>
                    {insertComponent}
                </Div>
            </Div>
        )
    };
    return Control;
}
export default getControl