import Router from 'next/router';
import axios from 'axios';
import { API_SERVER_URL, delToken } from './utils';
import { zendoStorage } from '../../hybrid/wrappers';

export const navigate = (url, asRout, lang, replace) => {
    if (replace){
        if (asRout && lang?.length > 0) {
            Router.router.replace(url === '/' ? '/[lang]' : url, `/${lang}${asRout}`);
        } else if (asRout) {
            Router.router.replace(url.replace('/[lang]', ''), `${asRout}`);
        } else {
            Router.router.replace(url);
        }
    } else {
        if (asRout && lang?.length > 0) {
            Router.router.push(url === '/' ? '/[lang]' : url, `/${lang}${asRout}`);
        } else if (asRout) {
            Router.router.push(url.replace('/[lang]', ''), `${asRout}`);
        } else {
            Router.router.push(url);
        }
    }
};

export const historyStore = (type) => {
    const changePrevUrlVal = () => {
        if (Router.router.route !== '/logout') {
            zendoStorage.set('prevUrlData', JSON.stringify({
                url: Router.router.route,
                params: Router.router.asPath
            }));
        }
    };

    if (type === 'on')Router.router.events.on('routeChangeStart', changePrevUrlVal);
    if (type === 'off')Router.router.events.off('routeChangeStart', changePrevUrlVal);
};

export const dataSender = (
    url, method, data, susseccFunc, errorFunc, userToken, lang, currency, dataType = 'json'
) => {
    const headers = userToken
        ? {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': `${dataType === 'json' ? 'application/json' : 'multipart/form-data'};charset=UTF-8`,
            ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
            mode: 'no-cors',
            ...lang ? { 'Accept-Language': lang } : {},
            ...currency ? { 'Accept-Currency': currency } : {}
        }
        : {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': `${dataType === 'json' ? 'application/json' : 'multipart/form-data'};charset=UTF-8`,
            ...lang ? { 'Accept-Language': lang } : {},
            ...currency ? { 'Accept-Currency': currency } : {}
        };
    const config = {
        method: !['POST', 'GET'].includes(method) && !['json', 'form-data'].includes(dataType) ? 'POST': method,
        url,
        headers
    };

    if (data && dataType === 'json'){
        config.data = JSON.stringify(data);
    } else {
        let newData = new FormData();
        if (!data?.['_method']){
            newData.append('_method', method)
        }
        for (let key in data) {
            if (key === 'image' || key === 'seo_image') {
                if (data[key]?.url || data[key]?.links?.preview) {
                    newData.append(key, '')
                } else {
                    newData.append(key, data[key])
                }
            } else if (key === 'moreImage') {
                if (data[key].length > 0) {
                    data[key].map((img, idx) => {
                        if (data[key][idx]?.url || data[key][idx]?.links?.preview) {
                            newData.append(key, '')
                        } else {
                            newData.append(`${key}[${idx}]`, img)
                        }
                    })
                }
            } else if (Array.isArray(data[key])) { //Проверка на массив
                data[key].map((item, idx) => {
                    if (typeof item === 'object') { // Проверка на массив объектов
                        if (key === 'categories' || key === 'special_statuses') {
                            newData.append(`${key}[${idx}]`, item.id || item.value)
                        } else {
                            for (let k in item) {
                                if (Array.isArray(item[k])){
                                    item[k].map((j, index) => {
                                        if (!isNaN(j)) {
                                            newData.append(`${key}[${idx}][${k}][${index}]`, j)
                                        } else {
                                            for (let m in j) {
                                                if (Array.isArray(j[m])) {
                                                    newData.append(`${key}[${idx}][${k}][${index}][${m}]`, j[m][0])
                                                } else {
                                                    newData.append(`${key}[${idx}][${k}][${index}][${m}]`, j[m])
                                                }
                                            }
                                        }
                                    })
                                } else {
                                    newData.append(`${key}[${idx}][${k}]`, item[k])
                                }
                            }
                        }
                    } else { // Массив чисел, строк и т.д.
                        newData.append(`${key}[${idx}]`, item)
                    }
                })
            } else if (typeof data[key] === 'object') { // Проверка на объект
                for (let k in data[key]) {
                    newData.append(`${key}[${k}]`, data[key][k])
                }
            } else {
                newData.append(key, data[key])
            }
        }
        config.data = newData;
    }

    axios(config)
        .then((response) => {
            if (response.status === 204) {
                susseccFunc({ status: 204 });
            } else {
                susseccFunc(response);
            }
        })
        .catch((error) => {
            if (!error.response) return false;
            const {
                data, status, headers
            } = error.response;

            if (status === 401) {
                delToken(userToken, lang)
            } else {
                errorFunc(data, status, headers)
            };
        });
};

export const getTokenByIdAndHash = async (id, hash) => {
    const data = await axios({
        method: 'POST',
        url: `${API_SERVER_URL}/api/v1/shopify/login`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: JSON.stringify({
            id,
            hash
        })
    })

    return data?.data?.data?.token || false
}

export const getData = async (
    url, userToken, lang, ip, currency, changeNeedReloadToken
) => {
    if (!url) {
        return false;
    }
    const resultUrl = url.includes('https://') || url.includes('http://') ? url : `${API_SERVER_URL}${url}`
    try {
        const res = await fetch(resultUrl, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;charset=UTF-8',
                ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
                ...lang ? { 'Accept-Language': lang } : {},
                ...ip ? { 'ClientNodeIp': ip } : {},
                ...currency ? { 'Accept-Currency': currency } : {},
                mode: 'no-cors'
            }
        })
        if (res.status === 500){
            console.warn(res);
            return { status: 500 }
        }
        if (res.status === 404 || !res){
            return false
        } else if (res.status === 403 && changeNeedReloadToken) {
            changeNeedReloadToken();
            return false;
        } else if (res.status === 503) {
            navigate('/[lang]/error/[type]', '/error/503', lang);
            return false
        } else {
            return await res.json();
        }
    } catch (e) {
        console.warn(url, e);
        return false;
    }
};

export const htmlFormSend = (path, params) => {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = path;
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];
            form.appendChild(hiddenField);
        }
    }
    document.body.appendChild(form);
    form.submit();
}