// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import {
    svgSprite, Div, Slider, Img
} from '../../../hybrid/wrappers';
import T, { t } from '../../../common/components/T';
import OfficeConditionBlock from '../../../hybrid/library/OfficeConditionBlock';
import SpeedProgress from '../../../hybrid/library/SpeedProgressAdmin';
import LineProgress from '../../../hybrid/library/LineProgress';
import uikit from '../../../project/uikit/styles';


const ProgressBlock = ({
    styles,
    rankData,
    trans
}) => {
    return (
        <Div styles={styles.userModalUserRangBlockWrapper}>
            <Div styles={styles.userModalRangBlock}>
                <Div styles={styles.userModalRangItem}>
                    <Div styles={styles.userModalRangIcon}>
                        {rankData.current_rank && rankData.current_rank?.preview?.links?.preview ?
                            <Img
                                styles={styles.userModalRangImg}
                                src={rankData.current_rank && rankData.current_rank?.preview?.links?.preview}
                            />
                            :
                            rankData?.current_rank?.alter_name ? (
                                <Div styles={{display: 'flex', 'align-items': 'center'}}>{Array.from(Array(rankData?.current_rank?.alter_name).keys()).map(item => {
                                return (
                                    <Div
                                        key={item}
                                        styles={{
                                            display: 'flex',
                                            height: '16px',
                                            margin: '0 2px'
                                        }}
                                    >
                                        {svgSprite('eternity-rank-star', {
                                            width: '16px',
                                            height: '16px',
                                        })}
                                    </Div>
                                )
                            })}</Div>
                            )
                            : rankData?.current_rank?.sort ?
                                svgSprite(`rank${rankData?.current_rank?.sort}`, styles.userModalRangImg || {
                                    width: '34px',
                                    height: '34px'
                                })
                                :
                                <Div styles={{
                                    background: '#48535F',
                                    'text-align': 'center',
                                    'border-radius': '16px',
                                    display: 'flex',
                                    'align-items': 'center',
                                    'justify-content': 'center',
                                    padding: '4px 12px',
                                    color: 'white'
                                }}>
                                    <T textName='personal_WithoutRank-new' defaultStr='Member' page='all'/>
                                </Div>
                        }
                    </Div>
                    <Div styles={styles.userModalRangValue}>
                        {rankData?.current_rank?.alter_name ? null : rankData.current_rank ? rankData.current_rank.name : null}
                    </Div>
                </Div>
                {
                    rankData.next_rank?.name !== rankData.current_rank?.name ?
                        <>
                            <Div styles={styles.userModalRangArrow}>
                                {
                                    svgSprite('angle-right', { style: styles.userModalCloseAngleRight })
                                }
                            </Div>
                            <Div styles={styles.userModalRangItem}>
                                <Div styles={styles.userModalRangIcon}>
                                    {rankData.next_rank && rankData.next_rank?.preview?.links?.preview ?
                                        <Img
                                            styles={styles.userModalRangImg}
                                            src={rankData.next_rank && rankData.next_rank?.preview?.links?.preview}
                                        />
                                        :
                                        rankData?.next_rank?.alter_name ? (
                                            <Div styles={{display: 'flex', 'align-items': 'center'}}>{Array.from(Array(rankData?.next_rank?.alter_name).keys()).map(item => {
                                            return (
                                                <Div
                                                    key={item}
                                                    styles={{
                                                        display: 'flex',
                                                        height: '16px',
                                                        margin: '0 2px'
                                                    }}
                                                >
                                                    {svgSprite('eternity-rank-star', {
                                                        width: '16px',
                                                        height: '16px',
                                                    })}
                                                </Div>
                                            )
                                        })}</Div>
                                        )
                                        : 
                                        rankData?.next_rank?.sort ?
                                            svgSprite(`rank${rankData?.next_rank?.sort}`, styles.userModalRangImg || {
                                                width: '34px',
                                                height: '34px'
                                            })
                                            :
                                            <Div styles={{
                                                background: '#48535F',
                                                'text-align': 'center',
                                                'border-radius': '16px',
                                                display: 'flex',
                                                'align-items': 'center',
                                                'justify-content': 'center',
                                                padding: '4px 12px',
                                                color: 'white'
                                            }}>
                                                <T textName='personal_WithoutRank-new' defaultStr='Member' page='all'/>
                                            </Div>
                                    }
                                </Div>
                                <Div styles={styles.userModalRangValue}>
                                    {rankData?.next_rank?.alter_name ? null : rankData.next_rank ? rankData.next_rank.name : null}
                                </Div>
                            </Div>
                        </>
                        : null
                }

            </Div>
            <Div styles={styles.wrapSpeedProgressSlider}>
                {
                    rankData?.conditions?.volumes &&
                    <Slider props={{
                        dots: true,
                        arrows: false,
                        slidesToShow: rankData.conditions.volumes.length < 2 ? rankData.conditions.volumes.length : 2,
                        slidesToScroll: 2,
                        responsive: [
                            {
                                breakpoint: 575,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    }}>
                        {
                            rankData.conditions.volumes.map((quantityPVitem, idx) =>
                                <SpeedProgress
                                    key={`quantityPVitem${idx}`}
                                    target={quantityPVitem.target}
                                    hasPV={quantityPVitem.accumulated}
                                    title={quantityPVitem.symbol}
                                    formatted_accumulated={quantityPVitem.formatted_accumulated}
                                    formatted_left_to_accumulate={quantityPVitem.formatted_left_to_accumulate}
                                    styles={styles}
                                />)
                        }
                    </Slider>

                }
            </Div>
            <Div>
                {
                    !!rankData.conditions?.next_rank_package &&
                        <OfficeConditionBlock
                            data={{
                                status: rankData?.conditions?.next_rank_package?.has_package,
                                text: ` ${t(
                                    'user_modal_PackageAvailability', 'Наличие пакета', 'widget', trans, 'userModal'
                                )} «${rankData?.conditions?.next_rank_package?.package.name}» ${t(
                                    'user_modal_orHigher', 'или выше', 'widget', trans, 'userModal'
                                )}`
                            }}
                        />
                }
                {rankData && rankData.conditions && rankData.conditions.branches_with_rank ?
                    <Div styles={uikit.mt3}>
                        <LineProgress
                            data={{
                                have: rankData.conditions.branches_with_rank.target_rank,
                                partners: {
                                    set: rankData?.conditions?.branches_with_rank?.number,
                                    max: rankData?.conditions?.branches_with_rank?.target_number
                                }
                            }}
                            styles={styles}
                        />
                    </Div>
                    : null}
            </Div>
        </Div>
    )
};
ProgressBlock.propTypes = {
    styles: PropTypes.object,
    nowStatus: PropTypes.string,
    nextStatus: PropTypes.string,
    partners: PropTypes.object
};
export default ProgressBlock;