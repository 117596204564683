import React, {
    useState, useRef, useEffect, useCallback
} from 'react';
import {
    Div, svgSprite, Tooltip, P
} from '../../../hybrid/wrappers';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import T, { t } from '../../../common/components/T'
import uikit from '../../../project/uikit/styles';

const WrapperButton = (props) => {
    if (props.hint) {
        return (
            <Div {...props}>
                <Tooltip text={props.hint}
                    styles={{ 'display': 'flex', 'align-items': 'center' }}>{props.children}</Tooltip>
            </Div>
        )
    }
    return <Div {...props}>{props.children}</Div>
}

const ModalDropDown = ({
    val,
    showDropdown,
    modal,
    styles,
    refTable,
    modalDropdown,
    refTableChild,
    getHandlers,
    translation
}) => {
    const StylesButton = (button) => {
        const defaultDiv = (text) =>
            <Div styles={{}}
                effects={{
                    hover: {
                        color: styles.variable.blueColor,
                        background: styles.variable.greyExtraLightColor
                    }
                }}>
                <P>{text}</P>
            </Div>
        switch (button) {
        case 'download_zip': return (
            <Div
                styles={{ display: 'flex' }}
                effects={{ hover: { background: styles.variable.greyExtraLightColor } }}
            >
                <P>{t(
                    'button-downloadZip', 'Скачать документы', 'widget', translation, 'grid'
                )}</P>
            </Div>
        )
        case 'reject':
            return (
                <Div styles={{
                    display: 'flex',
                    color: styles.variable.redColor
                }}>
                    <Div styles={{ 'margin-right': '8px' }}>
                        {svgSprite('cross',
                            { style: { ...styles.tableButtonIconClosed } })}
                    </Div>
                    <P>{t(
                        'grid-Reject', 'Отклонить', 'widget', translation, 'grid'
                    )}</P>


                </Div>
            )
        case 'verify':
            return (
                <Div styles={{
                    display: 'flex',
                    color: styles.variable.greenDeepColor
                }}>
                    <Div styles={{ 'margin-right': '8px' }}>
                        {svgSprite('check',
                            { style: { ...styles.tableButtonIconSmall, ...styles.tableButtonIconNew } })}
                    </Div>
                    <P>{t(
                        'grid-Verify', 'Верифицировать', 'widget', translation, 'grid'
                    )}</P>
                </Div>
            )
        case 'stages':
            return (
                <Div
                    styles={{ display: 'flex' }}
                    effects={{ hover: { background: styles.variable.greyExtraLightColor } }}
                >
                    <P>{t(
                        'button-preview', 'Просмотреть', 'widget', translation, 'grid'
                    )}</P>
                </Div>

            )
        case 'transactions':
            return (
                <Div styles={{}}
                    effects={{
                        hover: {
                            color: styles.variable.blueColor,
                            background: styles.variable.greyExtraLightColor
                        }
                    }}
                >
                    <P>{t(
                        'grid-ListFinOperation', 'Список финансовых проводок', 'widget', translation, 'grid'
                    )}</P>
                </Div>
            )
        case 'ban':
            return (
                <Div styles={{}}
                    effects={{
                        hover: {
                            color: styles.variable.blueColor,
                            background: styles.variable.greyExtraLightColor
                        }
                    }}
                >
                    {val.ban.withdrawal ?
                        <P>{t(
                            'grid-DisallowWithdrawal', 'Запретить вывод', 'widget', translation, 'grid'
                        )}</P> :
                        <P>{t(
                            'grid-AllowWithdrawal', 'Pазрешить вывод средств', 'widget', translation, 'grid'
                        )}</P>}
                </Div>

            )
        case 'withdrawal':
            return (
                <Div styles={{}}
                    effects={{
                        hover: {
                            color: styles.variable.blueColor,
                            background: styles.variable.greyExtraLightColor
                        }
                    }}
                >
                    <P>{t(
                        'grid-WithdrawFunds', 'Вывести средствa', 'widget', translation, 'grid'
                    )}</P>
                </Div>
            )
        case 'user':
            return (
                <Div styles={{}}
                    effects={{
                        hover: {
                            color: styles.variable.blueColor,
                            background: styles.variable.greyExtraLightColor
                        }
                    }}
                >
                    <P>{t(
                        'grid-WithdrawFunds', 'Вывести средствa', 'widget', translation, 'grid'
                    )}</P>
                </Div>
            )
        case 'transfer':
            return (
                <Div styles={{}}
                    effects={{
                        hover: {
                            color: styles.variable.blueColor,
                            background: styles.variable.greyExtraLightColor
                        }
                    }}
                >
                    <P>{t(
                        'grid-TopUpAccount', 'Пополнить счет', 'widget', translation, 'grid'
                    )}</P>
                </Div>
            )
        case 'show_news_category':
            return defaultDiv(t(
                'grid-NewsAddSubCategory', 'Добавить подкатегорию', 'widget', translation, 'group'
            ))
        case 'update_news_category':
            return defaultDiv(<T textName='edit' defaultStr='Редактировать' page='all'/>)
        case 'destroy_news_category':
            return defaultDiv(<T textName='delete' defaultStr='Удалить' page='all'/>)
        case 'block_user':
            return defaultDiv(t(
                'grid-blockingCategory', 'Заблокировать', 'widget', translation, 'group'
            ))
        case 'unBlock_user':
            return defaultDiv(t(
                'grid-unblockingCategory', 'Разблокировать', 'widget', translation, 'group'
            ))
        case 'test_user_handler':
            return defaultDiv(t(
                'grid-test-actions', 'Test actions', 'widget', translation, 'group'
            ))
        case 'disableTwoFa':
            return defaultDiv(<T textName='enable_two_fa_button' defaultStr='Отключить 2фа' page='all'/>)
        case 'allow_withdrawal':
            return defaultDiv(t(
                'grid-allow_withdrawal', 'Действия с договором', 'widget', translation, 'group'
            ))
        case 'update_user':
            return defaultDiv(<T textName='edit' defaultStr='Редактировать' page='all'/>)
        case 'destroy_user':
            return defaultDiv(<T textName='delete' defaultStr='Удалить' page='all'/>)
        case 'show_user_transaction':
            return defaultDiv(t(
                'grid-show_user_transaction', 'Показать финансовые транзакции', 'widget', translation, 'group'
            ))
        case 'pagemanagement_view':
            return defaultDiv(t(
                'grid-pagemanagement_view', 'Просмотр', 'widget', translation, 'group'
            ))
        case 'pagemanagement_copy':
            return defaultDiv(t(
                'grid-pagemanagement_copy', 'Дублировать', 'widget', translation, 'group'
            ))
        case 'pagemanagement_edit':
            return defaultDiv(<T textName='edit' defaultStr='Редактировать' page='all'/>)
        case 'pagemanagement_delete':
            return defaultDiv(<T textName='delete' defaultStr='Удалить' page='all'/>)
        case 'replenishment':
            return defaultDiv(<T textName='create_user_transfer' defaultStr='Создать поступление' page='all' />)
        case 'user_transfer':
            return defaultDiv(<T textName='transfer_money' defaultStr='Перевод средств' page='all' />)
        case 'expense':
            return defaultDiv(<T textName='create_expense' defaultStr='Создать расход' page='all' />)
        default:
            return button;
        }
    }

    const func = useCallback(() => {
        if (modal?.current) {
            const dropDownHeight = modal?.current?.getBoundingClientRect()?.height;
            const dropDownWidth = modal?.current?.getBoundingClientRect()?.width;
            const top = modalDropdown?.current?.getBoundingClientRect()?.top - refTable?.current.getBoundingClientRect()?.top - 10;
            const bottom = modalDropdown?.current?.getBoundingClientRect()?.top + modalDropdown?.current?.getBoundingClientRect()?.height - refTable?.current.getBoundingClientRect()?.top - dropDownHeight + 10;
            const left = modalDropdown?.current?.getBoundingClientRect()?.left - refTable?.current.getBoundingClientRect()?.left - dropDownWidth - 20;
            const limitBottom = refTableChild?.current.getBoundingClientRect()?.height;

            if (top > limitBottom) {
                modal.current.style.top = `${bottom}px`;
            } else {
                modal.current.style.top = `${top}px`;
            }
            modal.current.style.left = `${left}px`;
        }
    }, [modal, modalDropdown, refTable, refTableChild])

    useEffect(() => {
        const refTableChildVar = refTableChild?.current;
        func()
        if (refTableChildVar) {
            refTableChildVar.addEventListener('scroll', func)
        }

        return () => {
            refTableChildVar?.removeEventListener('scroll', func)
        }
    }, [func, refTableChild])

    return (
        <Div
            childrenRef={modal}
            styles={showDropdown ? {
                position: 'absolute',
                padding: '10px',
                'border-radius': '12px',
                background: styles.variable.whiteColor,
                'z-index': '100000',
                '-webkit-appearance': 'none',
                '-moz-appearance': 'none',
                'appearance': 'none',
                'box-shadow': `0px 10px 100px ${styles.variable.blackTransparentColor}`
            } : { display: 'none' }}
        >
            {val ? Object.keys(val).filter((k) => k !== 'id' && k !== 'settings')
                .map((item, index) =>
                    <WrapperButton
                        key={`ActionBtn_${index}`}
                        {...getHandlers(item)}
                        styles={{
                            'min-width': '252px',
                            'border-radius': '8px',
                            ...uikit.text,
                            'padding': '10px'
                        }}
                        effects={{
                            hover:
                                {
                                    background: styles.variable.greyExtraLightColor,
                                    cursor: 'pointer'
                                }
                        }}
                    >
                        <P>
                            {StylesButton(item)}
                        </P>
                    </WrapperButton>) : null}
        </Div>
    )
}


const Actions = ({
    gridStyles,
    getHandlers,
    styles,
    val,
    name,
    refTable,
    refTableChild,
    gridName,
    translation
}) => {
    const ref = useRef(false)
    const modal = useRef(null);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (showModal && ref.current && !ref.current.contains(e.target)) {
                setShowModal(false)
            }
        }

        document.addEventListener('click', checkIfClickedOutside)
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [showModal])
    const isOptions = Object.keys(val).filter(key => key !== 'id' && key !== 'settings').length;
    return (
        <Div
            ref={ref}
            styles={gridStyles[name]?.wrapper?.styles || {
                ...styles.flexRow, ...styles.alignItemsCenter, ...styles.justifyContentCenter
            }}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            {
                isOptions ?
                    <Div
                        data-uitest='611_uitest' onClick={() => {
                            setShowModal((pre) => !pre)
                        }}
                        childrenRef={ref}
                        effects={{
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }}
                        styles={{ ...gridStyles[name]?.destroyIcoWrap?.styles || styles.tableButton, ...showModal ? styles.tableButtonHover : {} }}

                    >
                        {
                            svgSprite('dots-horisontal',
                                gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                        }
                    </Div> : null
            }
            {
                !!showModal &&
                document.getElementById(`${gridName}portalDropdown`) &&
                ReactDOM.createPortal(<ModalDropDown
                    showDropdown={showModal}
                    modal={modal}
                    styles={styles}
                    refTable={refTable}
                    getHandlers={getHandlers}
                    modalDropdown={ref}
                    val={val}
                    refTableChild={refTableChild}
                    translation={translation}
                />, document?.getElementById(`${gridName}portalDropdown`))
            }
        </Div>
    );
};

Actions.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    styles: PropTypes.object,
    name: PropTypes.string
};

export default Actions;