// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import { P, Div, Tooltip } from '../../../hybrid/wrappers';
import GridTheadSort from './sort/GridTheadSort';

import {
    TheadComponent, TrHeadComponent, TdHeadComponent
} from './wrappers'

const GridThead = ({
    styles,
    gridName,
    cellThAddButton,
    widthArr,
    thead,
    gridStyles,
    convertData,
    setGridData,
    rowRef,
    sticky
}) => {
    const getCustomStyles = (name, index) => gridStyles[name] || gridStyles[index] || {};
    return (
        <TheadComponent sticky={sticky}>
            <TrHeadComponent rowRef={rowRef} sticky={sticky} styles={{ ...styles.rowThWeb, ...gridStyles?.th }}>
                {
                    thead.map((data, idx) =>
                        <TdHeadComponent
                            sticky={sticky}
                            key={`${gridName}th${idx}`}
                            styles={{
                                ...widthArr[idx] || {},
                                ...sticky?.leftStickyColumnCount
                                    ? styles.thSticky
                                    : styles.th,
                                ...gridStyles.th?.styles || {},
                                ...thead.length === idx + 1
                                    ? styles.tdLast
                                    : {}
                                ,
                                ...getCustomStyles(data.name, idx).th?.styles || {}
                            }}

                            effects={getCustomStyles(data.name, idx).th?.effects || {}}
                        >
                            <Div
                                styles={{
                                    ...styles.thContent,
                                    ...idx === 0 && !['center', 'right'].includes(data.align) || data.align === 'left'
                                        ? styles.tdAlignLeft
                                        : data.align === 'right' ? styles.tdAlignRight : styles.tdAlignCenter,
                                    ...gridStyles.th?.div || {},
                                    ...getCustomStyles(data.name, idx).th?.div || {},
                                    ...data.nowrap?.th ? { 'white-space': 'nowrap' } : {},
                                    ...data.nowrap?.th === false ? { 'white-space': 'wrap' } : {}
                                }}
                                effects={getCustomStyles(data.name, idx).th?.div?.effects || {}}

                                {...(
                                    !!cellThAddButton && !!cellThAddButton[idx] && !!cellThAddButton[idx].handlers
                                        ? cellThAddButton[idx].handlers
                                        : {}
                                )}
                            >
                                <P effects={getCustomStyles(data.name, idx).th?.p?.effects || {}} >
                                    {data?.tooltip ?
                                        <Tooltip
                                        text={data.tooltip}
                                        styles={styles}
                                        >
                                        {data.title}
                                        </Tooltip> : data.title}
                                </P>

                                {
                                    !!cellThAddButton && !!cellThAddButton[idx] && !!cellThAddButton[idx].comp
                                && cellThAddButton[idx].comp
                                }
                                {data.sort && <GridTheadSort
                                    styles={styles}
                                    setGridData={setGridData}
                                    convertData={convertData}
                                    data={data}
                                    thead={thead}
                                />}
                            </Div>
                        </TdHeadComponent>)
                }
            </TrHeadComponent>
        </TheadComponent>
    )
};

export default GridThead;