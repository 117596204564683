import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Div, svgSprite } from '../wrappers';
import uikit from '../../project/uikit/styles';
import { UserProfileContext } from '../../common/utils/getContext';
import {
    admin, main, office
} from '../../project/uikit/color-variables';

const FieldErrorElement = ({
    children,
    styles
}) => {
    const { adminPage, officePage } = useContext(UserProfileContext) || {};
    const variables = adminPage ? admin : officePage ? office : main;

    const innerStyles = {
        errorWrapper: {
            display: 'flex',
            'align-items': 'center'
        },
        errorIcon: {
            width: '12px',
            height: '16px',
            marginRight: '6px',
            fill: styles?.variable?.redColor || variables?.redColor,
            flexShrink: 0,
            padding: '2px 0'
        },
        errorText: {
            ...uikit.tiny,
            color: styles?.variable?.redColor || variables?.redColor,
            'word-break': 'break-word'
        }
    };

    return (
        <Div styles={{
            'margin-top': '4px', ...innerStyles.errorWrapper, ...styles?.wrapper || {}
        }}>
            {svgSprite('warning-circle', { style: innerStyles.errorIcon })}
            <Div styles={innerStyles.errorText}>
                {children}
            </Div>
        </Div>
    );
};

FieldErrorElement.propTypes = { styles: PropTypes.object };

export default FieldErrorElement;