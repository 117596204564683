// @generated: @expo/next-adapter@2.1.5
import React, {
    useCallback, useContext, useMemo, useState, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import { Div, Portal } from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/search-window-styles';
import Styles from '../../common/decorators/Styles';
import FormComponent from '../form/FormComponent';
import { getUserTable } from './initData';
import { sendFunction } from './utils';
import UserContext, { UserProfileContext } from '../../common/utils/getContext';
import SearchWindowHead from './SearchWindowHead';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import T, { t } from '../../common/components/T';
import { getWidgetTranslates } from '../../common/utils/utils';
import { LangContext } from '../../common/decorators/Language';

const SearchWindow = ({
    styles,
    closeModal,
    sendData,
    title,
    customData,
    gridHandlers,
    gridConfig = {},
    portalClass,
    fixedWidth,
    transformSearchData,
    thead,
    customStyles,
    input = {}
}) => {
    const widthDimensions = useWindowDimensions();
    const screenWidth = useMemo(() => widthDimensions < 768, [widthDimensions]);
    const {
        userToken, setAlertData, lang
    } = useContext(UserContext);
    const { profileData } = useContext(UserProfileContext) || {};
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    const [trans, setTranslates] = useState(null);
    useEffect(() => {
        getWidgetTranslates(lang, 'searchWindow', translation, setTranslates);
    }, [lang, translation])

    const [userTableData, setUserTableData] = useState(false);

    const sender = useCallback((val) => {
        sendFunction(
            val, sendData, setUserTableData, userToken, setAlertData, lang, transformSearchData, profileData?.data?.data?.id
        );
    }, [sendData, userToken, setAlertData, lang, transformSearchData, profileData?.data?.data?.id]);

    useEffect(() => {
        sender('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const interval = useRef(null);
    const searchInputFunc = useCallback((val) => {
        clearInterval(interval.current);
        interval.current = setInterval(() => {
            sender(val);
            clearInterval(interval.current);
        }, 500);
    }, [interval, sender]);

    const userTable = () =>
        getUserTable(
            userTableData, styles, gridHandlers, gridConfig, thead, transformSearchData, trans
        );
    return (
        <Portal closeHandler={closeModal} customClass={portalClass} fullWidth={screenWidth} fullHeight={screenWidth}>
            <Div
                styles={{
                    ...styles.searchWindowWrapper, ...fixedWidth, ...customStyles?.searchWindowWrapper
                }}
                // data-uitest='676_uitest' onClick={(e) => e.stopPropagation()}
            >
                <SearchWindowHead title={title} styles={styles} closeModal={closeModal} customStyles={customStyles}/>

                <Div styles={styles.searchWindowTableWrapper}>
                    <FormComponent
                        data={[
                            {
                                attribute: 'search',
                                disabled: false,
                                hint: false,
                                name: 'search',
                                placeholder: t(
                                    'SearchWindowsearchplaceholder', 'Введите данные пользователя', 'widget', trans, 'searchWindow'
                                ),
                                type: 'input',
                                search: { type: 'onInput' },
                                required: false,
                                info: <T textName='SearchWindowsearchinfo-v1' page='widget' group='searchWindow' defaultStr='Введите фамилию / Имя / ID пользователя' customTranslates={trans} customLoading={!trans}/>,
                                title: {
                                    body: <T textName='SearchWindowsearchtitle' page='widget' group='searchWindow'
                                        defaultStr='Поиск пользователя' customTranslates={trans}
                                        customLoading={!trans}/>,
                                    links: false
                                },
                                ...input
                            }
                        ]}

                        funcs={{ search: { onChange: (value) => searchInputFunc(value) } }}
                        add={[{ position: 'middle', comp: userTable }]}
                        buttons={[
                            {
                                type: 'ok',
                                buttonText: customData?.buttonText
                            },
                            {
                                type: 'cancel',
                                buttonText: customData?.cancelText,
                                clickFunc: () => closeModal()
                            }
                        ]}
                        buttonNotShow={customData?.buttonNotShow}
                    />
                </Div>
            </Div>
        </Portal>
    );
};
SearchWindow.propTypes = {
    styles: PropTypes.object,
    closeModal: PropTypes.func,
    sendData: PropTypes.object,
    title: PropTypes.node,
    customData: PropTypes.object,
    gridHandlers: PropTypes.object,
    gridConfig: PropTypes.object,
    portalClass: PropTypes.string,
    fixedWidth: PropTypes.bool,
    transformSearchData: PropTypes.func,
    thead: PropTypes.object,
    customStyles: PropTypes.object,
    input: PropTypes.object
};
export default Styles(SearchWindow, getStyles);