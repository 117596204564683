import PhoneInput from 'react-phone-input-2';
import {
    Div, Input, P, svgSprite, Tooltip
} from '../../../../../hybrid/wrappers';
import FromInputTitle from '../../FormFieldTitle';
import { getIsCoupleVariation, getPlaceholderColor } from '../../helpers';
import { getInputStyles } from '../helpers';
import CopyType from './CopyType';
import ModalSeachType from './ModalSeachType';
import PasswordType from './PasswordType';

const InputSwitcher = (props) => {
    const {
        attribute,
        hint,
        placeholder,
        required,
        readonly,
        disabled,
        title,
        compStyle = {},
        type = 'input',
        styles,
        errors,
        message,
        search,
        addon,
        copy,
        tooltip,
        customData = {},
        buttons = {},
        placeholderError,
        keyword,
        changeHandler,
        variationStyle,
        val,
        focused,
        componentConfig,
        setShowPass,
        showPass,
        addonLast
    } = props;

    switch (keyword) {
    case 'search':
        return search && search?.type === 'modal' ? <ModalSeachType
            search={search}
            styles={styles}
            changeHandler={changeHandler}
        /> : search ? <Div
            data-uitest='598_uitest' onClick={search?.onClick}
            styles={{ ...styles.formControlIconWrap, ...compStyle?.search }}
        >
            {svgSprite(search?.icon || 'search', { style: { ...styles.formControlSearchIconSvg, ...compStyle.icon } })}
        </Div> : null;
    case 'input':
        return <Div
            styles={{ ...styles.formControlInner, ...compStyle.inner }}
        >
            {
                !!title && getIsCoupleVariation(variationStyle) ?
                    <FromInputTitle
                        id={`input_${attribute}`}
                        styles={styles}
                        errors={errors}
                        title={title}

                        compStyle={compStyle}
                        hint={hint}
                        required={required}
                        isFilled={Boolean(val)}
                        variationStyle={variationStyle}
                        focused={focused}
                    /> : null

            }
            <Tooltip text={tooltip} styles={{
                ...styles.formControlContentAddon,
                ...addon ? styles.formControlContentAddonScroll : {},
                ...type === 'phone-widget' ? { tooltipWrapCustom: { position: 'static' } } : {}
            }}>
                {
                    !!customData?.isSocial
                        ? <Div
                            styles={{ ...styles.formControlIconWrap, ...styles.formControlIconSotialWrap }}>
                            {svgSprite('at', {
                                style: {
                                    ...styles.formControlSocialIconSvg,
                                    ...compStyle?.social,
                                    fill: errors && errors.length ? styles.variable.redColor : focused ? styles.variable.blueColor : styles.variable.greyExtraDarkColor
                                }
                            })}
                        </Div> : null
                }
                {!!addon && !addonLast ? <Div styles={{ ...styles.formControlAddon, ...compStyle?.addon, ...String(addon || '')?.toLowerCase() === 'et' ? { 'background-color': 'unset' } : {} }}>{addon}</Div> : null}
                {
                    type === 'phone-widget' ?
                        <Div styles={getInputStyles(
                            variationStyle, styles, compStyle, errors, focused, disabled
                        )}>
                            <PhoneInput {...componentConfig}/>
                        </Div>
                        :
                        <Div styles={styles.formControlContentPlaceholder}>
                            <Input {...componentConfig} />
                            {!!placeholder && !val && (!focused || readonly) ?
                                <Div className='hideOnAutofill' styles={{ ...styles.formControlInnerPlaceholder, ...compStyle?.placeholderWrapper }}>
                                    <Div styles={{
                                        ...getPlaceholderColor(
                                            variationStyle, styles, compStyle, errors, focused, disabled
                                        ), ...compStyle?.placeholder, ...errors ? compStyle?.placeholderError : {}
                                    }}>{errors ? placeholderError : placeholder}</Div>
                                    { !!required && !title && <P styles={{
                                        ...styles.formControlRequiredMark, ...compStyle?.requiredMark, ...!errors && focused ? { ...styles.formControlRequiredMarkFocus, ...compStyle?.requiredMarkFocus } : {}
                                    }}>*</P>}
                                </Div>
                                : null}
                        </Div>
                }
                {!!addon && addonLast ? <Div styles={{ ...styles.formControlAddon, ...compStyle?.addon }}>{addon}</Div> : null}
            </Tooltip>
        </Div>;
    case 'password':
        return type === 'password'
            ? <PasswordType
                styles={styles} setShowPass={setShowPass} showPass={showPass}
                compStyle={compStyle}/> : null;
    case 'copy':
        return copy
            ?
            <CopyType
                styles={styles}
                copy={copy}
                message={message}
                val={val}
                compStyle={compStyle}
            /> : null;
    default:
        if (buttons[keyword]){
            const btn = buttons[keyword];
            return <Div
                data-uitest='599_uitest' onClick={btn.handler}
                effects={{ hover: { opacity: .7 } }}
                styles={{...compStyle?.[keyword]?.buttonWrapper}}
            >
                <Tooltip
                    text={btn.hint}
                    styles={{ ...styles.formControlIconWrap, ...compStyle?.[keyword]?.button }}>
                    {btn.icon ? svgSprite(btn.icon, {
                        width: '20px',
                        height: '20px',
                        fill: styles.variable.greyExtraDarkColor,
                        ...compStyle?.[keyword]?.icon
                    }) : btn.name}
                </Tooltip>
            </Div>;
        }
        return null;
    }
}
export default InputSwitcher;