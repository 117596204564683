import { media } from '../../../common/utils/utils';
import officeStyles from './office/search-window-styles'
import mainStyles from './main/search-window-styles'
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    formCursorPointer: { 'cursor': 'pointer' },
    searchWindowWrapper: media(width, height, 'w', {
        992: {
            'max-width': '750px',
            'width': '750px',
            'border-radius': '20px',
            'background-color': variable.whiteColor
        },
        768: {
            'max-width': '750px',
            'width': '750px',
            'border-radius': '20px',
            'background-color': variable.whiteColor
        },
        0: {
            'border-radius': '0px',
            'padding-bottom': '0px',
            'background-color': variable.greyColor,
            'flex-grow': '1'
            // order: -1,
        }
    }),
    searchWindowTableWrapper: media(width, height, 'w', {
        992: {
            'align-items': 'center',
            padding: '24px 32px 1px',
            // height: `${height - 50}px`,
            'overflow-y': 'scroll'
        },
        768: {
            'align-items': 'center',
            padding: '12px 24px 1px 24px',
            height: `${height - 50}px`,
            'overflow-y': 'scroll'
        },
        0: {
            'border-radius': '20px 20px 0 0',
            'align-items': 'center',
            padding: '16px 12px 0',
            background: variable.whiteColor,
            height: `${height - 58}px`,
            'overflow-y': 'scroll',
            'overflow-x': 'hidden'
        }
    }),
    searchWindowTable: {
        'margin-bottom': '32px',
        'overflow': 'hidden'
    },
    searchTable: media(width, height, 'w', {
        768: { 'min-width': '686px' },
        0: {}
    }),
    searchWindowTitleBlock: media(width, height, 'w', {
        992: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'position': 'relative',
            'align-items': 'flex-start',
            padding: '36px 0 36px 64px',
            background: variable.whiteColor,
            'border-radius': '20px 0px 0px 0px'
        },
        768: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'position': 'relative',
            'align-items': 'flex-start',
            padding: '24px 0 24px 36px',
            background: variable.whiteColor,
            'border-radius': '20px 0px 0px 0px'
        },
        0: {
            'background-color': variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '10px',
            'margin-bottom': '12px',
            'box-shadow': `0px 2px 15px ${variable.blackExtraTransparentColor}`,
            'border-radius': '0px 0px 20px 20px'
        }
    }),
    searchWindowTitle: media(width, height, 'w', {
        992: {
            color: variable.blackColor,
            ...uikit.title,
            'text-align': 'center',
            'font-weight': 500
        },
        0: {
            color: variable.blackColor,
            ...uikit.title,
            'font-weight': 500
        }
    }),
    searchWindowTitleClose: media(width, height, 'w', {
        992: {
            'position': 'absolute',
            top: '32px',
            right: '32px'
        },
        768: {
            'position': 'absolute',
            top: '24px',
            right: '24px'
        },
        0: {}
    }),
    searchWindowTitleSvg: {
        fill: variable.darkColor,
        width: '12px',
        height: '12px'
    },
    noDataAlert: media(width, height, 'w', {
        768: {
            background: variable.greyTransparentHalfColor,
            'border-radius': '8px',
            padding: '16px'
        },
        0: {
            'text-align': 'center',
            background: variable.greyTransparentHalfColor,
            'border-radius': '8px',
            padding: '16px'
        }
    })

});
export default stylesheets;