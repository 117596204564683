// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, svgSprite } from '../../../hybrid/wrappers';

const Check = ({
    val, getHandlers, gridStyles, name, styles
}) => {
    if (typeof val === 'object' ? Object.keys(val)?.length > 0 : Boolean(val)) {
        return (
            <P
                {...getHandlers(false)}
                styles={gridStyles[name]?.wrapper?.styles || {}}
                effects={gridStyles[name]?.wrapper?.effects || {}}
            >
                {
                    svgSprite('check',
                        gridStyles[name]?.ico?.styles || {
                            fill: styles.variable.greenDeepColor,
                            width: '15px',
                            height: '10px'
                        })
                }
            </P>
        );
    }
    return (
        <P
            {...getHandlers(false)}
            styles={gridStyles[name]?.wrapper?.styles || {}}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            {
                svgSprite('plus',
                    gridStyles[name]?.ico?.styles || {
                        fill: styles.variable.greyExtraDarkColor,
                        width: '15px',
                        height: '15px'
                    })
            }
        </P>
    );
};

Check.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    name: PropTypes.string
};

export default Check;