// @generated: @expo/next-adapter@2.1.5
import React, {
    useContext, useState, useEffect
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    svgSprite, Div, Tooltip
} from '../../../../../hybrid/wrappers';
import UserContext from '../../../../../common/utils/getContext';
import QrCode from '../../../../qrCode/qrCode';
import { t } from '../../../../../common/components/T'
import { LangContext } from '../../../../../common/decorators/Language';
import { getWidgetTranslates } from '../../../../../common/utils/utils';
import SocialShare from '../../../../../hybrid/library/SocialShare';

const CopyType = ({
    styles, compStyle, copy, message, val
}) => {
    const { setAlertData, lang } = useContext(UserContext);
    const [qrModal, setQrModal] = useState(false);
    const [transl, setTranslates] = useState(null)
    const [modalOn, setModalOn] = useState(false)
    const [tooltipId, setTooltipId] = useState(false)

    const {
        qr, positionDropdown, hint, hideShare, onCopyFunc, copyVal
    } = copy;
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    useEffect(() => {
        if (!tooltipId) {
            setTooltipId(`${Math.floor(Math.random() * 10000000)}tooltip`);
        }
    }, [tooltipId]);


    useEffect(() => {
        // Знаходження елемента за його id
        const element = document.getElementById(tooltipId);
    
        // Перевірка, чи елемент знайдено
        if (element && modalOn) {
          // Зміна стилів елемента
          element.style.display = 'none';
        } 
      }, [modalOn]); 

      const onMouseOverFunc = () => {
        if(!modalOn){
            const element = document.getElementById(tooltipId);
            element.style.display = 'block';
        }
      }
    return (
        <>
            {
                qr ?
                    <Tooltip
                        place='top'
                        text={t('copyqr-title-text', 'Скопируйте QR-код', 'widget', transl, 'form')}
                    >
                        <Div
                            data-uitest='596_uitest' onClick={() => { setQrModal(true) }}
                            styles={{ ...styles.formControlIconWrap, ...compStyle?.copy || {} }}>
                            {
                                svgSprite('qr', {
                                    width: '19px',
                                    height: '20px',
                                    fill: styles.variable.blackColor
                                })
                            }
                        </Div>
                        {
                            qrModal &&
                          <QrCode title={qr} closeHandler={setQrModal} val={val}/>

                        }
                    </Tooltip>
                    : null
            }

            <CopyToClipboard
                text={copyVal || val}
                onCopy={() => {
                    if (onCopyFunc) {
                        onCopyFunc();
                    } else {
                        setAlertData({
                            type: 'success', cont: message?.copy || message?.success || t(
                                'FormCopy-CopyReferalLink-success', 'Скопировано в буфер обмена', 'widget', transl, 'form'
                            )
                        })
                    }
                }}
            >
                <Div>
                    <Tooltip styles={{ ...styles.formControlIconWrap, ...compStyle?.copy || {} }} text={hint || t(
                        'FormCopy-CopyReferalLink-v1', 'Скопировать ссылку', 'widget', transl, 'form'
                    )}>
                        {svgSprite('file-copy', {
                            width: '20px',
                            height: '20px',
                            fill: styles.variable.blackColor
                        })}
                    </Tooltip>
                </Div>
            </CopyToClipboard>

            {!hideShare
            &&
            <Tooltip
                place='top'
                text={t('copysocial-title-text-v1', 'Поделиться ссылкой в соцсетях', 'widget', transl, 'form')}
                tooltipId={tooltipId}
            >
                <div onMouseOver={onMouseOverFunc}>
                    <SocialShare setModalOn={setModalOn} btnStyles={{ ...styles.formControlIconWrap, ...compStyle?.copy || {} }} title={qr} val={val} positionDropdown={positionDropdown}/>
                </div>
            </Tooltip>
            }

        </>
    );
};

export default CopyType;