import React from 'react';
import PropTypes from 'prop-types';
import { Div, svgSprite } from '../wrappers';
import uikit from '../../project/uikit/styles';

const FieldInfoElement = ({
    children,
    styles,
    compStyle,
    infoRed
}) => {
    const innerStyles = {
        errorWrapper: {
            display: 'flex',
            'align-items': 'flex-start',
            'margin-top': '8px',
            ...infoRed ? {
                'padding-left': '16px'
            } : {}
        },
        errorIcon: {
            width: '12px',
            height: '16px',
            marginRight: '6px',
            fill: styles.variable.calcColor('black', .6),
            flexShrink: 0,
            padding: '2px 0'
        },
        errorText: {
            ...uikit.tiny,
            'flex-grow': '1',
            color: styles.variable.calcColor('black', .6),
            ...infoRed ? {
                color: styles.variable.errorColor,
                'font-weight': '500'
            } : {}
        }
    };
    return (
        <Div styles={{
            ...innerStyles.errorWrapper, ...styles?.wrapper, ...compStyle?.errorWrapper || {}
        }}>
            {
                !infoRed ? svgSprite('warning-circle', {
                    style: {
                        ...innerStyles.errorIcon, ...styles?.icon, ...compStyle?.icon || {}
                    }
                }) : null
            }
            <Div styles={{
                ...innerStyles.errorText, ...styles?.text, ...compStyle?.text || {}
            }}>
                {children}
            </Div>
        </Div>
    );
};

FieldInfoElement.propTypes = {
    styles: PropTypes.object,
    compStyle: PropTypes.object
};

export default FieldInfoElement;