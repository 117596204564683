

const defaultColors = {
    white: '255, 255, 255',
    black: '0, 0, 0',
    dark: '15, 19, 36',
    greyLight: '240, 241, 245',
    greyExtraDark: '143, 143, 171',
    blue: '44, 104, 137',
    blueDark: '40, 35, 89',
    blueDeep: '73, 109, 247',
    purple: '161, 46, 225',
    purpleDeep: '160, 67, 192',
    red: '255, 69, 58',
    redLight: '237, 68, 100',
    redDark: '217, 71, 99',
    orangeDeep: '249, 137, 55',
    orange: '250, 176, 49',
    green: '42, 185, 116',
    greenDeep: '52, 199, 89',
    greenLight: '16, 181, 106',
    error: '209, 44, 74',
    success: '88, 174, 36',
    project: '44,104,137'
}
const colors = {
    main: { ...defaultColors },
    office: { ...defaultColors },
    admin: { ...defaultColors }
}
const getCustomTransparentFunc = (part) => (color, opacity) => `rgba(${colors[part][color]}, ${opacity})`

const variables = (part) => {
    const customTransparent = getCustomTransparentFunc(part)
    return {
        calcColor: customTransparent,

        // White / black variables
        whiteColor: customTransparent('white', '1'),
        whiteLessTransparentColor: customTransparent('white', '0.2'),
        whiteTransparentColor: customTransparent('white', '0.11'),
        darkDeepColor: 'rgba(14, 12, 32, 1)',
        whiteMoreTransparentColor: customTransparent('white', '0.5'),
        whiteExtraTransparentColor: customTransparent('white', '0.7'),
        blackColor: customTransparent('black', '1'),
        blackHalfTransparentColor: customTransparent('black', '0.5'),
        blackTransparentColor: customTransparent('black', '0.2'),
        blackExtraTransparentColor: customTransparent('black', '0.14'),
        blackSuperTransparentColor: customTransparent('black', '0.06'),

        //Project color
        projectColor: customTransparent('project', '1'),

        // Dark variables
        darkLogoColor: 'rgba(23, 21, 33, 1)',
        darkBlueColor: 'rgba(37, 39, 51, 1)',
        darkColor: customTransparent('dark', '1'),
        darkMiddleTransparentColor: customTransparent('dark', '0.8'),
        darkTransparentColor: customTransparent('dark', '0.3'),
        darkHalfTransparentColor: customTransparent('dark', '0.6'),
        darkExtraTransparentColor: customTransparent('dark', '0.15'),
        darkMoreTransparentColor: customTransparent('dark', '0.04'),
        darkSuperTransparentColor: customTransparent('dark', '0.06'),
        darkGreyColor: 'rgba(40, 44, 52, 1)',
        darkExtraBlueColor: 'rgba(28, 30, 38, 1)',

        // Blue variables
        blueColor: customTransparent('blue', '1'),
        blueColor2: 'rgba(26, 62, 82)',
        blueExtraLightColor: 'rgba(176, 193, 255, 1)',
        blueDarkColor: customTransparent('blueDark', '1'),
        blueBackgroundLight: 'rgba(245, 248, 255, 1)',
        blueDeepAddColor: 'rgba(107, 135, 239, 1)',
        blueSkyColor: 'rgba(33, 175, 206, 1)',
        blueSkyTransparentColor: 'rgba(33, 175, 206, 0.12)',
        blueSkyHalfTransparentColor: 'rgba(3, 155, 229, 0.5)',
        blueSeaColor: 'rgba(73, 216, 247, 0.75)',
        blueDeepColor: 'rgba(54, 202, 235, 1)',
        blueDeepLightColor: customTransparent('blueDeep', '1'),
        blueDeepLightHalfTransparentColor: customTransparent('blueDeep', '0.75'),
        blueDeepLightTransparentColor: customTransparent('blueDeep', '0.15'),
        blueLightColor: 'rgba(70, 128, 194, 1)',
        blueTotalGray: 'rgba(63, 66, 80, 1)',
        blueTotalGrayTransparent: 'rgba(63, 66, 80, 0.2)',
        blueHalfTransparentColor: customTransparent('blue', '0.5'),
        blueTransparentColor: customTransparent('blue', '0.15'),
        blueColorTransparent: customTransparent('blue', '0.12'),
        blueExtraTransparentColor: customTransparent('blue', '0.06'),
        blueDarkTransparentColor: customTransparent('blueDark', '0.12'),
        blueDarkExtraTransparentColor: customTransparent('blueDark', '0.06'),
        bluePurpleColor: 'rgba(102, 92, 172, 1)',
        blueGradient: `linear-gradient(108.49deg, rgba(107, 135, 239, 1) 0%, ${customTransparent('blue', '1')} 100%)`,
        blueGradient1: `linear-gradient(108.49deg, rgba(121, 143, 223, 1) 0%, ${customTransparent('blue', '1')} 100%)`,
        blueGradient2: 'linear-gradient(90deg, #0B4462 0%, rgba(11, 68, 98, 0.32) 100%)',

        // Grey variables
        greyDarkColor: 'rgba(149, 148, 149, 1)',
        greyDarkTransparentColor: 'rgba(12, 19, 36, 0.12)',
        greyTransparentColor: 'rgba(143, 143, 172, 0.12)',
        greyShadowColor: 'rgba(20, 21, 22, 0.15)',
        greySuperDarkColor: 'rgba(111, 113, 124, 1)',
        greySuperDarkAltColor: 'rgba(138, 138, 138, 1)',
        greyUltraLightColor: 'rgba(244, 244, 247, 1)',
        greyExtraDarkAltColor: 'rgba(87, 90, 102, 1)',
        greyExtraDarkColor: customTransparent('greyExtraDark', '1'),
        greyExtraDarkColorTransparent: customTransparent('greyExtraDark', '0.1'),
        greyExtraDarkHalfTransparentColor: customTransparent('greyExtraDark', '0.6'),
        greyExtraDarkMHalfTransparentColor: customTransparent('greyExtraDark', '0.5'),
        greyExtraDarkTransparentColor: customTransparent('greyExtraDark', '0.08'),
        greySuperExtraDarkTransparentColor: customTransparent('greyExtraDark', '0.05'),
        greyExtraDarkExtraTransparentColor: customTransparent('greyExtraDark', '0.01'),
        greyDeepExtraDarkColor: 'rgba(199, 204, 218, 1)',
        greyDeepDarkColor: 'rgba(209, 215, 226, 1)',
        greyDeepLightColor: 'rgba(206, 206, 218, 1)',
        greyDeepColor: 'rgba(204, 204, 204, 1)',
        greyDeepExtraLightColor: 'rgba(229, 229, 233, 1)',
        greyDeepAltExtraLightColor: 'rgba(233, 233, 238, 1)',
        greyBlueDarkColor: 'rgba(198, 217, 255, 1)',
        greyBlueColor: 'rgba(201, 234, 218, 1)',
        greyBlueLightColor: 'rgba(209, 236, 255, 1)',
        greyBlueSuperLightColor: 'rgba(212, 241, 227, 1)',
        greyMainColor: 'rgba(231, 231, 233, 1)',
        greyColor: 'rgba(164, 179, 202, 1)',
        greyColor2: 'rgba(213, 225, 231, 1)',
        greyColor3: 'rgba(240, 245, 247, 1)',
        greyColor4: 'rgba(230, 235, 237, 1)',
        greyColor5: 'rgba(239, 246, 249, 1)',
        greyColor6: 'rgba(229, 238, 243, 1)',
        greyTransparentHalfColor: 'rgba(238, 240, 250, 0.4)',
        greyLightColor: customTransparent('greyLight', '1'),
        greyExtraLightColor: 'rgba(240, 245, 247, 1)', //changed
        greySuperLightColor: 'rgba(247, 248, 253, 1)',
        greyLightExtraColor: 'rgba(249, 249, 251, 1)',
        greyGreenColor: 'rgba(218, 251, 199, 1)',
        greyWhiteColor: 'rgba(254, 227, 255, 1)',
        beigeColor: 'rgba(255, 237, 189, 1)',
        greyExtra: 'rgba(159, 161, 167, 1)',
        greySkeletonLoader: 'rgba(232, 234, 238, 1)',
        greySkeletonButton: 'rgba(220, 221, 225, 1)',

        // Red variables
        redColor: customTransparent('red', '1'),
        redTransparentColor: customTransparent('red', '0.03'),
        redHalfTransparentColor: customTransparent('red', '0.7'),
        redLightColor: customTransparent('redLight', '1'),
        redExtraLightColor: 'rgba(255, 249, 249, 1)',
        redLightTransparentColor: customTransparent('redLight', '0.12'),
        redDarkColor: customTransparent('redDark', '1'),
        redDarkColorTransparent: customTransparent('redDark', '0.1'),
        redDarkExtraTransparent: customTransparent('redDark', '0.05'),
        redDeepColor: 'rgba(255, 0, 0, 1)',
        redGradient: `linear-gradient(90deg, ${customTransparent('redLight', '1')} 0%, rgba(243, 146, 164, 1) 100%)`,
        redBackground: customTransparent('redDark', '0.12'),

        // Purple variables
        purpleLightColor: 'rgba(113, 96, 235, 1)',
        purpleLightTransparentColor: 'rgba(113, 96, 235, 0.12)',
        purpleDarkColor: 'rgba(99, 42, 119, 1)',
        purpleColor: customTransparent('purple', '1'),
        purpleTransparentColor: customTransparent('purple', '0.15'),
        purpleDeepColor: customTransparent('purpleDeep', '1'),
        purpleDeepTransparentColor: customTransparent('purpleDeep', '0.12'),
        purpleDeepExtraTransparentColor: customTransparent('purpleDeep', '0.075'),
        purpleGradient: `linear-gradient(90deg, ${customTransparent('purple', '1')} 0%, rgba(220, 157, 255, 1) 100%)`,

        // Orange variables
        yellowColor: 'rgba(250, 255, 27, 1)',
        orangeDeepColorLight: customTransparent('orangeDeep', '1'),
        orangeDeepTransparentColorLight: customTransparent('orangeDeep', '0.12'),
        orangeDeepExtraTransparentColorLight: customTransparent('orangeDeep', '0.05'),
        orangeColor: customTransparent('orange', '1'),
        orangeAltColor: 'rgba(247, 147, 26, 1)',
        orangeDarkColor: 'rgba(238, 148, 42, 1)',
        orangeLightColor: 'rgba(241, 169, 84, 1)',
        orangeDeepColor: 'rgba(254, 133, 0, 1)',
        orangeHalfTransparentColor: 'rgba(254, 133, 0, 0.4)',
        orangeTransparentColor: 'rgba(254, 133, 0, 0.12)',
        orangeExtraTransparentColor: customTransparent('orange', ' 0.05'),
        orangeGradient: 'linear-gradient(108.49deg, rgba(255, 163, 63, 1) 0%, rgba(254, 133, 0, 1) 100%)',
        orangeGradient1: `linear-gradient(108.49deg, rgba(255, 166, 101, 1) 0%, ${customTransparent('orangeDeep', '1')} 100%)`,

        // Brown variables
        brownColor: 'rgba(160, 126, 102, 1)',

        // Green variables
        greenRegisterColor: 'rgba(60, 226, 77, 0.75)',
        greenDeepColor: customTransparent('greenDeep', '1'),
        greenDeepDarkColor: 'rgba(76, 175, 80, 1)',
        greenExtraTransparentColor: customTransparent('greenDeep', '0.08'),
        greenDeepTransparentColor: customTransparent('greenDeep', '0.05'),
        greenColor: customTransparent('green', '1'),
        greenGradientLightColor: 'rgba(116, 221, 170, 1)',
        greenLightColor: customTransparent('greenLight', '1'),
        greenLightHalfTransparentColor: customTransparent('greenLight', '0.75'),
        greenLightTransparentColor: customTransparent('greenLight', '0.2'),
        greenLightExtraTransparentColor: customTransparent('greenLight', '0.12'),
        greenHalfTransparentColor: customTransparent('greenLight', '0.4'),
        greenTransparentColor: customTransparent('green', '0.12'),
        greenExtraSuperTransparentColor: customTransparent('green', '0.075'),
        greenSuperExtraTransparentColor: customTransparent('green', '0.03'),
        greenGradient: `linear-gradient(90deg, ${customTransparent('green', '1')} 0%, rgba(147, 224, 187, 1) 100%)`,
        greenGradient1: `linear-gradient(90deg, ${customTransparent('green', '1')} 0%, rgba(29, 157, 96, 1) 100%)`,

        // Backgrounds variables
        mainWrapperMenuBackground: `linear-gradient(122.71deg, ${customTransparent('blueDeep', '1')} 0%, rgba(73, 216, 247, 1) 100%)`,
        mainWrapperWebBackground: 'linear-gradient(108.49deg, rgba(86, 152, 186, 0.75) 0%, rgba(11, 68, 98, 0.97) 100%)',
        mainWrapperWebRegisterBackground: `linear-gradient(108.49deg, ${customTransparent('greenLight', '0.75')} 0%, rgba(60, 226, 77, 0.75) 100%)`,
        mainWrapperWebRestoreBackground: `linear-gradient(108.49deg, ${customTransparent('purple', '0.75')} 0%, rgba(255, 63, 97, 0.75) 100%)`,

        marketingProgressGradient: 'linear-gradient(90deg, rgba(230, 235, 255, 1) 0%, rgba(169, 185, 245, 1) 100%)',

        chartColor1: 'rgba(4, 10, 14, 1)',
        chartColor2: 'rgba(17, 47, 63, 1)',
        chartColor3: 'rgba(26, 62, 82, 1)',
        chartColor4: 'rgba(44, 104, 137, 1)',
        chartColor5: 'rgba(118, 159, 181, 1)',
        chartColor6: 'rgba(174, 200, 214, 1)',
        chartColor7: 'rgba(211, 227, 236, 1)',
        chartColor8: 'rgba(4, 10, 14, 1)',
        chartColor9: 'rgba(17, 47, 63, 1)',
        chartColor10: 'rgba(26, 62, 82, 1)',
        chartColor11: 'rgba(44, 104, 137, 1)',
        chartColor12: 'rgba(118, 159, 181, 1)',

        //error colors
        errorColor: customTransparent('error', 1),
        successColor: customTransparent('success', 1)
    }
}
export const rankColors = [
    {
        back: '#EDEEE0',
        color: '#dbdcde',
        gradient: 'rgba(164, 179, 202, 1)'
    },
    {
        back: '#EDEEE0',
        color: '#dbdcde',
        gradient: 'rgba(164, 179, 202, 1)'
    },
    {
        back: '#E0EEE4',
        color: 'rgba(42, 185, 116, 1)',
        gradient: 'linear-gradient(90deg, rgba(42, 185, 116, 1) 0%, rgba(147, 224, 187, 1) 100%)'
    },
    {
        back: '#E0E9EE',
        color: 'rgba(54, 202, 235, 1)',
        gradient: 'linear-gradient(90deg, rgba(54, 202, 235, 1) 0%, rgba(54, 202, 235, 1) 100%)'
    },
    {
        back: '#E0E2EE',
        color: 'rgba(67, 95, 197, 1)',
        gradient: 'linear-gradient(108.49deg, rgba(107, 135, 239, 1) 0%, rgba(67, 95, 197, 1) 100%)'
    },
    {
        back: '#E5E0EE',
        color: 'rgba(161, 46, 225, 1)',
        gradient: 'linear-gradient(90deg, rgba(161, 46, 225, 1) 0%, rgba(220, 157, 255, 1) 100%)'
    },
    {
        back: '#EEE0E9',
        color: 'rgba(217, 71, 99, 1)',
        gradient: 'linear-gradient(90deg, rgba(217, 71, 99, 1) 0%, rgba(217, 71, 99, 1) 100%)'
    },
    {
        back: '#EEE0E0',
        color: 'rgba(255, 204, 0, 1)',
        gradient: 'linear-gradient(90deg, rgba(255, 228, 118, 1) 0%, rgba(255, 204, 0, 1) 100%)'
    },
    {
        back: '#FEF3E0',
        color: 'rgba(250, 176, 49, 1)',
        gradient: 'linear-gradient(90deg, rgba(250, 176, 49, 1) 0%, rgba(250, 176, 49, 1) 100%)'
    }
]

export const mainLinearGrad = 'linear-gradient(135deg, #F7FDFF 0%, #B9DDEA 100%)';

const chartStatsColors = (part) => ({
    chartStatsColor_1: variables(part).chartColor1,
    chartStatsColor_2: variables(part).chartColor2,
    chartStatsColor_3: variables(part).chartColor3,
    chartStatsColor_4: variables(part).chartColor4,
    chartStatsColor_5: variables(part).chartColor5,
    chartStatsColor_6: variables(part).chartColor6,
    chartStatsColor_7: variables(part).chartColor7,
    chartStatsColor_8: variables(part).chartColor8,
    chartStatsColor_9: variables(part).chartColor9,
    chartStatsColor_10: variables(part).chartColor10,
    chartStatsColor_11: variables(part).chartColor11,
    chartStatsColor_12: variables(part).chartColor12,
})
const officeVolumesColor = (part) => ({
    officeVolumesColor_0: variables(part).redLightColor,
    officeVolumesColorTransparent_0: variables(part).redLightTransparentColor,
    officeVolumesColor_1: variables(part).purpleColor,
    officeVolumesColorTransparent_1: variables(part).calcColor('purple', '0.12'),
    officeVolumesColor_2: variables(part).blueColor,
    officeVolumesColorTransparent_2: variables(part).blueColorTransparent,
    officeVolumesColor_3: variables(part).orangeDeepColor,
    officeVolumesColorTransparent_3: variables(part).orangeTransparentColor,
    officeVolumesColor_4: variables(part).greenColor,
    officeVolumesColorTransparent_4: variables(part).greenTransparentColor,
    officeVolumesColor_5: variables(part).blueSkyColor,
    officeVolumesColorTransparent_5: variables(part).blueSkyTransparentColor
})

export const main = {
    ...variables('main'),
    ...chartStatsColors('main'),
    ...officeVolumesColor('main'),
    logoSize: {
        desktop: {
            width: '192',
            height: '44'
        },
        mobile: {
            width: '192',
            height: '44'
        }
    },
    containerWidth: '1704px',
    menuLinkFontWeight: '500',
    authPanelPadding: '44px 40px 48px 40px',

    //Transitions
    transition: '0.1s all linear'

};

export const office = {
    officePage: true,
    ...variables('office'),
    ...chartStatsColors('office'),
    ...officeVolumesColor('office')
};

export const admin = {
    adminPage: true,
    ...variables('admin'),
    ...chartStatsColors('admin'),
    ...officeVolumesColor('admin'),
    logo: {
        size: {
            width: '280',
            height: '66'
        },
        fill: '#2E6D8E'
    }
};

export const progressSpeedColors = ['#EFF9FF', '#D3E3EC', '#AEC8D6'];

export const landingBg = 'url(../src/img/landing/hero/hero-bg.png) no-repeat top';