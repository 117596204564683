// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useEffect, useContext, useCallback, useRef
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment/moment'; // register it with the name you want
import PropTypes from 'prop-types';
import Styles from '../../../../../common/decorators/Styles';
import getStyles from '../../../../../project/styles/widget-styles/datepicker-range-styles';
import {
    useWindowDimensions, useWindowDimensionsHeight, useWindowScrollDimensions
} from '../../../../../common/utils/windowDimensions';
import UserContext from '../../../../../common/utils/getContext';
import {
    Button, Div, Label, P
} from '../../../../../hybrid/wrappers';
import { existLocales } from '../../../../../common/utils/dateLocales';
import T from '../../../../../common/components/T';
import ReactDOM from 'react-dom';
import FormDate from '../FormDate';
import FormInput from '../../form-input/FormInput';
import { LangContext } from '../../../../../common/decorators/Language';
import { getWidgetTranslates } from '../../../../../common/utils/utils';
import { checkIsPartner } from '../../../../../common/utils/memo';

const CustomDatePicker = ({
    styles, handleClose, setVal, pickerElement, mobileRangeDateCustom, config = {}, defaultFilter=false
}) => {
    const {
        lang, time, settings, userToken
    } = useContext(UserContext);
    const [langLocale, setLangLocale] = useState(lang);

    const [adminProfile, setAdminProfile] = useState(false)
    useEffect(() => {
        (async() => {
            const data = await checkIsPartner(userToken, lang);
            setAdminProfile(data?.data?.data)
        })()
    }, [userToken, lang])

    const modalElement = useRef()
    useEffect(() => {
        if (existLocales[lang]) {
            registerLocale(lang, existLocales[lang]);
            setLangLocale(lang);
        } else {
            (async () => {
                const locale = await import('date-fns/locale/ru');
                const localeDef = await locale;
                if (localeDef.default) {
                    registerLocale('ru', localeDef.default);
                    setLangLocale('ru');
                }
            })();
        }
    }, [lang])
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    const date = time().format('yyyy-MM-DD');
    const [trans, setTranslates] = useState(null);
    useEffect(() => {
        getWidgetTranslates(lang, 'datePickerRange', translation, setTranslates);
    }, [lang, translation])


    const [startDate, setStartDate] = useState(moment(date, 'yyyy-MM-DD').valueOf());
    const [endDate, setEndDate] = useState(moment(date, 'yyyy-MM-DD').valueOf());

    const handleChangeEndDate = (attr, date) => {
        setEndDate(date)
    }

    const handleChangeStartDate = (attr, date) => {
        setStartDate(date)
    }

    const isMobile = useWindowDimensions() < 768;

    const getInputValue = (data) => {
        if (Array.isArray(data)) {
            return {
                start: data[0],
                end: data[1]
            };
        }

        const datesArr = data.split('-');

        if (datesArr.length === 2) {
            return {
                start: datesArr[0],
                end: datesArr[1]
            };
        }

        return false;
    };

    const changeHandler = (v) => {
        const val = getInputValue(v);
        if (!val) return false;

        const { start, end } = val;
        setStartDate(start);
        setEndDate(end);
    };
    const dateFormat = useCallback((dating) => {
        switch (settings?.date_format){
        case '0':
            let string = dating.locale(lang).format('MMMM DD, YYYY')
            return string.charAt(0).toUpperCase() + string.slice(1);
        case '1':
            return dating.format('YYYY-MM-DD');
        case '2':
            return dating.format('MM/DD/YYYY');
        case '3':
            return dating.format('DD.MM.YYYY');
        default:
            return dating.format('DD.MM.YYYY')
        }
    }, [lang, settings]);

    const formatValue = (value) => {
        if (!value) return null
        const toMoment = moment(value);
        return toMoment.isValid() ? dateFormat(toMoment) : null
    }
    const heightDimensions = useWindowDimensionsHeight();
    const widthDimensions = useWindowDimensions();
    const mobile = widthDimensions < 768
    const formatDate = (d) => {
        if (!d || !moment(d).isValid()) { return; }

        return moment(d).format('yyyy-MM-DD')
    };

    const func = useCallback(() => {
        if (!mobile && modalElement.current){
            const picker = pickerElement.current.getBoundingClientRect();
            const modal = modalElement.current.getBoundingClientRect();
            let lPos = config?.left ? picker.left : picker.right - modal.width
            if (lPos < 0){
                lPos = picker.left;
            }
            let tPos = picker.top + window?.scrollY + picker.height + 10;
            if (tPos + modal.height > window?.scrollY + heightDimensions){
                tPos = picker.top + window?.scrollY - modal.height - 10;
                if (tPos < window?.scrollY){
                    tPos = window?.scrollY;
                }
            }
            modalElement.current.style.left = lPos + 'px';
            modalElement.current.style.top = tPos + 'px';
        } else if (mobile && modalElement.current){
            modalElement.current.style.left = '0';
            modalElement.current.style.top = '0';
        }
    }, [mobile, pickerElement, heightDimensions, config]);
    useEffect(() => {
        func()
    }, [func]);
    useEffect(() => {
        useWindowScrollDimensions(func)
        return () => {
            useWindowScrollDimensions(func, true)
        }
    }, [func]);

    return (
        <>
            {ReactDOM.createPortal(<>
                <Div data-uitest='6_uitest' onClick={() => { handleClose() }} styles={styles.customDatePickerBackdrop}/>
                <Div childrenRef={modalElement} styles={styles.customDatePickerRange}>
                    <Div styles={styles.containerDatePicker}>
                        <Div styles={styles.mainContainer}>
                            <Div styles={styles.horizontal}>
                                {mobileRangeDateCustom && isMobile ? <>
                                    <Div onClick={() => handleClose()} styles={styles.closeModal}></Div>
                                    <Div styles={styles.title}>
                                        <P styles={styles.titleText}><T textName='publication_date' defaultStr='Дата публикации' page='all' group='widget' /></P>
                                    </Div>
                                </> : null}
                                {mobileRangeDateCustom && isMobile ? null : <Div styles={{ ...styles.vertical, ...styles.containerButton }}>
                                    <Div data-uitest='7_uitest' onClick={() => {
                                        handleClose()
                                    }} styles={styles.containerDatePickerDecor} />
                                    <Div>
                                        {!mobileRangeDateCustom && mobile && <P styles={styles.customPickerTitle}><T textName='shown-period' defaultStr='Период отображения' page='all' group='widget' /></P>}
                                        <Div
                                            effects={{ hover: { opacity: 0.7, cursor: 'pointer' } }}
                                            styles={styles.menuButton}
                                            data-uitest='8_uitest' onClick={() => {
                                                setStartDate(time().valueOf());
                                                setEndDate(time().valueOf());
                                            }}
                                        >
                                            <T textName='datePickerRangeToday' defaultStr='За сегодня' page='widget'
                                                group='datePickerRange' customTranslates={trans} customLoading={!trans} />
                                        </Div>
                                    </Div>
                                    <Div>
                                        <Div
                                            effects={{ hover: { opacity: 0.7, cursor: 'pointer' } }}
                                            styles={styles.menuButton}
                                            data-uitest='9_uitest' onClick={() => {
                                                const d = time().add(-1, 'days');

                                                setStartDate(d.valueOf());
                                                setEndDate(d.valueOf());
                                            }}
                                        >
                                            <T textName='datePickerRangeYesterday' defaultStr='За вчера' page='widget'
                                                group='datePickerRange' customTranslates={trans} customLoading={!trans} />
                                        </Div>
                                    </Div>
                                    <Div>
                                        <Div
                                            effects={{ hover: { opacity: 0.7, cursor: 'pointer' } }}
                                            styles={styles.menuButton}
                                            data-uitest='10_uitest' onClick={() => {
                                                const d = time().add(-7, 'days');
                                                setStartDate(d.valueOf());
                                                setEndDate(time().valueOf());
                                            }}
                                        >
                                            <T textName='datePickerRangeRange' vars={{ days: '7' }}
                                                defaultStr='За последние ${days} дней' page='widget' group='datePickerRange'
                                                customTranslates={trans} customLoading={!trans} />
                                        </Div>
                                    </Div>
                                    <Div>
                                        <Div
                                            effects={{ hover: { opacity: 0.7, cursor: 'pointer' } }}
                                            styles={styles.menuButton}
                                            data-uitest='11_uitest' onClick={() => {
                                                const d = time().add(-30, 'days');
                                                setStartDate(d.valueOf());
                                                setEndDate(time().valueOf());
                                            }}
                                        >
                                            <T textName='datePickerRangeRange' vars={{ days: '30' }}
                                                defaultStr='За последние ${days} дней' page='widget' group='datePickerRange'
                                                customTranslates={trans} customLoading={!trans} />
                                        </Div>
                                    </Div>
                                    <Div>
                                        <Div
                                            effects={{ hover: { opacity: 0.7, cursor: 'pointer' } }}
                                            styles={styles.menuButton}
                                            data-uitest='12_uitest' onClick={() => {
                                                const d = time().subtract(1, 'months');
                                                setStartDate(d.startOf('month').valueOf());
                                                setEndDate(d.endOf('month').valueOf());
                                            }}
                                        >
                                            <T textName='datePickerRangeMonth' defaultStr='За прошлый месяц' page='widget'
                                                group='datePickerRange' customTranslates={trans} customLoading={!trans} />
                                        </Div>
                                    </Div>
                                    <Div>
                                        <Div
                                            effects={{ hover: { opacity: 0.7, cursor: 'pointer' } }}
                                            styles={styles.menuButton}
                                            data-uitest='13_uitest' onClick={() => {
                                                setVal({
                                                    start: moment(adminProfile?.created_at_formatted?.split(' ')[0], 'DD.MM.YYYY')?.isValid
                                                        ? moment(adminProfile?.created_at_formatted?.split(' ')[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                                                        : moment(new Date(0)).format('YYYY-MM-DD'),
                                                    end: time().format('yyyy-MM-DD')
                                                });
                                                handleClose({
                                                    start: moment(adminProfile?.created_at_formatted?.split(' ')[0], 'DD.MM.YYYY')?.isValid
                                                        ? moment(adminProfile?.created_at_formatted?.split(' ')[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                                                        : moment(new Date(0)).format('YYYY-MM-DD'),
                                                    end: time().format('yyyy-MM-DD')
                                                });
                                            }}
                                        >
                                            <T textName='datePickerRangeAll' defaultStr='За все время' page='widget'
                                                group='datePickerRange' customTranslates={trans} customLoading={!trans} />
                                        </Div>
                                    </Div>
                                </Div>}
                                <Div styles={mobileRangeDateCustom && isMobile ? { ...styles.containerCalendar, ...styles.mobileCustomContainerCalendar } : styles.containerCalendar}>
                                    {!mobileRangeDateCustom && isMobile && <P styles={styles.mobileCustomDatePickerOwn}><T textName='choose-own' defaultStr='Выбрать свой диапазон' page='all' group='widget' />:</P>}
                                    <Div styles={styles.topContainer}>
                                        <Div styles={styles.containerInput}>
                                            {
                                                isMobile ?
                                                    <FormDate
                                                        compStyle={{ wrapper: { 'margin-bottom': '0' } }}
                                                        value={startDate}
                                                        setFormData={handleChangeStartDate}
                                                        options={{ max_date: endDate }}
                                                    />
                                                    :
                                                    <FormInput readonly value={formatValue(startDate)}/>

                                            }
                                        </Div>
                                        <Div styles={styles.textBetweenInput}><Label>-</Label></Div>
                                        <Div styles={styles.containerInput}>
                                            {
                                                isMobile ?
                                                    <FormDate
                                                        compStyle={{ wrapper: { 'margin-bottom': '0' } }}
                                                        value={endDate}
                                                        setFormData={handleChangeEndDate}
                                                        options={{ min_date: startDate }}
                                                    />
                                                    :
                                                    <FormInput readonly value={formatValue(endDate)}/>
                                            }
                                        </Div>
                                    </Div>
                                    <Div className={mobileRangeDateCustom && isMobile ? 'react-datepicker-range react-datepicker-range-custom-mobile' : 'react-datepicker-range'}>
                                        {
                                            (!isMobile || mobileRangeDateCustom)
                                        &&
                                            <DatePicker
                                                selected={startDate}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={changeHandler}
                                                selectsRange
                                                monthsShown={mobileRangeDateCustom && isMobile ? 1 : 2}
                                                locale={langLocale}
                                                inline
                                            />
                                        }
                                    </Div>
                                </Div>
                            </Div>
                            <Div styles={styles.bottomContainer}>
                                {/* <Button
                                    effects={{ hover: { opacity: 0.7, cursor: 'pointer' } }}
                                    styles={mobileRangeDateCustom && isMobile ? { ...styles.buttonCustom, ...styles.buttonCancelCustom } : isMobile ? { ...styles.buttonMobile, ...styles.buttonMobileCancel } : { ...styles.button, ...styles.buttonCancel }}
                                    data-uitest='20_uitest' onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    {isMobile ? <T textName='reset' defaultStr='Сбросить' page='all' /> : <T textName='cancel' defaultStr='Отмена' page='all' />}
                                </Button> */}
                                <Button
                                    effects={{ hover: { opacity: 0.7, cursor: 'pointer' } }}
                                    styles={mobileRangeDateCustom && isMobile ? { ...styles.buttonCustom, ...styles.buttonCancelCustom } : isMobile ? { ...styles.buttonMobile, ...styles.buttonMobileCancel } : { ...styles.button, ...styles.buttonCancel }}
                                    data-uitest='21_uitest' onClick={() => {
                                        setVal({
                                            start: defaultFilter?.default_date_from ? defaultFilter?.default_date_from : defaultFilter.default_created_after ? defaultFilter.default_created_after : moment(adminProfile?.created_at_formatted?.split(' ')[0], 'DD.MM.YYYY')?.isValid
                                                ? moment(adminProfile?.created_at_formatted?.split(' ')[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                                                : moment(new Date(0)).format('YYYY-MM-DD'),
                                            end: defaultFilter?.default_date_to ? defaultFilter?.default_date_to : defaultFilter.default_created_before ? defaultFilter.default_created_before : time().format('yyyy-MM-DD')
                                        });
                                        handleClose({
                                            start: defaultFilter?.default_date_from ? defaultFilter?.default_date_from : defaultFilter.default_created_after ? defaultFilter.default_created_after : moment(adminProfile?.created_at_formatted?.split(' ')[0], 'DD.MM.YYYY')?.isValid
                                                ? moment(adminProfile?.created_at_formatted?.split(' ')[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                                                : moment(new Date(0)).format('YYYY-MM-DD'),
                                            end: defaultFilter?.default_date_to ? defaultFilter?.default_date_to : defaultFilter.default_created_before ? defaultFilter.default_created_before : time().format('yyyy-MM-DD')
                                        });
                                    }}
                                >
                                    {<T textName='default-button-label' defaultStr='Сбросить' page='all' group='widget' />}
                                </Button>
                                <Button
                                    effects={{ hover: { opacity: 0.7, cursor: 'pointer' } }}
                                    styles={mobileRangeDateCustom && isMobile ? { ...styles.buttonCustom, ...styles.buttonOkCustom } : isMobile ? { ...styles.buttonMobile, ...styles.buttonMobileOk } : { ...styles.button, ...styles.buttonOk }}
                                    data-uitest='21_uitest' onClick={() => {
                                        setVal({
                                            start: formatDate(startDate),
                                            end: formatDate(endDate)
                                        });
                                        handleClose({
                                            start: formatDate(startDate),
                                            end: formatDate(endDate)
                                        });
                                    }}
                                >
                                    {isMobile ? <T textName='apply' defaultStr='Применить' page='all' group='widget' /> : <T textName='select' defaultStr='Выбрать' page='all' />}
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </>,
            document?.body)}
        </>
    );
};
CustomDatePicker.propTypes = {
    styles: PropTypes.object,
    handleClose: PropTypes.func,
    setVal: PropTypes.func,
    pickerElement: PropTypes.object
};

export default Styles(CustomDatePicker, getStyles);