import { media } from '../../../../common/utils/utils';
import uikit from '../../../uikit/styles';

const stylesheets = (width, height, variable = {}) => {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
    })(!window['safari'] || typeof safari !== 'undefined');
    return {
        formButtonPrimary: media(width, height, 'w', {
            768: {
                ...uikit.button,
                color: variable.blackColor,
                'background-color': variable.yellowColor,
                'border-radius': '32px',
                transition: 'background-color .4s'
            },
            0: {
                ...uikit.button,
                color: variable.blackColor,
                'background-color': variable.yellowColor,
                'height': '60px',
                'border-radius': '32px'
            }
        }),
        formButtonPrimaryHover: { 'background-color': variable.greyColor3 },
        formButtonPrimaryFocus: { 'background-color': variable.greyColor4 },
        formControlRequiredMark: {
            color: variable.errorColor,
            'padding-left': '4px',
            ...uikit.small
        },
        inputHover: {
            border: `2px solid ${variable.blueColor}`,
            'box-sizing': 'border-box'
        },
        input: media(width, height, 'w', {
            768: {
                ...uikit.text,
                'border': 'none',
                'width': '100%',
                'font-weight': 500
            },
            0: {
                ...isSafari ? {
                    'font-size': '16px',
                    'line-height': '1.5rem',
                    color: 'black'
                } : uikit.text,
                'border': 'none',
                'width': '100%',
                'font-weight': 500
            }
        }),
        buttonGray: media(width, height, 'w', {
            768: {
                ...uikit.button,
                'background-color': variable.whiteColor,
                color: variable.blackColor,
                border: `1px solid ${variable.greyColor}`,
                'border-radius': '32px',
                transition: 'background-color .4s'
            },
            0: {
                ...uikit.button,
                'background-color': variable.whiteColor,
                color: variable.blackColor,
                'height': '60px',
                border: `1px solid ${variable.greyColor}`,
                'border-radius': '32px',
                transition: 'background-color .4s'
            }
        }),
        buttonGrayHover: {
            'background-color': variable.greyColor3,
            'border-color': 'transparent'
        },
        buttonGrayFocus: {
            'background-color': variable.greyColor4,
            'border-color': 'transparent'
        }
    }
};
export default stylesheets;