export const duration = 0;

export const defaultStyle = {
    opacity: 0,
    transition: 'all .2s ease'
};

export const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0.3 },
    exited: { opacity: 0 }
};

export const defaultStyleAuthBlock = {
    opacity: 0,
    transition: 'all .2s ease'
};
export const transitionStylesAuthBlock = {
    entering: {
        opacity: 0,
        position: 'absolute',
        top: '0'
    },
    entered: { opacity: 1 },
    exiting: {
        opacity: 0.3,
        position: 'absolute',
        top: '0'
    },
    exited: {
        opacity: 0,
        position: 'absolute',
        top: '0'
    }
};