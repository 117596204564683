export const getInputStyles = (
    variationStyle, styles, compStyle, errors, focused, disabled, val, minInputWidth
) => {
    const getBg = (obj) => obj?.['background'] || obj?.['background-color']
    const bgErrors = errors && errors.length ? getBg(compStyle?.inputError) || getBg(styles[`formControlError__${variationStyle}`]) : false;
    const bgDisabled = disabled ? getBg(compStyle?.inputDisabled) || getBg(styles['formControlDisabled']) : false;
    const bgFocus = focused ? getBg(compStyle?.inputFocus) || getBg(styles[`formControlFocus__${variationStyle}`]) : false;
    const bgDefault = getBg(compStyle?.input) || getBg(styles[`formControl__${variationStyle}`]);

    const colorErrors = errors && errors.length ? compStyle?.inputError?.color || styles[`formControlError__${variationStyle}`]?.color : false;
    const colorDisabled = disabled ? '#000' : styles['formControlDisabled']?.color;
    const colorFocus = focused ? compStyle?.inputFocus?.color || styles[`formControlFocus__${variationStyle}`]?.color : false;
    const colorDefault = compStyle?.input?.color || styles[`formControl__${variationStyle}`]?.color;

    const background = bgErrors || bgDisabled || bgFocus || bgDefault;
    const color = colorErrors || colorDisabled || colorFocus || colorDefault

    const textAlign = compStyle?.input?.['text-align']

    return {
        ...styles.input,
        ...minInputWidth,
        background,
        color,
        ...textAlign ? { 'text-align': textAlign } : {},
        'box-shadow': `inset 0px 9000px 0 ${background}`
    }
}
export const getConfig = (
    attribute, required, typeField,
    val, maxLength, step, minValue, readonly,
    initHandlers, type, defaultCountry,
    changeHandler, pressHandler, showPass,
    variationStyle, styles, compStyle, errors, focused, disabled, minInputWidth
) => ({
    ...initHandlers,
    ...type === 'phone-widget' ? {
        initialCountry: 'ua',
        country: defaultCountry,
        excludeCountries: ['us', 'ua'],
        value: val || '',
        onChange: (phone, data, event, formattedValue) => changeHandler(phone, data, event, formattedValue)
    } : {
        type: showPass ? 'password' : typeField ? typeField : 'text',
        onChange: (e) => changeHandler(e.target.value),
        onKeyPress: (e) => pressHandler(e),
        value: val && step === 1 ? parseInt(`${val}`.replace(/[^0-9]/g, '')) : val,
        autoComplete: 'on',
        maxLength,
        readOnly: readonly,
        styles: getInputStyles(
            variationStyle, styles, compStyle, errors, focused, disabled, val, minInputWidth
        )
    },
    id: `input_${attribute}`,
    required,
    name: attribute,
    placeholder: '',
    disabled: Boolean(disabled) || false,
    min: minValue
})