// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { Div, P } from '../../../hybrid/wrappers';
import { t } from '../../../common/components/T';
import uikit from '../../../project/uikit/styles';

const Text = ({
    getHandlers, gridStyles, name, val, translation, styles
}) => {
    let copy = false;
    if (typeof val === 'string') {
        if (val?.includes('Копия')) {
            copy = val.split(' ').splice(1, val.split(' ').length - 1)
                .join(' ');
        }
    }

    return (
        <P
            {...getHandlers(false)}
            styles={gridStyles[name]?.wrapper?.styles || {}}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            { copy ?
                <Div styles={{ display: 'flex' }}>
                    <P>{copy}</P>
                    <P styles={{
                        'margin-left': '8px',
                        background: styles.variable.greySuperExtraDarkTransparentColor,
                        'border-radius': '6px',
                        padding: '4px 6px',
                        ...uikit.small,
                        'font-weight': '500',
                        color: styles.variable.blackColor

                    }}> {t(
                            'grid-copy', 'Копия', 'widget', translation, 'grid'
                        )}</P>
                </Div>
                : <P>
                    {
                        typeof val === 'object' && !Object.keys(val).length
                            ? '-'
                            : val === 0 ? `${val}` : val
                    }</P>
            }
        </P>
    );
};

Text.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    styles: PropTypes.object,
    name: PropTypes.string,
    link: PropTypes.string,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ])
};

export default Text;