import { dataSender } from '../../common/utils/router';
import { transformSearchUserData } from './initData';
import { API_SERVER_URL } from '../../common/utils/utils';

export const sendFunction = (
    val, sendData, setUserTableData, userToken, setAlertData, lang, transformSearchData = false, userId
) => {
    const searchParam = !!val && val.length
        // ? `${sendData?.url.indexOf('?') === -1 ? '?' : '&'}filter[q]=${val}`
        ? `${!sendData?.url || sendData?.url.indexOf('?') === -1 ? '?' : '&'}filter[q]=${val}`
        : '';


    const urlData = sendData?.url ? `${sendData?.url}${searchParam}` : `${API_SERVER_URL}/api/v1/user/partners/${userId || '1'}/descendants${searchParam}`;

    dataSender(
        urlData,
        'GET',
        false,
        (response) => {
            setAlertData(null);
            const resData = response.data;
            if (transformSearchData) {
                setUserTableData(transformSearchData(resData));
            } else {
                setUserTableData(transformSearchUserData(resData));
            }
        },
        (data) => {
            if (data?.message) {
                setAlertData({ type: 'error', cont: data.message });
            } else {
                setAlertData({ type: 'error', cont: data.false });
            }
        },
        userToken,
        lang
    );
};