import React, {
    useMemo, useContext, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
    Div, Img, P, svgSprite
} from '../wrappers';
import uikit from '../../project/uikit/styles';
import { t } from '../../common/components/T';
import { rankColors } from '../../project/uikit/color-variables';
import UserContext from '../../common/utils/getContext';
import { LangContext } from '../../common/decorators/Language';
import { getWidgetTranslates } from '../../common/utils/utils';

const LineProgress = ({ data, styles }) => {
    if (!data) return null;
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};
    let { have, partners } = data || {};
    const rankColor = useMemo(() => {
        if (have){
            return rankColors[have?.sort] || rankColors[rankColors.length-1]
        }
        return rankColors[0]
    }, [have]);

    const { lang } = useContext(UserContext);
    const [trans, setTranslates] = useState(null);

    useEffect(() => {
        getWidgetTranslates(lang, 'userModal', translation, setTranslates);
    }, [lang, translation]);

    return (
        <Div styles={styles.lineProgressWrap}>
            <Div styles={{
                ...uikit.flexRow, ...uikit.alignItemsCenter, ...uikit.medium, ...uikit.mb1
            }}>
                <P styles={{ ...uikit.small, ...styles.lineProgressTitle }}>
                    {data?.title}
                </P>
                <P styles={{ ...uikit.medium }}>«{have?.name}»</P>
            </Div>
            {Array.isArray(partners?.set) ?
                <Div styles={styles.structuresWrapper}>{
                    partners.set.map((el, i) =>
                        <Div key={el} styles={{
                            ...uikit.flexRow, ...uikit.alignItemsCenter, ...i !== 0 ? uikit.mt2 : {}
                        }}>
                            <Div>
                                <Div styles={{ ...have?.preview?.links?.preview ? styles.rangIcon: { 'margin-right': '6px' } }}>
                                    {have && have?.preview?.links?.preview ?
                                        <Img
                                            src={have?.preview?.links?.preview}
                                            styles={{ width: '100%', height: '100%' }}
                                        />
                                        : svgSprite('rankPlug', { width: '28px', height: '28px' })}
                                </Div>
                            </Div>
                            <Div styles={{ 'flex-grow': 1 }}>
                                <Div styles={{
                                    ...uikit.small, ...uikit.medium, 'margin-bottom': '3px'
                                }}>{
                                        (i===0 ? '1 ' + t(
                                            'user_modal_Branch', 'ветка', 'widget', trans, 'userModal'
                                        ) : '2 ' + t(
                                            'user_modal_Branch', 'ветка', 'widget', trans, 'userModal'
                                        )) + ' ' +
                                        el + ' '+t('of', 'из', 'all', translation)
                                    + ' ' + partners.max[i]}</Div>
                                <Div styles={styles.progressBarPath}>
                                    <Div styles={{
                                        ...styles.progressBarPathDone,
                                        background: rankColor.gradient,
                                        width: `${100 / (partners.max[i] / el)}%`
                                    }}/>
                                </Div>
                                {/*<Div styles={styles.progressBarCount}>*/}
                                {/*    <P>0</P>*/}
                                {/*    <P>{partners.max}</P>*/}
                                {/*</Div>*/}
                            </Div>
                        </Div>)
                }
                </Div>:
                <Div styles={{ ...uikit.flexRow, ...uikit.alignItemsCenter }}>
                    <Div>
                        <Div styles={{ ...have?.preview?.links?.preview ? styles.rangIcon: { 'margin-right': '6px' } }}>
                            {have && have?.preview?.links?.preview ?
                                <Img
                                    src={have?.preview?.links?.preview}
                                    styles={{ width: '100%', height: '100%' }}
                                />
                                : svgSprite('rankPlug', { width: '28px', height: '28px' })}
                        </Div>
                    </Div>
                    <Div styles={{ 'flex-grow': 1 }}>
                        <Div styles={{
                            ...uikit.small, ...uikit.medium, 'margin-bottom': '3px'
                        }}>{partners.set + ' ' + t('of', 'из', 'all', translation) + ' ' + partners.max}</Div>
                        <Div styles={styles.progressBarPath}>
                            <Div styles={{
                                ...styles.progressBarPathDone, background: rankColor.gradient, width: `${100 / (partners.max / partners.set)}%`
                            }} />
                        </Div>
                        {/*<Div styles={styles.progressBarCount}>*/}
                        {/*    <P>0</P>*/}
                        {/*    <P>{partners.max}</P>*/}
                        {/*</Div>*/}
                    </Div>
                </Div>
            }
        </Div>
    );
}

LineProgress.propTypes = {
    styles: PropTypes.object,
    data: PropTypes.object
};
export default LineProgress;