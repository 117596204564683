import {
    useContext, useEffect, useRef, useState
} from 'react';
import { t } from '../../../../common/components/T';
import { checkIsfile } from '../../../../common/utils/api';
import UserContext from '../../../../common/utils/getContext';
import { checkFileSize, SITE_URL } from '../../../../common/utils/utils';
import {
    Div, Img, P, svgSprite
} from '../../../../hybrid/wrappers';
import uikit from '../../../../project/uikit/styles';
import ProgressBar from '../../../ProgressBar/ProgressBar';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';

export const downloadFileFunction = (
    multiple, userToken, lang, setDownload, setCountRequest, setAlertData
) => (link, file) => {
    (async () => {
        let headers = {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'text/plain',
            'Accept': 'application/json',
            ...lang ? { 'Accept-Language': lang } : {}
        };

        const response = await fetch(link, {
            method: 'GET',
            headers
        })

        let chunks = [];

        if (response.status === 200){
            console.log('333',response.headers.has('Content-Length'))
            if (response.headers.has('Content-Length')) {
                const reader = response.body.getReader();

                while (true) {
                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }

                    chunks.push(value);
                }
                let blob = new Blob(chunks);

                var myFileRider = new FileReader();
                myFileRider.onloadend = function() {
                    setDownload(myFileRider.result);
                    setCountRequest(0);
                }
                myFileRider.readAsDataURL(blob)
            } else {
                if (multiple) {
                    setDownload(file)
                    return
                }
                const data = await response.json();
                if (!!data?.data?.finished){
                    if (data?.data?.download_link){
                        setDownload(data?.data?.download_link);
                    }
                    setCountRequest(0);
                } else {
                    if (data?.data?.download_link){
                        setDownload(data?.data?.download_link);
                    }
                    if (data?.data?.queue_link){
                        // setUrl(data?.data?.queue_link);
                    }
                    setTimeout(() => {
                        setCountRequest((pre) => pre+1);
                    }, 1500)
                }
            }
        } else {
            setAlertData({
                type: 'error', cont: true, default: 'error_get_data'
            });
            setCountRequest(-1);
        }
    })();
};

export async function fileListToBase64(fileList, maxScale) {
    function getBase64(file) {
        const reader = new FileReader()

        return new Promise(resolve => {
            if (file.preview || file?.links?.preview || file?.url){
                resolve(file.preview || file?.links?.preview || file?.url);
            }
            reader.onload = ev => {
                if (!maxScale){
                    resolve(ev?.target?.result)
                } else {
                    checkFileSize(ev?.target?.result, maxScale).then(result => resolve(result))
                }
            }
            reader.readAsDataURL(file)
        })
    }
    const promises = []
    for (let i = 0;i < fileList.length;i++) {
        promises.push(getBase64(fileList[i]))
    }

    return await Promise.all(promises)
}

export async function fileListHeic(
    fileList, setLoader, setAlertData, translation, setConvertProgress
) {
    setLoader('heic')

    function getHeic(file) {
        return new Promise(async resolve => {
            if (checkIsfile(file.name, ['heic', 'heif'])){
                const body = new FormData()
                body.append('upload', file)
                const response = await fetch(`${SITE_URL}/api/files/heic`, {
                    method: 'POST',
                    headers: { 'Accept': 'application/json' },
                    body
                })
                let receivedLength = 0;
                let chunks = [];
                let contentLength = 0

                if (response.status === 200){
                    const reader = response.body.getReader();
                    contentLength = +response.headers.get('Content-Length');


                    while (true) {
                        const readersrr = await reader.read();
                        const { done, value } = readersrr
                        if (done) {
                            break;
                        }

                        chunks.push(value);
                        receivedLength += value.length;

                        setConvertProgress(Math.round(receivedLength / contentLength * 100))
                    }

                    const fileResult = new File(chunks, `${file.name.slice(0, -5) || 'converted'}.jpeg`, { type: 'image/jpeg' })
                    const myFileRider = new FileReader()

                    myFileRider.onload = ev => resolve(Object.assign(fileResult, { preview: ev.target.result }))
                    myFileRider.readAsDataURL(fileResult)

                    setLoader(false)
                    setAlertData({
                        type: 'success', cont: t(
                            'FormInputDropzone-LoadingFileSucces', 'Изображение успешно загружено', 'widget', translation, 'form'
                        )
                    });
                }
            } else {
                resolve(file)
                setLoader(false)
                setAlertData({
                    type: 'success', cont: t(
                        'FormInputDropzone-LoadingFileSucces', 'Изображение успешно загружено', 'widget', translation, 'form'
                    )
                });
            }
        })
    }
    const promises = []
    for (let i = 0;i < fileList.length;i++) {
        promises.push(fileList[i].url ? fileList[i] : getHeic(fileList[i]))
    }
    return await Promise.all(promises)
        .finally(() => setLoader(false))
}

export const onDropFunction = (
    attribute, multiple, val,
    maxScale, max, setVal,
    setFormData, translation, setAlertData,
    setLoader, setConvertProgress
) => async acceptedFiles => {
    const allFiles = [...acceptedFiles, ...multiple ? val : []];
    if (allFiles.length > max) {
        setAlertData({
            type: 'error', cont: `${t(
                'FormInputDropzone-MaxFileCount', 'Максимальное количество файлов', 'widget', translation, 'form'
            )} - ${max}`
        });
        return
    }
    const arrayOfBase64 = await fileListToBase64(allFiles, maxScale);

    if (arrayOfBase64){
        const filtered = allFiles.filter((im, ind) => arrayOfBase64[ind]);
        const arrayOfBase64Filtered = arrayOfBase64.filter((im) => typeof im === 'string');
        let newVal = filtered.map((file, index) => {
            return Object.assign(file, { preview: arrayOfBase64Filtered[index] || file?.preview })
        });
        const slices = multiple || max ? newVal.slice(0, max || 1) : newVal;
        const converted = await fileListHeic(
            slices, setLoader, setAlertData, translation, setConvertProgress
        )


        if (multiple){
            setVal(converted);
            setFormData(attribute, converted);
        } else {
            setVal(converted?.length ? converted[0] : null);
            setFormData(attribute, converted?.length ? converted[0] : null);
        }

        if (filtered.length > converted.length && max !== 1){
            setAlertData({
                type: 'error', cont: `${t(
                    'FormInputDropzone-MaxFileCount', 'Максимальное количество файлов', 'widget', translation, 'form'
                )} - ${max}`
            });
        }
        if (allFiles.length > filtered.length){
            setAlertData({
                type: 'error', cont: `${t(
                    'FormInputDropzone-MaxFileSize', 'Максимальный размер файла', 'widget', translation, 'form'
                )} - ${maxScale} x ${maxScale}px`
            });
        }
    }
}
function getInfoAboutFile(param, mime) {
    const name = param?.split('.').splice(0, param?.split('.')?.length - 1);
    const type = mime ? mime?.split('/')?.reverse()[0] : param?.split('.')?.reverse()[0];

    return [name, type];
}
export const getFilesThumbs = (
    val, hint, downloading,
    deletefile, deleteImg,
    downloadFile, transl, styles,
    compStyle, multiple, exportData, screenWidth, isClickDownload, setIsClickDownload
) =>
    (Array.isArray(val) ? val : val ? [val] : []).map((file, idx) => {
        let [name, type] = getInfoAboutFile(file?.name || file?.original_name || file?.path || file?.exportData?.type, file?.mime_type)
        const size = isNaN(file?.size) ? file?.size : `${Math.round(file?.size / 1024)} ${t(
            'FormInputDropzone-Kbait', 'КБ', 'widget', transl, 'form'
        )}`;
        return (
            <Div
                key={`${file.name}${idx}`}
                styles={{ ...compStyle.fileWrapper, position: 'relative' }}
            >
                <Div
                    styles={compStyle?.fileContainer}
                >
                    <Div styles={compStyle.filePosition}>
                        <Div>
                            <P styles={compStyle.subTypePosition}>.{type?.toUpperCase()}</P>
                            {svgSprite('plug-file-image', { width: 56, height: 56 })}
                        </Div>
                        <Div>
                            <P styles={compStyle.subNamePosition}>{name?.length ? name : hint || exportData?.name}</P>
                            {!isNaN(size) && <P styles={compStyle.subSizePosition}>{t(
                                'FormInputDropzone-Size', 'Размер', 'widget', transl, 'form'
                            )}{' : '}{size}</P>}
                            <P styles={compStyle.subType2Position}>{t(
                                'FormInputDropzone-Format', 'Формат', 'widget', transl, 'form'
                            )}{': .'}{type}</P>
                        </Div>
                    </Div>
                    <Div styles={compStyle.buttonFile}>
                        {deletefile &&
                                <Div data-uitest='592_uitest' onClick={(e) => { deleteImg(file, e) }} styles={styles.formDropzoneDeleteButton}>
                                    {t(
                                        'Delete', 'Удалить', 'widget', transl, 'form'
                                    )}
                                </Div>
                        }
                        { !screenWidth ?  <Div data-uitest='595_uitest' onClick={ () => {
                            setIsClickDownload(!isClickDownload)
                            return !multiple ? downloadFile(downloading, false, isClickDownload) : downloadFile(file.url, file)
                        }} styles={styles.formDropzoneLoadButtons}>
                            {svgSprite('download', {
                                width: '19px',
                                height: '17px',
                                fill: styles.variable.greyExtraDarkColor
                            })}
                        </Div> :
                        <Div data-uitest='593_uitest' onClick={ () => !multiple ? downloadFile(downloading) : downloadFile(file.url, file)}>
                            {/* <a href={file.url} download={file.fileName}> */}
                            {t(
                                'Download', 'Скачать', 'widget', transl, 'form'
                            )}
                            {/* </a> */}
                        </Div> }
                    </Div>

                </Div>
            </Div>
        )
    })

export const getThumbs = (
    val, deleteBtn, deleteImg,
    downloading, downloadFile,
    noDrag, styles, compStyle, isClickDownload, setIsClickDownload
) =>
    (Array.isArray(val) ? val : val ? [val] : []).map((file, idx) => {
        const isPdf = checkIsfile(file?.preview, ['pdf'])
            || checkIsfile(file?.url, ['pdf'])
            || checkIsfile(file?.links?.preview, ['pdf'])
            || file?.preview?.includes('data:application/pdf')
            || file?.url?.includes('data:application/pdf')
            || file?.links?.preview?.includes('data:application/pdf');

        return (
            <Div key={`${file.url || file?.links?.preview}${file.name}${idx}`} styles={noDrag ? compStyle?.previewImageWrap || {} : { ...styles.formDropzoneImageWrapper, ...compStyle?.previewImageWrap }}>
                {deleteBtn &&
                    <Div data-uitest='594_uitest' onClick={(e) => { deleteImg(file, e) }} styles={styles.formDropzoneLoadButtons}>
                        {svgSprite('trash', {
                            width: '16px',
                            height: '18px',
                            fill: styles.variable.redDarkColor
                        })}
                    </Div>
                }
                {downloading &&
                    <Div data-uitest='595_uitest' onClick={() => { setIsClickDownload(!isClickDownload);downloadFile(downloading, false, isClickDownload) }} styles={styles.formDropzoneLoadButtons}>
                        {svgSprite('download', {
                            width: '19px',
                            height: '17px',
                            fill: styles.variable.greyExtraDarkColor
                        })}
                    </Div>
                }
                <Div styles={{ ...styles.formDropzoneImageContent, ...compStyle?.previewImageContent }}>
                    {isPdf
                        ? <iframe src={file?.url || file?.preview || file?.links?.preview} height='233px' width='233px'></iframe>
                        : <Img
                            styles={noDrag ? compStyle?.previewImage || {} : { ...styles.formDropzonePreviewImage, ...compStyle?.previewImage || { ...compStyle.styleOfImg } }}
                            src={file.url || file.preview || file?.links?.preview}
                        />
                    }
                </Div>
                {noDrag && <P styles={compStyle?.previewName}>{file.name || file?.original_name || 'flag.png'}</P>}
            </Div>
        )
    })

export const useDownload = (multiple, exportData, isClickDownload) => {
    const {
        setAlertData, lang, userToken
    } = useContext(UserContext);

    let [countRequest, setCountRequest] = useState(0);
    const [download, setDownload] = useState(null);

    useEffect(() => {
        console.log('download && !countRequest',download, !countRequest)
        if (download && !countRequest){
            const attribute = multiple ? download?.url || download : download
            let link = document.createElement('a')
            link.setAttribute('href', attribute)

            link.setAttribute('download', multiple ? exportData?.type ? `${exportData?.name}.${exportData?.type}` : download?.fileName || 'file' : `${exportData?.name}.${exportData?.type}`)
            link.click();
        }
    }, [download, countRequest, exportData?.name, exportData?.type, multiple, isClickDownload]);

    return downloadFileFunction(
        multiple, userToken, lang, setDownload, setCountRequest, setAlertData
    )
}
const BarComponent = ({ styles, refs }) => {
    const {
        pointerRef, numberRef, progressRef
    } = refs
    return <Div styles={{ ...uikit.mt2, ...uikit.mb2 }}><ProgressBar styles={styles} pointerRef={pointerRef} numberRef={numberRef} progressRef={progressRef}/></Div>
}
const StyledBarComponent = Styles(BarComponent, getStyles)
export const useProgress = () => {
    const progressRef = useRef()
    const numberRef = useRef()
    const pointerRef = useRef()
    const setConvertProgress = (progress) => {
        if (progressRef.current){
            progressRef.current.style.width = `${progress}%`
        }
        if (numberRef.current){
            numberRef.current.style.left = `${progress}%`
            numberRef.current.innerText = `${progress}%`
        }
        if (pointerRef.current){
            pointerRef.current.style.left = `${progress}%`
        }
    }
    return {
        setConvertProgress,
        Bar: () => <StyledBarComponent refs={{
            pointerRef, numberRef, progressRef
        }}/>
    }
}