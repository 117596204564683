// @generated: @expo/next-adapter@2.1.5
import React, {
    useContext, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
    Div, P, Portal, svgSprite
} from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/alert-styles';
import Styles from '../../common/decorators/Styles';
import { t } from '../../common/components/T';
import { LangContext } from '../../common/decorators/Language';
import { main } from '../../project/uikit/color-variables';

const Alert = ({ changeAlertSetter, styles }) => {
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    const [alertData, setAlertData] = useState(false);
    const canSet = useRef(true);
    const alertRef = useRef(null)

    const icons = {
        success: 'check-circle', info: 'info-circle-fill', error: 'warning-circle-fill', warning: 'warning-triangle-fill'
    };

    useEffect(() => {
        changeAlertSetter((data) => {
            !!data?.cont && setAlertData(data)
        });
        return () => canSet.current = false;
    }, [changeAlertSetter, setAlertData]);

    useEffect(() => {
        if (!alertData?.view || alertData?.view === 'base') {
            const timer = setTimeout(() => {
                if (canSet.current) setAlertData(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [alertData]);

    useEffect(() => {
        if (!alertRef.current) {
            return
        } else {
            if (alertRef.current.getBoundingClientRect().height < 60) {
                alertRef.current.style.alignItems = 'center'
            } else {
                alertRef.current.style.alignItems = 'flex-start'
            }
        }
    })

    if (!alertData) return null;

    const getDefaultText = (alias) => {
        const text = {
            'success_save_data': 'Данные успешно сохранены',
            'success_send_data': 'Данные успешно отправлены',
            'error_get_data': 'Ошибка получения данных',
            'error_delete_data': 'Ошибка удаления данных',
            'error_save_data': 'Ошибка сохранения данных'
        }
        if (text[alias]){
            return text[alias] && t(alias, text[alias], 'all', translation)
        }
    }
    switch (alertData?.view) {
    case 'modal':
        return (
            <Portal>
                <Div styles={styles.alertModalWrapper}>
                    <Div
                        styles={{ ...styles.cursorPointer, ...styles.alertModalCloseX }}
                        data-uitest='524_uitest' onClick={() => setAlertData(false)}
                    >
                        {svgSprite('cross', {
                            width: '12px',
                            height: '12px',
                            fill: styles.variable.greyExtraDarkColor || main.greyExtraDarkColor
                        })}
                    </Div>
                    {
                        alertData.cont?.showIcon
                            ?
                            <Div styles={styles.alertModalIconBlock}>
                                {svgSprite(icons[!alertData.type ? 'info' : alertData.type], {
                                    ...styles[`alertModalIcon_${alertData.type}`],
                                    style: styles.alertModalIcon
                                })}
                            </Div>

                            : null
                    }

                    <Div styles={styles.alertModalTitle}>
                        {alertData.cont.modalTitle}
                    </Div>

                    <Div styles={styles.alertModalText}>
                        {alertData.cont.modalCont}
                    </Div>

                    <Div
                        styles={{
                            ...styles.buttonPadding, ...styles.cursorPointer, ...styles.buttonGray, ...styles.buttonGrayText
                        }}
                        effects={{
                            hover: styles.buttonGrayHover,
                            focus: styles.buttonGrayFocus,
                            active: styles.buttonGrayFocus
                        }}
                        data-uitest='525_uitest' onClick={() => { setAlertData(false); }}
                    >
                            Закрыть
                    </Div>
                </Div>
            </Portal>
        );
    case 'base':
    default:
        return (
            <>
                <Div
                    childrenRef={alertRef}
                    styles={{ ...styles.alertWrapper, ...styles[`bg_${alertData.type || 'info'}`] }}
                >
                    <Div styles={styles.alertIconBlock}>
                        {svgSprite(icons[!alertData.type ? 'info' : alertData.type], { style: styles.alertIcon })}
                    </Div>
                    <Div styles={styles.alertTextWrapper}>
                        {
                            alertData.title ?
                                <P styles={styles.alertTitle}>
                                    {alertData.title}
                                </P>
                                : null
                        }
                        <P styles={styles.alertText}>
                            {
                                alertData.cont === true
                                    ? getDefaultText(alertData.default) || null
                                    : alertData.cont
                            }
                        </P>
                    </Div>
                </Div>
            </>
        );
    }
};

Alert.propTypes = {
    styles: PropTypes.object,
    changeAlertSetter: PropTypes.func
};

export default Styles(Alert, getStyles);